import React from 'react';
import Header from './Header';
import { Input, Button, Icon } from 'antd';

const { Search } = Input;

const TableHeader = ({ searchPlaceholder, onSearch, buttonLabel, onButtonPress, buttonDisabled, loading }) => { 
    return (
        <Header loading={loading} titleComponent={<Search placeholder={!searchPlaceholder ? 'Search' : searchPlaceholder} onSearch={onSearch} style={{ width: 400, marginLeft: 0 }} />} >    
            <Button type='primary' onClick={onButtonPress} disabled={buttonDisabled}><Icon type="plus" />{ buttonLabel }</Button>
        </Header>
    )
};

export default TableHeader;