import React, { Component } from 'react';
import {
  Tabs,
} from 'antd';
import { css } from 'emotion';
import WorkOrderDetailsView from '../components/WorkOrderDetailsView';
import WorkOrderBreakdownTab from '../components/WorkOrderBreakdownTab';
import CutStockTab from './CutStockTab';
import ActualWorkTab from './ActualWorkTab';
import ComposingAttachments from './ComposingAttachments';
import PublicationEstimates from './PublicationEstimates';
import MaterialsAndSuppliesTab from './MaterialsAndSuppliesTab';
import DeliveriesTab from './DeliveriesTab';
import { 
  shouldReloadTabs, 
  showActualWork, 
  cutStockAction, 
  materialsAndSuppliesAction,
  deliveriesAction,
  canEditBreakdown,
  composingAttachmentsAction,
  composingEstimateAction
} from '../utils';
import { get, mapValues, merge } from 'lodash';

const { TabPane } = Tabs;

const workOrderTabs = css({
  '& > .ant-tabs-card': {
    '& > .ant-tabs-content > .ant-tabs-tabpane': {
      background: '#FFF',
      padding: '16px'
    },
    '& > .ant-tabs-bar': {
      borderColor: '#FFF',
      '& .ant-tabs-tab': {
        borderColor: 'transparent',
        background: '#E9F8FE',
        margin: '0px 10px 0px 0px',
        color: '#9DD1EA'
      },
      '& .ant-tabs-tab-active': {
        borderColor: '#FFF',
        background: '#FFF',
        color: '#1890ff'
      },
    }
  }
});



export default class extends Component {
  constructor(){
    super();
    this.state = {
     panes: [],
     formRefs: {},
    }
  }

  componentDidMount() {
    this.populatePanes();
  }

  componentDidUpdate(prevProps) {
    if (shouldReloadTabs(this.props, prevProps)) {
      this.populatePanes();
    }
  }

  populatePanes = () => {
    const { loggedInUser, work_order } = this.props;
    const panes = [];
    const formRefs = {};

    const breakdownEditable = canEditBreakdown(loggedInUser, work_order);
    formRefs.breakdown = breakdownEditable ? React.createRef() : undefined;
    panes.push(
      <TabPane tab="Breakdown" key='breakdown' forceRender>
        <WorkOrderBreakdownTab 
          wrappedComponentRef={formRefs.breakdown}
          work_order={work_order} 
          readOnly={!breakdownEditable}
        />
      </TabPane>
    );

    if (cutStockAction(loggedInUser, work_order)) {
      formRefs.cut_stock = React.createRef();
      panes.push(
        <TabPane tab="Cut Stock" key='cut_stock' forceRender>
          <CutStockTab 
            work_order={work_order}
            wrappedComponentRef={formRefs.cut_stock} 
          />
        </TabPane>
      );
    }

    if (showActualWork(work_order)) {
      formRefs.actual_work = React.createRef();
      panes.push(
        <TabPane tab="Actual Work" key='actual_work' forceRender>
          <ActualWorkTab 
            loggedInUser={loggedInUser}
            work_order={work_order} 
            wrappedComponentRef={formRefs.actual_work} 
          />
        </TabPane>
      );
    }

    const composingAttachments = composingAttachmentsAction(loggedInUser, work_order);
    if (!!composingAttachments) {
      formRefs.attachments = React.createRef();
      panes.push(
        <TabPane tab="Attachments" key='attachment' forceRender>
          <ComposingAttachments 
            work_order={work_order} 
            readOnly={composingAttachments === 'view'}
            wrappedComponentRef={formRefs.attachments} 
          />
        </TabPane>
      );
    }

    if (composingEstimateAction(loggedInUser, work_order)) {
      formRefs.estimate_numbers = React.createRef();
      panes.push(
        <TabPane tab="Cost Estimates" key='cost_estimate' forceRender>
          <PublicationEstimates 
            wrappedComponentRef={formRefs.estimate_numbers} 
          />
        </TabPane>
      );
    }

    const materialsAction = materialsAndSuppliesAction(loggedInUser, work_order);
    if (!!materialsAction) {
      formRefs.materials = React.createRef();
      panes.push(
        <TabPane tab="Materials & Supplies" key='materials_supplies' forceRender>
          <MaterialsAndSuppliesTab 
            editable={materialsAction.editable}
            action={materialsAction.action}
            protected_data={get(work_order, `${materialsAction.protected_data}.materials`, [])}
            data={get(work_order, `${materialsAction.action}.materials`, [])}
            wrappedComponentRef={formRefs.materials} 
          />
        </TabPane>
      );
    }

    const deliverAction = deliveriesAction(loggedInUser, work_order);
    if (!!deliverAction) {
      formRefs.deliveries = React.createRef();
      panes.push(
        <TabPane tab="Deliveries" key='deliveries' forceRender>
          <DeliveriesTab 
            editable={deliverAction.editable}
            action={deliverAction.action}
            protected_data={get(work_order, `${deliverAction.protected_data}.deliveries`, [])}
            data={get(work_order, `${deliverAction.action}.deliveries`, [])}
            wrappedComponentRef={formRefs.deliveries} 
          />
        </TabPane>
      );
    }

    this.setState({ panes, formRefs });
  }

  getValidatedData = () => {
    const consolidatedData = {};
    mapValues(this.state.formRefs, ref => {
      if (ref) {
        merge(consolidatedData, ref.current.getValidatedData());
      }
      return undefined;
    });
    return consolidatedData;
  }

  render() {
    const { work_order } = this.props;
    console.log('work_order', work_order);
    const { panes } = this.state;
    return (
      <div className={workOrderTabs}>
        <Tabs type='card' onChange={activeKey =>  this.setState({ activeKey })}>
          <TabPane tab="Overview" key='overview'>
            <WorkOrderDetailsView work_order={work_order} />
          </TabPane>
          { panes }
        </Tabs>
      </div>
    );
  }
}