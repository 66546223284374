
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { Drawer, Button, Icon, notification } from 'antd';
import Header from 'shared/components/Header';
import CreateUpdateForm from './CreateUpdateForm';
import { createOP, updateOP } from '../actionCreators';
import { get } from 'lodash';

const drawerStyle = css({
	'& .drawer-content': {
		display: 'flex', 
		height: '100%', 
		overflow: 'hidden'
	},
	'& .ant-drawer-body': {
		height: '100%',
		background: '#FFF',
		padding: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column'
	},
	'& .form-container': {
		flex: 1,
		overflow: 'auto',
		padding: '25px',
	},
	'& .footer': { 
		backgroundColor: '#FFF', 
		width: '100%', 
		borderTop: '1px solid #e8e8e8', 
		padding: '20px', 
		textAlign: 'left',
	},
	'& .loading': {
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center', 
		position: 'absolute', 
		top: '0', 
		bottom: '0', 
		left: '0', 
		right: '0', 
		backgroundColor: '#26272955' 
	},
	'& .container': {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	}
});

class CreateUpdate extends Component {
	handleSubmit = (isReleased) => {
		this.formRef.props.form.validateFieldsAndScroll((err, fieldValues) => {
      if (!err) {
        const values = {
					...fieldValues,
					op_date: fieldValues.op_date.format('YYYY-MM-DD'),
					release: isReleased,
					id: get(this.props, 'data.id')
				}
				if (values.id) {
					this.props.updateOP(values, this.successCallback, this.errorCallback);
					this.props.onDrawerCloseClicked()
					return;
				}
				this.props.createOP(values, this.successCallback, this.errorCallback);
				this.props.onDrawerCloseClicked()
      }
    });
	}

	successCallback = () => {
		this.props.successCallback();
		this.props.onDrawerCloseClicked();
	}

	errorCallback = (error) => {
		notification.error({
			title: 'Uh Oh!',
			message: error.message
		})
	}

	saveCreateForm = (ref) => {
		this.formRef = ref;
	}

	render() {
		return (
			<Drawer
				width={520}
				placement="right"
				closable={false}
				maskClosable={false}
				destroyOnClose={true}
				visible={this.props.visible}
				className={drawerStyle}
			>
				<Header 
					title={'Order of Payment'} 
					containerStyle={{ background: `linear-gradient(to left, #2c83cb, #57aff4)`, padding: '15px 20px', boxShadow: '1px 0px 2px grey' }} 
					titleStyle={{ color: '#FAFBFB', fontWeight: '500' }}
				>
					<Icon onClick={this.props.onDrawerCloseClicked} type='close' style={{ fontSize: '1.5em', color: '#FBFAFA' }} />
				</Header>
				<div className='form-container'>
					<CreateUpdateForm 
						wrappedComponentRef={this.saveCreateForm}
						data={this.props.data}
					/>
				</div>
				<div className='footer'>
					{
						(!this.props.data || this.props.data.op_status === 'DRAFT') && 
						<Button loading={this.props.loading} style={{ marginRight: 8 }} type="primary" onClick={() => this.handleSubmit(true)} >
							Release
						</Button>
					}
					{
						(!this.props.data || this.props.data.op_status === 'DRAFT') && 
						<Button loading={this.props.loading} style={{ marginRight: 8 }} type="primary" onClick={() => this.handleSubmit(false)} >
							Save As Draft
						</Button>
					}
					<Button loading={this.props.loading} onClick={this.props.onDrawerCloseClicked}>
						{
							(!this.props.data || this.props.data.op_status === 'DRAFT') ? 'Cancel' : 'Close'
						}
					</Button>
				</div>
			</Drawer>
		);
	}
}

const mapStateToProps = ({order_of_payment}) => {
	return {
		loading: order_of_payment.loading,
	};
}

const mapDispatchToProps = {
	createOP, updateOP
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);