import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import { debounce, reject, get } from 'lodash';
import { axios } from 'shared/utils/api_client';
import { API_ESTIMATES } from 'shared/constants/urls';
import {getAuthHeader} from 'shared/utils/api_client';
const Option = Select.Option;

export default class extends Component {
    constructor(props) {
        super(props);    
        const value = props.value || undefined;
        this.state = {
            value: value,
            data: [],
            fetching: false,
        };
        this.lastFetchId = 0;
        this.fetchData = debounce(this.fetchData, 500);
    }

    // // // for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            this.setState({
                value: props.value || undefined,
            });
        }
    }

    componentDidMount = () => {
        this.fetchData('');
    }

    clear = () => {
        this.setState({ value: '' });
    }

    fetchData = (value) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        const url = API_ESTIMATES;
        if (!url) {
            this.setState({ data: [], fetching: false });
            return;
        }
        axios.get(url, { params: { take: 20, q: value, type: [this.props.type] }, headers: { ...getAuthHeader() }})
        .then((response) => {
            if (fetchId !== this.lastFetchId) { // for fetch callback order
                return;
            }
            const data = reject(get(response, 'data.data', []), ['ql_number', null]);
            this.setState({ data, fetching: false });
        })
        .catch((error) => {
            notification['error']({ message: error.message });
            this.setState({ fetching: false});
        });
    }

    handleChange = (value, options) => {
        this.setState({ value: options.props.fulldata });
        if (this.props.onChange) {
            this.props.onChange(options.props.fulldata);
        }
    }

    render() {
        const { fetching, data, value, fetchingEstimate } = this.state;
        return (
            <div>
            <Select
                showSearch
                mode="default"
                value={value ? value.estimate_number : value}
                placeholder="Select Cost Estimate"
                notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                filterOption={false}
                onSearch={this.fetchData}
                onChange={this.handleChange}
                style={{ width: '100%' }}
            >
                {data.map(d => <Option key={d.id} value={d.estimate_number} fulldata={d}>{d.estimate_number}</Option>)}
            </Select>
            {
                fetchingEstimate && 
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '0', bottom: '0', left: '0', right: '0' }}>
                    <Spin size='large' />
                </div>
            }
            </div>
        )

    }
}
