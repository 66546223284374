import React, { Component } from 'react';
import { Tabs, Form } from 'antd';
import { css } from 'emotion';
import { canEditActualWork } from '../utils';
import WorkEnvelopeTable from './WorkEnvelopeTable';
import { get, isEqual, concat, groupBy, mapValues, forEach, pullAllWith } from 'lodash';
const TabPane = Tabs.TabPane;

const wrapper = css({
	maxWidth: '100%',
  '& .ant-tabs': {
		margin: '15px', 
  },
  '& .ant-form-item-label': {
    textAlign: 'left',
    color: '#646970',
    fontSize: '15px',
    '& label': {
      color: '#646970',
      fontSize: '15px',
    }
  }
})

class ActualWorkTab extends Component {
	constructor() {
		super();
		this.state = { data: {} }
		this.currentTab = ''
	}

	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(get(this, 'props.work_order.actual_work'), get(prevProps, 'work_order.actual_work'))) {
			this.loadData();
		}
	}

	loadData = () => {
		const actual_work = get(this, 'props.work_order.actual_work') || {};
		const divisions = groupBy(get(this, 'props.work_order.process', []), 'division');
		const stepsData = mapValues(divisions, division => {
			let data = { data: [] };
			forEach(division, process => {
				const step = actual_work[process.step];
				if(step && step.data) {
					data.data = concat(data.data, step.data);
				}
			});
			return data;
		});
		this.setState({ data: stepsData })
	}
	
	getValidatedData = () => {
		const { form } = this.props;
		const response = {};
		form.validateFieldsAndScroll((error, values) => {
			if (error) {
				response.error = error;
			} else {
				const old_values = get(this, `state.data.${this.currentTab}`, { data: [] })
				const submitValues = pullAllWith(values.actual_work.data, old_values.data, 
					(old_value, new_value) => {
						return old_value.description === new_value.description && 
							old_value.title === new_value.title
					});
				response.values = {
					actual_work: {
						data: submitValues,
					}
				}
			}
		});
		return response;
	}

	renderTab = (tab) => {
		const { loggedInUser, work_order, form: { getFieldDecorator } } = this.props;
		if (canEditActualWork(loggedInUser, work_order, tab)) {
			this.currentTab = tab;
			return getFieldDecorator('actual_work', {
				initialValue: get(this, `state.data.${tab}`, { data: [] })
			})(
				<WorkEnvelopeTable />
			)
		} else {
			return (
				<WorkEnvelopeTable
					readOnly
					value={get(this, `state.data.${tab}`, { data: [] })}
				/>
			);
		}
	}

  render() {
		const { work_order } = this.props;
    if (!work_order) {
      return null;
		}
    return (
      <div className={wrapper} >
				<Tabs>
					<TabPane tab='Composing' key='composing' forceRender>
					{
						this.renderTab('COMPOSING_DIVISION')
					}
					</TabPane>
					<TabPane tab='Photo-Litography' key='photo_litography' forceRender>
					{
						this.renderTab('PHOTOLITOGRAPHY_DIVISION')
					}
					</TabPane>
					<TabPane tab='Press' key='press' forceRender>
					{
						this.renderTab('PRESS_DIVISION')
					}
					</TabPane>
					<TabPane tab='Finishing' key='finishing' forceRender>
					{
						this.renderTab('FINISHING_DIVISION')
					}
					</TabPane>
					<TabPane tab='Sales and Marketing' key='sales_marketing' forceRender>
					{
						this.renderTab('SALES_DIVISION')
					}
					</TabPane>
				</Tabs>
			</div>
    );
  }
}

export default Form.create()(ActualWorkTab);

// return (
// 	<div className={wrapper} >
// 		<Tabs>
// 			<TabPane tab='Size' key='size' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.size', { data: [], notes: '' })}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Works' key='works' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.works', { data: [], notes: '' })}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Composing' key='composing' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.composing', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						estimates={get(work_order, 'estimates', [])}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'COMPOSING')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'COMPOSING' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Photo-Litography' key='photo_litography' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.photo_litography', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'PHOTOLITOGRAPHY')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'PHOTOLITOGRAPHY' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Press' key='press' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.press', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'PRESS')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'PRESS' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Finishing' key='finishing' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.finishing', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'FINISHING')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'FINISHING' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Sales and Marketing' key='sales_marketing' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.sales_marketing', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'SALES')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'SALES' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 		</Tabs>
// 	</div>
// );