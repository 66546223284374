import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import debounce from 'lodash/debounce';
import { axios } from 'shared/utils/api_client';
import { API_WORK_ORDERS, API_WORK_ORDER } from 'shared/constants/urls';
import {getAuthHeader} from 'shared/utils/api_client';
const Option = Select.Option;

export default class extends Component {
  constructor(props) {
    super(props);    
    const value = props.value || undefined;
    this.state = {
      value: value,
      data: [],
      fetching: false,
      getting: false, 
    };
    this.lastFetchId = 0;
    this.lastGetId = 0;
    this.fetchData = debounce(this.fetchData, 500);
    this.getWorkOrder = debounce(this.getWorkOrder, 500);
  }

  // // for handling resetFields
  componentWillReceiveProps(props) {
    if ('value' in props) {
      this.setState({
        value: props.value || undefined,
      });
    }
  }

  componentDidMount = () => {
    this.fetchData(' ')
  }

  clear = () => {
    this.setState({ value: '' });
  }

  fetchData = (value) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    axios.get(API_WORK_ORDERS, { params: { take: 20, q: value, woe_status: ['COMPLETED'] }, headers: { ...getAuthHeader() }})
    .then((response) => {
      if (fetchId !== this.lastFetchId) { // for fetch callback order
        return;
      }
      this.setState({data: response.data.data, fetching: false});
    })
    .catch((error) => {
      notification['error']({ message: error.message });
    });
  }

  getWorkOrder = (id) => {
    this.lastGetId += 1;
    const getId = this.lastGetId;
    this.setState({ value: '', getting: true });
    axios.get(`${API_WORK_ORDER}/${id}`, { headers: { ...getAuthHeader() }})
    .then((response) => {
      if (getId !== this.lastGetId) { 
        return;
      }
      this.setState({value: response.data, getting: false});
      if (this.props.onChange) {
        this.props.onChange(response.data);
      }
    })
    .catch((error) => {
      notification['error']({ message: error.message });
    });
  }

  handleChange = (value) => {
    this.getWorkOrder(value)
  }

  render() {
    const { fetching, data, value, getting } = this.state;
    const { disabled } = this.props;
    return (
      <Select
        showSearch
        mode="default"
        value={value ? value.id : ''}
        placeholder="Select Work Order"
        notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
        filterOption={false}
        loading={getting}
        onSearch={this.fetchData}
        onChange={this.handleChange}
        style={{ width: '100%' }}
        disabled={disabled}
      >
        {data.map(d => <Option key={d.id} value={d.id}>{d.woe_number}</Option>)}
      </Select>
    )

  }
}
