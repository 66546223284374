import React, { Component } from 'react';
import { Form } from 'antd';
import { css } from 'emotion';
import { } from 'lodash';
import WorkOrderCostEstimateList from './WorkOrderCostEstimateList';

const wrapper = css({
	margin: '25px',
	display: 'flex', 
	flexDirection: 'column',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
	'& .top-form-container': {
		display: 'flex',
	}
});

class PublicationEstimates extends Component {
  getValidatedData = () => {
		const { form } = this.props;
		const response = {};
		form.validateFieldsAndScroll((error, values) => {
			if (error) {
				response.error = error;
			} else {
				response.values = {
          estimate_numbers: values.estimate_numbers.map(estimate => estimate.estimate_number)
        };
			}
		});
		return response;
  }
  
  render() {
    const { form: { getFieldDecorator } } = this.props;
    return (
      <div className={wrapper}>
        <div className='form-group-title'>
          Cost Estimate/s
        </div>
        <div className='top-form-container'>
        {
          getFieldDecorator('estimate_numbers', { 
            rules: [{
              required: true, message: 'Required field.',
            }], 
            initialValue: []
          })(
            <WorkOrderCostEstimateList />
          )
        }
        </div>
      </div>
    );
  }
}

export default Form.create()(PublicationEstimates);