import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import debounce from 'lodash/debounce';
import { axios } from 'shared/utils/api_client';
import { API_PARTNERS } from 'shared/constants/urls';
import {getAuthHeader} from 'shared/utils/api_client';
const Option = Select.Option;

export default class CaseSearchBox extends Component {
    constructor(props) {
        super(props);    
        const value = props.value || undefined;
        this.state = {
            value: value,
            data: [],
            fetching: false
        };
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 500);
    }

    // // for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            this.setState({
                value: props.value || undefined,
            });
        }
    }

    componentDidMount = () => {
        this.fetchUser('');
    }

    clear = () => {
        this.setState({ value: '' });
    }

    fetchUser = (value) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        axios.get(API_PARTNERS, { params: { take: 20, q: value }, headers: { ...getAuthHeader() }})
        .then((response) => {
            if (fetchId !== this.lastFetchId) { // for fetch callback order
                return;
            }
            this.setState({data: response.data.data, fetching: false});
        })
        .catch((error) => {
            notification['error']({ message: error.message });
        });
    }

    handleChange = (value, option) => {
        this.setState({value: value});
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        if (this.props.onChange2) {
            this.props.onChange2(option.props.fulldata)
        }
    }

    render() {
        const { fetching, data, value } = this.state;
        return (
            <Select
                showSearch
                mode="default"
                value={value}
                placeholder="Search for partner"
                notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                filterOption={false}
                onSearch={this.fetchUser}
                onChange={this.handleChange}
                style={{ width: '100%' }}
            >
                {data.map(d => <Option key={d.id} value={d.id} fulldata={d}>{d.partner_name}</Option>)}
            </Select>
        )

    }
}
