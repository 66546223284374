import React, { Component } from 'react';
import { Divider, Form, Input, Col, Row, DatePicker } from 'antd';
import EstimateSearch from '../components/EstimateSearch';
import DivTotal from 'shared/components/DivTotal';
import moment from 'moment';

const FormItem = Form.Item;

class CreateUpdateForm extends Component {
  onEstimateSelected = (estimate) => {
    const { form } = this.props;
    const { setFieldsValue } = form;
    if (estimate.type === 'Publication') {
      setFieldsValue({
        type: estimate.type,
        estimate_id: estimate.id,
        op_data: {
          charged_to: estimate.data.partner.partner_name,
          contact_person: estimate.data.partner.contact_persons[0].name,
          deliver_to: estimate.data.partner.delivery_address,
        },
        op_particulars: `${estimate.job_title} ${estimate.data.case_number || ''}`,
        op_amount: estimate.total_amount
      });
      return;
    }
    setFieldsValue({
      type: estimate.type,
			estimate_id: estimate.id,
			op_data: {
				charged_to: estimate.data.partner.partner_name,
				contact_person: estimate.data.partner.contact_persons[0].name,
				deliver_to: estimate.data.partner.delivery_address,
				op_quantity: estimate.data.quantity.value1,
				op_unit: estimate.data.quantity.unit1
			},
			op_particulars: `${estimate.job_title} ${estimate.data.serial_number || ''}`,
			op_amount: estimate.total_amount
    });
  }

  componentDidMount() {
    if (this.props.data) {
      const { form, data } = this.props;
      const { setFieldsValue } = form;
      setFieldsValue({
        type: data.type,
        estimate_id: data.estimate_id,
        op_data: {
          charged_to: data.op_data.charged_to,
          contact_person: data.op_data.contact_person,
          deliver_to: data.op_data.deliver_to,
          op_quantity: data.op_data.op_quantity,
          op_unit: data.op_data.op_unit
        },
        op_particulars: data.op_particulars,
        op_amount: data.op_amount,
        op_date: moment(data.op_date)
      });
    }
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const type = getFieldValue('type');
    return (
      <Form>
        <FormItem wrapperCol={{ span: 0 }} style={{ margin: '0px', padding: '0px' }}> { /* hidden */ }
          {getFieldDecorator('type', { hidden: true })(<Input disabled/>)}
        </FormItem>
        <FormItem
          label='Estimate Number'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ margin: '0px' }}
        >
          {getFieldDecorator('estimate_id', {
            rules:[
              { required: true, message: 'Required field' }
            ]
          })(
            <EstimateSearch 
              onChange2={this.onEstimateSelected}
            />
          )}
        </FormItem>
        <Divider />
        <FormItem
          label='Charged to'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ margin: '0px' }}
        >
          {getFieldDecorator('op_data.charged_to')(
            <Input disabled />
          )}
        </FormItem>
        <FormItem
          label='Contact Person'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ margin: '0px' }}
        >
          {getFieldDecorator('op_data.contact_person')(
            <Input disabled />
          )}
        </FormItem>
        <FormItem
          label='Deliver To'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ margin: '0px' }}
        >
          {getFieldDecorator('op_data.deliver_to')(
            <Input disabled />
          )}
        </FormItem>
				<Divider />
				<FormItem
          label='Order of Payment Date'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {getFieldDecorator('op_date', {
            rules: [
              { required: true, message: 'Required field.'}
            ]
          })(
            <DatePicker />
          )}
        </FormItem>
        <Row gutter={24}>
          {
            type !== 'Publication' &&
            <Col xs={24} sm={4}>
              <FormItem
                label='Qty'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}	
              >
                {
                  getFieldDecorator('op_data.op_quantity')(<Input disabled />)
                }
              </FormItem>
            </Col>
          }
					{
            type !== 'Publication' &&
            <Col xs={24} sm={6}>
              <FormItem
                label='UNIT'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}	
              >
                {
                  getFieldDecorator('op_data.op_unit')(<Input disabled />)
                }
              </FormItem>
            </Col>
          }
					<Col xs={24} sm={14}>
						<FormItem
							label='PARTICULARS'
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}	
						>
							{
								getFieldDecorator('op_particulars')(<Input.TextArea />)
							}
						</FormItem>
					</Col>
				</Row>
				<FormItem
					label='Amount Due'
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }}	
				>
					{
						getFieldDecorator('op_amount')(<DivTotal hideTotal />)
					}
				</FormItem>
      </Form>
    );
  }
}

export default Form.create()(CreateUpdateForm)