import React from 'react';
import { css } from 'emotion';
import { get, last, sortBy } from 'lodash';
import moment from 'moment';

const container = css({
  padding: '15px',
  background: '#FFFFFF', 
  boxShadow: '1px 0px 3px grey',
  display: 'flex',
  flexDirection: 'row',
  '& .details': {
    flex: '1',
    '& .previous-route': {
      marginBottom: '7px',
      fontWeight: 'bold'
    },
    '& .section': {
      color: '#328fd9',
      fontWeight: 'bold'
    }, 
    '& .text': {
      color: '#c4c4c4',
      '& .name': {
        color: '#8993a4',
        fontWeight: 'bold'
      }, 
    }, 
  },
  '& .notes': {
    flex: '1',
    fontWeight: 'bold'
  }
});

function getLastRoute(props) {
  const progress_list = sortBy(get(props, 'work_order.progress', []), 'id');
  return last(progress_list) || {}; // , { action: 'Release' }) || {}
}

function lastActionSection(route) {
  return (route === 'Release')
    ? `Released by ${route.section}`
    : `Received by ${route.section}`;
}

function lastActionUser(route) {
  return (route === 'Release')
    ? `Released by `
    : `Received by `;
}

function getDate(route) {
  if (route.action_date) {
    return moment(route.action_date).format('MM/DD/YYYY - H:mm A')
  }
}

export default (props) => {
  const last_route = getLastRoute(props);
  return (
    <div className={container}>
      <div className='details'>
        <div className='previous-route'>
          Previous Route: { last_route.division }
        </div>
        <div className='section'>
          { lastActionSection(last_route) }
        </div>
        <div className='text'>
          { lastActionUser(last_route) }<span className='name'> { last_route.action_by_name } </span>
        </div>
        <div className='text'>
          { getDate(last_route) }
        </div>
      </div>
      <div className='notes'>
          " { last_route.notes } "
      </div>
    </div>
  );
}