import { axios } from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';
import {
	API_OP,
	API_OPS
} from 'shared/constants/urls';
import { getAuthHeader } from 'shared/utils/api_client';

export const TYPES = {
	GET_LIST: 'GET_OPS',
	GET: 'GET_OP',
	CREATE: 'CREATE_OP',
	UPDATE: 'UPDATE_OP',
	REQUEST: 'REQUEST_OP',
	ERROR: 'ERROR_OP'
}

export const getOPs = (params) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });
		axios.get(API_OPS, {
			headers: { ...getAuthHeader() },
			params: { ...params, only_with_estimates: true }
		})
		.then(response => dispatch({ type: TYPES.GET_LIST, payload: response.data }))
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}

export const getOP = (id) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.get(`${API_OP}/${id}`, {
			headers: { ...getAuthHeader() }
		})
		.then(response => dispatch({ type: TYPES.GET, payload: response.data }))
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}

export const createOP = (op, successCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_OP}`, op, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { dispatch({ type: TYPES.CREATE, payload: response.data }); if (successCallback) { successCallback(); } })
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}

export const deleteOP = (op) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.delete(`${API_OP}/${op.id}`, {
			headers: { ...getAuthHeader() }
		})
		.then(response => dispatch({ type: TYPES.UPDATE, payload: response.data }))
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}

export const updateOP = (op, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.put(`${API_OP}/${op.id}`, op, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
            dispatch({ type: TYPES.UPDATE, payload: response.data }); 
            if (successCallback) { 
                successCallback(response.data); 
            } 
        })
		.catch(error => {
            dispatch({ type: TYPES.ERROR, payload: getError(error)})
            if (errorCallback) {
                errorCallback(getError(error))
            }
        })
	}
}
