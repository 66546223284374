export const getEstimate = (estimates, props) => {
  if (!props.match || !props.match.params || !props.match.params.id) {
      return undefined;
  }
  const id = props.match.params.id;

  if (!estimates || estimates.length <= 0) {
      return undefined;
  }

  return estimates.find(estimate => estimate.id === parseInt(id))
}

export const getPCIdFromUrl = (props) => {
  if (!props.match || !props.match.params || !props.match.params.id) {
    return undefined;
  }
  return props.match.params.id;
}

export const getModeFromProps = (props) => {
  if (!props.location || 
      !props.location.pathname) {
          return 'new';
  }

  if (props.location.pathname.includes('edit')) {
      return 'edit';
  }

  if (props.location.pathname.includes('view')) {
    return 'view';
  }

  if (!props.match || !props.match.params || !props.match.params.id) {
      return 'new';
  }

  return 'copy';
}