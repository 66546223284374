import { axios } from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';
import {
	API_FILE
} from 'shared/constants/urls';
import { getAuthHeader } from 'shared/utils/api_client';

export const downloadFile = (filename, location, errorCallback) => {
	axios.get(`${API_FILE}`, {
		responseType: 'blob',
		headers: { ...getAuthHeader() },
		params: { location: location }
	})
	.then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `ppcd-file-${filename}`); 
		document.body.appendChild(link);
		link.click();
	})
	.catch(error => {
		if (errorCallback) {
			errorCallback(getError(error));
		} 
	})
}