import { axios } from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';
import {
  API_WORK_ORDER, 
  API_WORK_ORDERS
} from 'shared/constants/urls';
import { getAuthHeader } from 'shared/utils/api_client';

export const TYPES = {
	GET_LIST: 'GET_WORK_ORDERS',
	GET: 'GET_WORK_ORDER',
	CREATE: 'CREATE_WORK_ORDER',
	UPDATE: 'UPDATE_WORK_ORDER',
	WO_ACTION: 'ACTION_WORK_ORDER',
	REQUEST: 'REQUEST_WORK_ORDER',
	ERROR: 'ERROR_WORK_ORDER'
}

export const getWorkOrders = (params) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.get(API_WORK_ORDERS, {
			headers: { ...getAuthHeader() },
			params: { ...params }
		})
		.then(response => dispatch({ type: TYPES.GET_LIST, payload: response.data }))
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}

export const getWorkOrder = (id, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.get(`${API_WORK_ORDER}/${id}`, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			if (successCallback) {
				successCallback(response.data)
			}
			dispatch({ type: TYPES.GET, payload: response.data })
		})
		.catch(error => {
			if (errorCallback) {
				errorCallback(error);
			}
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
		})
	}
}

export const createWorkOrder = (work_order, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_WORK_ORDER}`, work_order, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.CREATE, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const updateWorkOrder = (id, work_order, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });
		axios.put(`${API_WORK_ORDER}/${id}`, work_order, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.UPDATE, payload: response.data })
			if (successCallback) {
				successCallback()
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const generateCutStock = (work_order, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_WORK_ORDER}/${work_order.id}/generate_cs`, work_order, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.UPDATE, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const downloadCutStockPdf = (work_order, errorCallback) => {
	return dispatch => {
		axios.get(`${API_WORK_ORDER}/${work_order.id}/cut_stock`, {
			responseType: 'blob',
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${work_order.woe_number}_cutstock.pdf`); 
			document.body.appendChild(link);
			link.click();
		})
		.catch(error => {
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const downloadWoPdf = (work_order, errorCallback) => {
	return dispatch => {
		axios.get(`${API_WORK_ORDER}/${work_order.id}/work_order`, {
			responseType: 'blob',
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${work_order.woe_number}_envelope.pdf`); 
			document.body.appendChild(link);
			link.click();
		})
		.catch(error => {
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const receive = (work_order, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_WORK_ORDER}/${work_order.id}/receive`, null, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.WO_ACTION, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const release = (work_order, division, data, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		const { estimate_numbers, ...actual_work } = data;
		let estimate_nos = undefined;
		if (!!estimate_numbers && Array.isArray(estimate_numbers)) {
			estimate_nos = estimate_numbers.map(number => number.estimate_number)
		}
		axios.post(`${API_WORK_ORDER}/${work_order.id}/release`, { 
				division, 
				estimate_numbers: estimate_nos || undefined,
				actual_work: {
					estimates: estimate_numbers,
					...actual_work
				},
			}, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.UPDATE, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const save = (id, data, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_WORK_ORDER}/${id}/save_release`, data, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.WO_ACTION, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const releaseToDivision = (id, data, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_WORK_ORDER}/${id}/release_to_division`, data, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.WO_ACTION, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const returnToPpcd = (id, data, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_WORK_ORDER}/${id}/return_to_ppcd`, data, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.WO_ACTION, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const partialRelease = (id, data, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_WORK_ORDER}/${id}/partial_release`, data, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.WO_ACTION, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}

export const partialReceive = (id, data, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_WORK_ORDER}/${id}/partial_receive`, data, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			dispatch({ type: TYPES.WO_ACTION, payload: response.data })
			if (successCallback) {
				successCallback(response.data)
			}
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error)
			}
		})
	}
}
