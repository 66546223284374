import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import debounce from 'lodash/debounce';
import { axios } from 'shared/utils/api_client';
import { API_WORK_ORDERS, API_WORK_ORDER } from 'shared/constants/urls';
import {getAuthHeader} from 'shared/utils/api_client';
const Option = Select.Option;

export default class extends Component {
    constructor(props) {
        super(props);    
        const value = props.value || undefined;
        this.state = {
            value: value,
            data: [],
            fetching: false,
            fetchingSelected: false,
        };
        this.lastFetchId = 0;
        this.fetchData = debounce(this.fetchData, 500);
    }

    // // // for handling resetFields
    // componentWillReceiveProps(props) {
    //     if ('value' in props) {
    //         this.setState({
    //             value: props.value || undefined,
    //         });
    //     }
    // }

    componentDidMount = () => {
        this.fetchData('wo');
    }

    clear = () => {
        this.setState({ value: '' });
    }

    fetchData = (value) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        axios.get(API_WORK_ORDERS, { params: { take: 20, q: value }, headers: { ...getAuthHeader() }})
        .then((response) => {
            if (fetchId !== this.lastFetchId) { // for fetch callback order
                return;
            }
            this.setState({data: response.data.data, fetching: false});
        })
        .catch((error) => {
            notification['error']({ message: error.message });
        });
    }

    fetchSelected = (id) => {
        this.setState({ value: '', fetchingSelected: true });
        axios.get(`${API_WORK_ORDER}/${id}`, { headers: { ...getAuthHeader() }})
        .then((response) => {
            this.setState({value: response.data, fetchingSelected: false});
            if (this.props.onChange2) {
                this.props.onChange2(response.data);
            }
        })
        .catch((error) => {
            this.setState({fetchingSelected: false});
            notification['error']({ message: error.message });
        });
    }

    handleChange = (value, option) => {
        this.fetchSelected(option.props.fulldata.id)
        // this.setState({value: option.props.fulldata});
        // if (this.props.onChange) {
        //     this.props.onChange(value);
        // }
        // if (this.props.onChange2) {
        //     this.props.onChange2(option.props.fulldata);
        // }
    }

    render() {
        const { fetching, fetchingSelected, data, value } = this.state;
        return (
            <Select
                showSearch
                mode="default"
                value={value ? value.woe_number : ''}
                placeholder="Select Work Order"
                notFoundContent={fetching || fetchingSelected ? <Spin size="small" /> : 'Not Found'}
                filterOption={false}
                onSearch={this.fetchData}
                onChange={this.handleChange}
                style={{ width: '100%' }}
            >
                {data.map(d => <Option key={d.id} value={d.woe_number} fulldata={d}>{d.woe_number}</Option>)}
            </Select>
        )

    }
}
