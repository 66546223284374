import {combineReducers} from 'redux'
import { loggedInUser } from 'layouts/landing/reducers/login_reducer';
import { loadingReducer } from 'layouts/main/reducers/loading_reducer';
import { errorReducer } from 'layouts/main/reducers/error_reducer';
import partnerReducer from 'modules/partner/reducers';
import estimateReducer from 'modules/estimate/reducers';
import woReducer from 'modules/work_order/reducers';
import opReducer from 'modules/orderofpayment/reducers';
import pcReducer from 'modules/productioncost/reducers';
import notificationReducer from 'modules/notification/reducers';

const appReducer = combineReducers({
    loggedInUser: loggedInUser,
    loadingReducer: loadingReducer,
    errorReducer: errorReducer,
    partner: partnerReducer,
    estimate: estimateReducer,
    work_order: woReducer,
    order_of_payment: opReducer,
    production_cost: pcReducer,
    notification: notificationReducer,
});

// Clear all states after the user logs out
const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;