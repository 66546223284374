import React, { Component } from 'react';
import accounting from 'accounting';
import { isEqual } from 'lodash';
import EditableTable from 'shared/components/EditableTable';
import DivTotal from 'shared/components/DivTotal';

export default class extends Component {
	state = {};

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.loadData();
    }
  }

  loadData = () => {
    const { data, total } = this.props.value;
    const newData = data.map((datum, index) => {
      return {
        ...datum,
        key: index
      };
    });
    this.setState({ value: { data: newData, total } });
  }

  columns = [
    {
      title: 'Qty',
      dataIndex: 'quantity',
      width: '8%',
      inputType: 'number',
      rules: [{ type: 'number', message: 'Invalid input' }],
      editable: !this.props.readOnly,
      required: true,
      onUpdate: (value, form) => {
        const { getFieldValue, setFieldsValue } = form;
        const rate = getFieldValue('rate') || 0;
        setFieldsValue({
          amount: value * rate
        });
      }
    }, {
      title: 'Unit',
      dataIndex: 'unit',
      inputType: 'unit',
      width: '8%',
      editable: !this.props.readOnly,
      required: true,
    }, {
      title: 'Kind',
      dataIndex: 'kind',
      width: '24%',
      editable: !this.props.readOnly,
      required: true,
    }, {
      title: 'Size (LxW)',
      dataIndex: 'size',
      width: '25%',
      // inputType: 'dimension',
      rules: [{ pattern: new RegExp('^[0-9."mXx, ]+$'),  message: 'Invalid input' }],
      editable: !this.props.readOnly,
      required: true,
    }, {
      title: 'Weight',
      dataIndex: 'weight',
      width: '8%',
      editable: !this.props.readOnly,
      // required: true,
    }, {
      title: 'Rate',
      dataIndex: 'rate',
      inputType: 'float',
      rules: [{ type: 'number', message: 'Invalid input' }],
      width: '8%',
      editable: !this.props.readOnly,
      required: true,
      onUpdate: (value, form) => {
        const { getFieldValue, setFieldsValue } = form;
        const quantity = getFieldValue('quantity') || 0;
        setFieldsValue({
          amount: value * quantity
        });
      }
    }, {
      title: 'Total Amount',
      dataIndex: 'amount',
      inputType: 'float',
      editable: !this.props.readOnly,
      disabled: () => true,
      width: '19%',
      render: (text, record, index) => <span style={{ fontWeight: 'bold' }}>{accounting.formatMoney(text, '₱', 2, ',', '.')}</span>,
    }
  ];

  beforeRowSave = (row) => {
    row.amount = row.quantity * row.rate
  }

  onChange = (value) => {
    const total = value.reduce((a, b) => {
      const first = typeof a === 'number' ? a : parseFloat(a.amount, 10)
      const second = typeof b === 'number' ? b : parseFloat(b.amount, 10)
      return first + second
    }, 0);
    
    this.setState({ 
      value: { data: value, total }
    });

    if (this.props.onChange) {
      this.props.onChange({ data: value, total });
    }
  }

  render() {
    const { value } = this.state;
    if (!value) {
      return null;
    }
    const { data, total } = value;
    return (
      <div style={{ margin: '20px' }}>
        <EditableTable
          value={data}
          columns={this.columns}
          // beforeRowSave={this.beforeRowSave}
          onChange={this.onChange}
        />
        <DivTotal value={total} />
      </div>
    );
  }
}