import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Spin, notification } from 'antd';
import { css } from 'emotion';
import { get, includes } from 'lodash';
import Header from 'shared/components/Header';
import { createWorkOrder, updateWorkOrder, getWorkOrder } from 'modules/work_order/actionCreators';
import { getType, getModeFromProps, getWorkOrderIdFromUrl } from 'modules/work_order/selectors';
import PublicationForm from './components/PublicationForm';
import StandardForm from './components/StandardForm';

const parentStyle = css({
	height: '100%',
	width: '100%',
	background: '#F6FCFF',
	'& .loading': {
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center', 
		position: 'absolute', 
		top: '0', 
		bottom: '0', 
		left: '0', 
		right: '0', 
		backgroundColor: '#26272955' 
	},
	'& .container': {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	}
});

function disabledAdd (props) {
	const permissions = get(props, 'loggedInUser.user.permissions.ppcd');
	if (!Array.isArray(permissions)) {
			return true;
	}
	return !permissions.includes('ppcd_add_work_order');
}

class CreateUpdate extends Component {
	componentDidMount () {
		if (disabledAdd(this.props)) {
			notification.warning({
				message: 'Uh Oh!',
				description: 'You do not have permission to view this module.',
			});
			this.props.history.replace('/workorder');
			return;
		}
		if (this.loadData()) {
			return;
		}
		if (!this.props.type && this.props.mode === 'new') {
			this.props.history.replace('/workorder');
		}
	} 

	componentDidUpdate () {
		if (this.loadData()) {
			return;
		}
		if (!this.props.type && this.props.mode === 'new') {
			this.props.history.replace('/workorder');
		}
	}

	loadData = () => {
		const currentDataId = get(this.props, 'work_order.id', -1);
		const work_orderID = get(this.props, 'work_orderID', -1);
		if (this.props.mode !== 'new' && currentDataId !== work_orderID) {
			this.props.getWorkOrder(
				work_orderID, 
				undefined, 
				this.errorCallback
			);
			return true;
		}
		const canEdit = includes(['Writer Section', 'Monitoring Section'], get(this, 'props.work_order.woe_current_step'));
		if (this.props.mode === 'edit' 
				&& currentDataId === work_orderID
				&& !canEdit) {
				notification.warning({
					message: 'Uh Oh!',
					description: 'Work order cannot be edited.',
				});
				this.props.history.replace('/workorder');
				return true;
		}
		return false;
	}

	onFormSave = (values) => {
		const id = get(this, 'props.work_order.id', undefined);
		if (this.props.mode === 'edit' && id) {
			this.props.updateWorkOrder(id, values, this.successCallback, this.errorCallback);
			return;
		}
		const { type, mode } = this.props;
		if (mode === 'new') {
			values.type = type;
		}
		this.props.createWorkOrder(values, this.successCallback, this.errorCallback);
	}

	close = () => {
		this.props.history.replace('/workorder');
	}

	successCallback = (response) => {
		const work_order_id = get(response, 'work_order.id', -1);
		this.props.history.push({
			pathname: work_order_id !== -1 ? `/workorder/${work_order_id}` : '/workorder', 
			state: { reload: true }
		});
	}

	errorCallback = (error) => {
		notification.error({ message: error.message });
	}

	renderForm = () => {
		const currentDataId = get(this.props, 'work_order.id', -1);
		const work_orderID = get(this.props, 'work_orderID', -1);
		if (currentDataId !== work_orderID) {
			return null;
		}
		const { type, work_order } = this.props;
		switch (type) {
			case 'Standard':
			case 'Accountable':
			case 'Specialized':
				return (
					<StandardForm 
						type={type}
						onFormSave={this.onFormSave}
						work_order={work_order} 
					/>
				);
			case 'Publication':
				return (
					<PublicationForm 
						onFormSave={this.onFormSave}
						work_order={work_order} 
					/>
				);
			default:
				return null;
		}
	}

	render() {
		const { loading } = this.props;
		return (
			<div className={parentStyle}>
				<div className='container'>
					<Header 
						title={`Work Order Form`} 
						containerStyle={{ background: '#FFFFFF', padding: '15px 20px', boxShadow: '10px 0px 5px grey' }} 
						titleStyle={{ color: '#2B3D44', fontWeight: '500' }}>
						<Icon onClick={this.close} type='close' style={{ fontSize: '2em', color: '#797979' }} />
					</Header>
					{
						this.renderForm()
					}
				</div>
				{
					loading && 
						<div className='loading'>
							<Spin size='large' />
						</div>
				}
			</div>
		);
	}
}

const mapStateToProps = ({ loggedInUser, work_order }, ownProps) => {
	const mode = getModeFromProps(ownProps);
	const work_orderID = getWorkOrderIdFromUrl(ownProps);
	return {
		loading: work_order.loading,
		error: work_order.error,
		work_order: mode === 'edit' || mode === 'copy' ? work_order.work_order : undefined,
		loggedInUser,
		mode,
		work_orderID,
		type: getType(work_orderID, work_order.work_order, ownProps),
	};
}

const mapDispatchToProps = {
  createWorkOrder, updateWorkOrder, getWorkOrder
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);