import React, { Component } from 'react';
import { css } from 'emotion';
import { get } from 'lodash';
import accounting from 'accounting';

const container = css({
  background: '#FFFFFF', 
  boxShadow: '1px 0px 3px grey',
  padding: '35px',
  '& .title': {
    fontSize: '1.5em',
    fontWeight: 'bold', 
    color: '#4B5874',
  },
  '& .subtitle': {
    fontSize: '1em',
    color: '#B5B6B8',
    '& .estimate-number': {
      color: '#6bafe4',
    }
  },
  '& .publication-estimate': {
    borderBottom: '1px solid #9AA0A5',
    padding: '10px',
    '& .partner-name': {
      fontSize: '22px',
      color: '#2B3D44',
      margin: '10px 0px',
    },
    '& .item': {
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      '& .label': {
        flex: '1',
        color: '#9AA0A5', 
        fontSize: '1em',
        textAlign: 'end',
        '& .label-bold': {
          fontWeight: 'bold'
        }
      },
      '& .value': {
        flex: '1',
        color: '#646970', 
        fontSize: '1.2em',
        textAlign: 'end', 
        '& .value-bold': {
          fontWeight: 'bold'
        }
      },
      '@media (min-width: 720px)': {
          flexDirection: 'row', 
          minWidth: '60%',
      },
    },
  },
  '& .standard-estimate': {
    width: '80%',
    padding: '35px 0px',
    '& .division-name': {
      fontSize: '1.4em',
      color: '#6bafe4',
    },
    '& .item': {
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      '& .label': {
        flex: '1',
        color: '#9AA0A5', 
        fontSize: '1em',
        textAlign: 'end',
        '& .label-bold': {
          fontWeight: 'bold'
        }
      },
      '& .value': {
        flex: '1',
        color: '#646970',
        fontSize: '1.2em',
        textAlign: 'end', 
        '& .value-bold': {
          fontWeight: 'bold'
        }
      },
      '@media (min-width: 720px)': {
          flexDirection: 'row', 
          minWidth: '60%',
      },
    },
  },
});

export default class CostEstimateBreakdown extends Component {
  renderPublicationCostEstimate = (estimate, index) => {
		return (
			<div className='publication-estimate' key={index.toString()}>
        <div className='partner-name'>{get(estimate, 'data.partner.partner_name')}</div>
        <div className='item' >
          <div className='label'>Publication Type:</div>
          <div className='value'>{get(estimate, 'job_title')}</div>
        </div>
        <div className='item' >
          <div className='label'>
            <span className='label-bold'>
              Publication Fee:
            </span>
          </div>
          <div className='value'>
            <span className='value-bold'>
              {accounting.formatMoney(get(estimate, 'total_amount', 0), '₱', 2, ',', '.')}
            </span>
          </div>
        </div>
			</div>
		);
  }
  
  renderDivisionBreakdown = (label, division) => {
    const data = get(division, 'data', []);
		return (
      <div className='standard-estimate'>
        <div className='division-name'>
          {label}
        </div>
				{
					data.map((datum, index) => {
						return (
              <div key={index.toString()} className='item'>
                <div className='label'>{get(datum, 'work_type') || get(datum, 'item') || get(datum, 'kind')}</div>
                <div className='value'>{accounting.formatMoney(get(datum, 'amount', 0), '₱', 2, ',', '.')}</div>
              </div>
						);
					})
        }
        <div className='item'>
          <div className='label'><span className='label-bold'>Division Total:</span></div>
          <div className='value'><span className='value-bold'>{accounting.formatMoney(get(division, 'total', 0), '₱', 2, ',', '.')}</span></div>
        </div>
			</div>
		);
	}

  render() {
    const { work_order } = this.props;
    if (!work_order) {
      return null;
    }
    const { type } = work_order;
    if (type === 'Publication') {
      return (
        <div className={container} >
          <div className='title'>
            Publication
          </div>
          {
						work_order.estimates && Array.isArray(work_order.estimates) &&
						work_order.estimates.map((estimate, index) => {
							return this.renderPublicationCostEstimate(estimate, index);
						})
					}
        </div>
      );
    }

    return (
      <div className={container} >
        <div className='title'>
          Cost Estimate
        </div>
        <div className='subtitle'>
          Cost Estimate Number: <span className='estimate-number'>{get(work_order, 'estimates[0].estimate_number')}</span>
        </div>
        {
          this.renderDivisionBreakdown('Composing', get(work_order, 'estimates[0].data.division.composing'), true)
        }
        {
          this.renderDivisionBreakdown('Photo-litography', get(work_order, 'estimates[0].data.division.photo_litography'))
        }
        {
          this.renderDivisionBreakdown('Press', get(work_order, 'estimates[0].data.division.press'))
        }
        {
          this.renderDivisionBreakdown('Finishing', get(work_order, 'estimates[0].data.division.finishing'))
        }
        {
          this.renderDivisionBreakdown('Indirect', get(work_order, 'estimates[0].data.division.indirect'))
        }
        {
          this.renderDivisionBreakdown('Stock', get(work_order, 'estimates[0].data.division.stock'))
        }
      </div>
    );
  }
}