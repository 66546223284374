import React, { Component } from 'react';
import { Form, Input, Checkbox, InputNumber, Row, Col } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import { ordinal_suffix_of } from 'shared/utils/numbers';
import ColorSearchBox from 'shared/components/ColorSearchBox';

const FormItem = Form.Item;

const container = css({
  margin: '25px',
	display: 'flex', 
	flexDirection: 'column',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
  '& .formcol': {
    display: 'flex',
    flexDirection: 'row',
  },
});

class StandardFormPage3 extends Component {
  componentDidMount () {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.values && this.props.values) {
      this.loadData();
    }
  }

  loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
		}
    const { setFieldsValue } = form;
    setFieldsValue({
			job_title: get(values, 'job_title'),
			woe_data: {
        quantity: get(values, 'woe_data.quantity'),
        number_of_copies: get(values, 'woe_data.number_of_copies', 1),
        multiple_copies: get(values, 'woe_data.multiple_copies'),
        // unit_price: get(values, 'woe_data.unit_price'),
        // property_number: get(values, 'woe_data.property_number')
			}
    });
  }

  onContinuePressed = (saveAsDraft) => {
    const { onFormSave, values, moveToNext, form } = this.props;
    form.validateFields((err, val) => {
      if (saveAsDraft) {
        onFormSave({
          ...values,
          ...val,
          woe_data: {
						...get(values, 'woe_data', {}),
						...get(val, 'woe_data', {})
					},
          as_draft: true
        });
      } else if (!err) {
        moveToNext({
          ...val,
          woe_data: {
            ...val.woe_data
          },
        });
			}
    });
  }
  
  multipleCopiesChanged = (e) => {
    const checked = e.target.checked;
    if (!checked) {
      const { form } = this.props;
      const { getFieldsValue, setFieldsValue } = form;
      const values = getFieldsValue();
      setFieldsValue({
        woe_data: {
          ...values.woe_data,
          number_of_copies: 1,
        }
      });
    }
  }

  renderCopies = () => {
    const { form, values } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const copiesCount = getFieldValue('woe_data.number_of_copies')
    const copies = get(values, 'woe_data.copies', [])
    let i;
    const children = [];
    for (i = 0; i < copiesCount; i++) {
          children.push(
            <Col key={i.toString()} span={8}>
              <FormItem
                label={`${ordinal_suffix_of(i + 1)} Ply`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {
                  getFieldDecorator(`woe_data.copies[${i}]`, {
                      initialValue: copies[i],
                      rules: [{
                          required: true, message: 'Required field.',
                      }]
                  })(
                      <ColorSearchBox />
                  )
                }
              </FormItem>
            </Col>
          )
    }
    return children;
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const multiple_copies = getFieldValue('woe_data.multiple_copies');
      return (
        <div className={container} >
          <div className='form-group-title' >
            Work Order Details
          </div>
          <FormItem
            label='Quantity'
            labelCol={{ span: 24 }}
            style={{ margin: '0px' }}
          />
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
              <FormItem
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('woe_data.quantity.value1')(
                  <InputNumber style={{ width: '100%' }} disabled />
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
              <FormItem
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('woe_data.quantity.unit1')(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
              <FormItem
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('woe_data.quantity.value2')(
                  <InputNumber style={{ width: '100%' }} disabled />
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
              <FormItem
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('woe_data.quantity.unit2')(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
              <FormItem
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('woe_data.quantity.value3')(
                  <InputNumber style={{ width: '100%' }} disabled />
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
              <FormItem
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('woe_data.quantity.unit3')(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
          </Row>
          <Col span={24}>
            <FormItem
              wrapperCol={{ span: 24 }}
              style={{ margin: '10px' }}
            >
              {getFieldDecorator('woe_data.multiple_copies', {
                  valuePropName: 'checked',
              })(
                  <Checkbox onChange={this.multipleCopiesChanged} >Multiple Copies</Checkbox>
              )}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label='Number of Copies'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ margin: '10px' }}
            >
              {getFieldDecorator('woe_data.number_of_copies', {
                rules: [{
                  type: 'number', message: 'Requires a number.'
                }],
              })(
                <InputNumber disabled={!multiple_copies} min={1} />
              )}
            </FormItem>
          </Col>
          <Row gutter={8}>
            {
              this.renderCopies()
            }
          </Row>
          <Col span={24}>
            <FormItem
              label='Job Title'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              style={{ margin: '10px' }}
            >
              {getFieldDecorator('job_title', {
                  rules: [{
                      required: true, message: 'Required field.',
                  }],
              })(
                  <Input disabled />
              )}
            </FormItem>
          </Col>
          {
          // <Row>
          //   <Col span={12}>
          //     <FormItem
          //       label='Property Number'
          //       labelCol={{ span: 24 }}
          //       wrapperCol={{ span: 24 }}
          //       style={{ margin: '10px' }}
          //     >
          //       {getFieldDecorator('woe_data.property_number', {
          //           rules: [{
          //             required: false, message: 'Required field.',
          //           }],
          //       })(
          //         <InputNumber
          //           style={{ width: '100%' }} 
          //           min={0}
          //           onFocus={e => e.target.select()} 
          //         />
          //       )}
          //     </FormItem>
          //   </Col>
          //   <Col span={12}>
          //     <FormItem
          //       label='Unit Price'
          //       labelCol={{ span: 24 }}
          //       wrapperCol={{ span: 24 }}
          //       style={{ margin: '10px' }}
          //     >
          //       {getFieldDecorator('woe_data.unit_price', {
          //         rules: [{
          //             required: false, message: 'Required field.',
          //         }],
          //       })(
          //         <InputNumber
          //           style={{ width: '100%' }} 
          //           step={0.01} 
          //           min={0.00}
          //           onFocus={e => e.target.select()} 
          //         />
          //       )}
          //     </FormItem>
          //   </Col>
          // </Row>
          }
        </div>
      );
  }
}

export default Form.create()(StandardFormPage3);