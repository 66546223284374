import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Col, Row } from 'antd';
import { NavLink } from 'react-router-dom';
import Pending from './components/pending';
import Timeline from './components/timeline';
import Issued from './components/issued';
import Client from './components/client';
import Publication from './components/publication';

class MyComponent extends Component {
    render() {
        return (
            <div className="App">
                <div style={{ background: '#fff', padding: 24, minHeight: 500 }}>
                    <HashRouter>
                        <Switch>
                            <Route path="/report/pending" component={Pending} />
                            <Route path="/report/timeline" component={Timeline} />
                            <Route path="/report/publication" component={Publication} />
                            <Route path="/report/issued" component={Issued} />
                            <Route path="/report/client" component={Client} />
                            <Route path="/report" component={ReportMenu} />

                        </Switch>
                    </HashRouter>
                </div>
            </div>
        );
    }
}

class ReportMenu extends Component {
    render = () => {
        return <div>
            <Row><h1>&nbsp;</h1></Row>
            <Row gutter={16}>
                <Col span={8}>
                    <h3><NavLink to='/report/pending'>Pending Work Orders</NavLink></h3>
                    This is a status report of all work order from different divisions, which displays the Work Order Number, Requisitioner, Date Received, Ordered items, Delivered Items and Items for Delivery.
                </Col>
                <Col span={8}>
                    <h3><NavLink to='/report/timeline'>Work Order Timeline Report</NavLink></h3>
                    This is a timeline report of all the Work Order from different divisions. Mainly views the date and time that the division received/released the work order.
                </Col>
                <Col span={8}>
                    <h3><NavLink to='/report/publication'>Publication Reports</NavLink></h3>
                    This is a status report of all the Publications, Mainly views the status of the  Paid and Partial Paid Official Gazettes.
                </Col>
            </Row>
            <Row><h1>&nbsp;</h1></Row>
            <Row gutter={16}>
                <Col span={8}>
                    <h3><NavLink to='/report/issued'>Issued Work Orders</NavLink></h3>
                    This displays all the Work Order issued by the client that will be Mostly according to the date range of the report and Request Type.                
                </Col>
                <Col span={8}>
                    <h3><NavLink to='/report/client'>Client Work Order Report</NavLink></h3>
                    This is a status report of all the work order from different clients. Mainly displays the work order status of the client, job title, request date, and request type.
                </Col>
            </Row>            
        </div>

    }
}

export default MyComponent;