import API_CLIENT from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';


export const API_LOGIN_TYPE = 'API_LOGIN';
export const LOGOUT_TYPE = 'LOGOUT';

export const login = ({ code }) => {
    return (dispatch) => {
        dispatch({ type: `${API_LOGIN_TYPE}_REQUEST` });
        API_CLIENT.login({ code: code })
            .then((response) => dispatch({type: `${API_LOGIN_TYPE}_SUCCESS`, payload: response.data }))
            .catch((error) => dispatch({ type: `${API_LOGIN_TYPE}_FAILURE`, payload: getError(error) }));
    }
}

export const logout = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT_TYPE, payload: null });
    }
}

