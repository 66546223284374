import React, { Component } from 'react';
import { Tabs, Form } from 'antd';
import { css } from 'emotion';
import { get, isEqual } from 'lodash';
import WorkEnvelopeTable from './WorkEnvelopeTable';

const TabPane = Tabs.TabPane;

const wrapper = css({
	maxWidth: '100%',
  '& .ant-tabs': {
		margin: '15px', 
  },
  '& .ant-form-item-label': {
    textAlign: 'left',
    color: '#646970',
    fontSize: '15px',
    '& label': {
      color: '#646970',
      fontSize: '15px',
    }
  }
})

class BreakdownView extends Component {
	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(get(this, 'props.work_order.woe_data'), get(prevProps, 'work_order.woe_data'))) {
			this.loadData();
		}
	}

	loadData = () => {
		const { form: { setFieldsValue }, work_order } = this.props;
		if (!work_order) {
			return;
		} 
		setFieldsValue({
			...get(work_order, 'woe_data.work_item', {})
		});
	}
	
	getValidatedData = () => {
		const { form, readOnly, work_order } = this.props;
		const response = {};
		if (readOnly) {
			return response;
		}
		form.validateFieldsAndScroll((error, values) => {
			if (error) {
				response.error = error;
			} else {
				response.values = {
					woe_data: {
						...work_order.woe_data,
						work_item: values
					}
				}
			}
		});
		return response;
	}

  render() {
		const { work_order, readOnly } = this.props;
    if (!work_order) {
      return null;
		}
		const { form: { getFieldDecorator } } = this.props;
		return (
			<div className={wrapper} >
				<Tabs>
					<TabPane tab='Size' key='size' forceRender>
					{
						getFieldDecorator('size', {
							initialValue: { data: [], notes: '' },
							hidden: readOnly
						})(
							<WorkEnvelopeTable 
								readOnly={readOnly}
							/>
						)
					}
					</TabPane>
					<TabPane tab='Works' key='works' forceRender>
					{
						getFieldDecorator('works', {
							initialValue: { data: [], notes: '' },
							hidden: readOnly
						})(
							<WorkEnvelopeTable 
								readOnly={readOnly}
							/>
						)
					}
					</TabPane>
					<TabPane tab='Composing' key='composing' forceRender>
					{
						getFieldDecorator('composing', {
							initialValue: { data: [], notes: '' },
							hidden: readOnly
						})(
							<WorkEnvelopeTable 
								withNotes
								readOnly={readOnly}
							/>
						)
					}
					</TabPane>
					<TabPane tab='Photo-Litography' key='photo_litography' forceRender>
					{
						getFieldDecorator('photo_litography', {
							initialValue: { data: [], notes: '' },
							hidden: readOnly
						})(
							<WorkEnvelopeTable 
								withNotes
								readOnly={readOnly}
							/>
						)
					}
					</TabPane>
					<TabPane tab='Press' key='press' forceRender>
					{
						getFieldDecorator('press', {
							initialValue: { data: [], notes: '' },
							hidden: readOnly
						})(
							<WorkEnvelopeTable 
								withNotes
								readOnly={readOnly}
							/>
						)
					}
					</TabPane>
					<TabPane tab='Finishing' key='finishing' forceRender>
					{
						getFieldDecorator('finishing', {
							initialValue: { data: [], notes: '' },
							hidden: readOnly
						})(
							<WorkEnvelopeTable 
								withNotes
								readOnly={readOnly}
							/>
						)
					}
					</TabPane>
					<TabPane tab='Sales and Marketing' key='sales_marketing' forceRender>
					{
						getFieldDecorator('sales_marketing', {
							initialValue: { data: [], notes: '' },
							hidden: readOnly
						})(
							<WorkEnvelopeTable 
								withNotes
								readOnly={readOnly}
							/>
						)
					}
					</TabPane>
					<TabPane tab='Machine' key='machine' forceRender>
						{
							getFieldDecorator('machine', {
								initialValue: { data: [], notes: '' }
							})(
								<WorkEnvelopeTable 
									withNotes 
									readOnly={readOnly}	
								/>
							)
						}
					</TabPane>
					<TabPane tab='Cutters' key='cutters' forceRender>
						{
							getFieldDecorator('cutters', {
								initialValue: { data: [], notes: '' }
							})(
								<WorkEnvelopeTable 
									withNotes 
									readOnly={readOnly}	
								/>
							)
						}
					</TabPane>
				</Tabs>
			</div>
		);
  }
}

export default Form.create()(BreakdownView);

// return (
// 	<div className={wrapper} >
// 		<Tabs>
// 			<TabPane tab='Size' key='size' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.size', { data: [], notes: '' })}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Works' key='works' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.works', { data: [], notes: '' })}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Composing' key='composing' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.composing', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						estimates={get(work_order, 'estimates', [])}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'COMPOSING')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'COMPOSING' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Photo-Litography' key='photo_litography' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.photo_litography', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'PHOTOLITOGRAPHY')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'PHOTOLITOGRAPHY' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Press' key='press' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.press', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'PRESS')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'PRESS' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Finishing' key='finishing' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.finishing', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'FINISHING')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'FINISHING' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 			<TabPane tab='Sales and Marketing' key='sales_marketing' forceRender>
// 				<div>
// 					<WorkEnvelopeTable 
// 						withNotes
// 						readOnly
// 						value={get(work_order, 'woe_data.work_item.sales_marketing', { data: [], notes: '' })}
// 					/>
// 					<WorkOrderActualWork
// 						form={form}
// 						type={get(work_order, 'type', '')}
// 						progress={find(get(work_order, 'division_progress', []), 
// 							progress => progress.division.toUpperCase() === 'SALES')}
// 						readOnly={
// 							`${get(work_order, 'woe_current_division', '')}_DIVISION` !== user_division ||
// 							get(work_order, 'woe_current_division', '') !== 'SALES' || 
// 							get(work_order, 'woe_status', '') !== 'OPEN'
// 						}
// 					/>
// 				</div>
// 			</TabPane>
// 		</Tabs>
// 	</div>
// );