import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, keyBy, some, includes } from 'lodash';
import { getNotifications } from 'modules/notification/actionCreators';
import { notificationCount } from 'modules/notification/actionCreators';
import { getNotificationsCount } from 'modules/notification/actionCreators';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Modal, Layout, Menu, notification, Button, Icon, Badge, Dropdown, Spin, Divider } from 'antd';
import { NavLink } from 'react-router-dom';
import Partner from 'modules/partner';
import Estimate from 'modules/estimate';
import WorkOrder from 'modules/work_order';
import OrderOfPayment from 'modules/orderofpayment';
import ProductionCost from 'modules/productioncost';
import Report from 'modules/report';
import { css } from 'emotion';
import Clock from 'react-live-clock';
import IdleTimer from 'react-idle-timer';

import ReactTimeAgo from 'react-time-ago';
import JavascriptTimeAgo from 'javascript-time-ago';
// The desired locales.
import en from 'javascript-time-ago/locale/en';

// Initialize the desired locales.
JavascriptTimeAgo.locale(en);


const warning = Modal.warning;

const { Header, Sider } = Layout;
const routes_with_header = [ 
  '/estimate', 
  '/workorder', 
  '/orderofpayment', 
  '/productioncost', 
  '/report', 
  '/partner' 
];

notification.config({
  top: 50,
  right: 0,
  duration: 1.5
});

const MENU_ITEMS = [
  { title: "Estimates", value: "estimate" },
  { title: "Work Orders", value: "workorder" },
  { title: "Production Cost", value: "productioncost" },
  { title: "Order of Payment", value: "orderofpayment" },
  { title: "Partner Management", value: "partner" },
  { title: "Reports", value: "report" },
];

const appLayout = css({
  height: '100%',
  width: '100%',
  '& .sider':{
    background: 'linear-gradient(#2c83cb, #57aff4)',
    '& .sider-logo': {
      margin: '20px 0px', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      '& .company-name': {
        fontWeight: '600', 
        color: '#ebfefe', 
        fontSize: '16px', 
        margin: '3px 0px'
      },
      '& .division-name': {
        color: '#ebfefe', 
        fontSize: '14px', 
        textAlign: 'center', 
        margin: '0px 10px'
      }
    }
  },
  '& .content': {
    background: '#FFFFFF',
    height: '100%',
    width: '100%'
  }
});

const sideBarMenuStyle = css({
  backgroundColor: "transparent",
  borderRight: '0px',
  "& .ant-menu-item > a": {
    color: '#f0fefe',
  },
  "& .ant-menu-item:after": {
    borderRight: "6px solid #fbd233",
    borderLeft: "0px",
    right: 0,
  },
  "#sidemenu & .ant-menu-item-selected": {
    backgroundColor: "#2e85cc",
    color: '#f0fefe',
    fontWeight: 'bold'
  }
});

class MainLayout extends Component {
  componentDidMount() {
    if (this.props.loggedInUser == null) {
      this.props.history.push("/login");
    } else {
      const token = sessionStorage.getItem("token");
      if (!token) {
        sessionStorage.setItem("token", this.props.loggedInUser.token);
      }
      this.props.getNotifications();
    }
  }

  componentDidUpdate() {
    if (this.props.loggedInUser == null) {
      this.props.history.push("/login");
    }
    const lastNotificationRetrieved = get(
      this.props,
      "notifications.meta.dateTimeRetrieved"
    );
    const notificationsLoading = get(this.props, "notifications.loading");
    if (
      !notificationsLoading &&
      (!lastNotificationRetrieved ||
        moment().diff(lastNotificationRetrieved, "minute") > 15)
    ) {
      this.props.getNotifications();
    }
  }

  topMenuItems = (
    <Menu>
      {
        //<Menu.Item key="1"><NavLink to='/account'>My Account</NavLink></Menu.Item>
      }
      <Menu.Item key="2">
        <NavLink to="/logout">Logout</NavLink>
      </Menu.Item>
    </Menu>
  );

  getMenu = () => {
    const currentKey = this.props.location.pathname.replace("/", "");
    return (
      <Menu
        className={sideBarMenuStyle}
        mode="inline"
        theme="light"
        selectedKeys={[currentKey]}
      >
        <Menu.Item key="estimate">
          <NavLink to="/estimate">Estimates</NavLink>
        </Menu.Item>
        <Menu.Item key="workorder">
          <NavLink to="/workorder">Work Order</NavLink>
        </Menu.Item>
        <Menu.Item key="productioncost">
          <NavLink to="/productioncost">Production Cost</NavLink>
        </Menu.Item>
        <Menu.Item key="orderofpayment">
          <NavLink to="/orderofpayment">Order of Payment</NavLink>
        </Menu.Item>
        <Menu.Item key="partner">
          <NavLink to="/partner">Partner Management</NavLink>
        </Menu.Item>
        <Menu.Item key="report">
          <NavLink to="/report">Reports</NavLink>
        </Menu.Item>
      </Menu>
    );
  };

  onNotificationClick = (item, key, keyPath) => {
    const type = get(item, "item.props.data.type", "");
    const id = get(item, "item.props.data.object_id", -1);
   
    switch (type) {
      case "estimate":
        this.props.history.push(`/estimate/${id}`);
        break;
      case "work_order":
        this.props.history.push(`/workorder/${id}`);
        break;
      case "production_cost":
        this.props.history.push(`/productioncost/${id}`);
        break;
      case "order_of_payment":
        this.props.history.push(`/orderofpayment/${id}`);
        break;
      default:
        break;
    }
  };

  getNotifications = () => {
    const { notifications } = this.props;
    const { data, loading } = notifications;
    if (loading) {
      return (
        <Menu>
          <Menu.Item disabled>
            <div>Notifications</div>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <Spin />
          </Menu.Item>
        </Menu>
      );
    }

    if (data.length <= 0) {
      return (
        <Menu onClick={this.onNotificationClick}>
          <Menu.Item disabled>
            <div>Notifications</div>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <div>No New Notifications</div>
          </Menu.Item>
        </Menu>
      );
    } 
    // else if (data.length > 10) {
    //   return (
    //     <Menu onClick={this.onNotificationClick}>
    //       <Menu.Item disabled>
    //         <div>Notifications</div>
    //       </Menu.Item>
    //       <Menu.Divider />
    //       {data.slice(0, 9).map((notification, index) => {
    //         return (
    //           <Menu.Item key={index.toString()} data={notification} style={{ whiteSpace: 'inherit' }}>
    //             <div>{notification.description}</div>
    //             <div>
    //               <ReactTimeAgo date={notification.created_at} locale="en"/>
    //             </div>
    //           </Menu.Item>
    //         );
    //       })}
    //     </Menu>
    //   );
    // }
    return (
      <Menu onClick={this.onNotificationClick}>
        <Menu.Item disabled>
          <div>Notifications</div>
        </Menu.Item>
        <Menu.Divider />
        {data.map((notification, index) => {
          return (
            <Menu.Item key={index.toString()} data={notification} style={{ whiteSpace: 'inherit' }}>
              <div>{notification.description}</div>
              <div>
                <ReactTimeAgo date={notification.created_at} locale="en"/>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  logout = () => {
    this.props.history.push({
        pathname: '/logout'
    })
  }

  onIdle = () => {
    const logout = this.logout
    warning({
        title: 'Warning',
        content: 'You have been logged out. Status: Idle',
        onOk() {
            logout();
        }
    });
  }

  render() {
    if(this.props.loggedInUser == null) {
      return <div>Logged out.</div>
    }
    const username = get(this.props, 'loggedInUser.user.name', 'User');
    const topMenu = (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ fontSize: '36px', color: '#2B3D44', fontWeight: '500' }}>
            <HashRouter>
              <Switch>
                <Route path="/:path" render={(props) => {return <span>{keyBy(MENU_ITEMS, 'value')[props.match.params.path].title}</span> }} />
                <Route path="/" render={(props) => {return <span>Estimates</span>}} />
              </Switch>
            </HashRouter>
          </div>
          <div>
            <Clock 
              format={'h:mm:ss a'}
              ticking={true} 
              timezone={'Asia/Manila'}
              style={{
                paddingRight: '13px',
                fontWeight: '500'
              }}
            />
            <Dropdown                            
              overlay={ this.getNotifications() } 
              trigger={ [ 'click' ] }
              overlayStyle={{ maxWidth: '400px' }}
            >
              <Badge count={this.props.notifications.count} style={ { fontSize: '10px', padding: '0', zIndex: '1000' } }>
                <button  onClick={this.props.getNotificationsCount} style={ { border: 'none' } }>
                  <Icon type="bell" theme='filled' style={ { fontSize: '20px' } } />
                </button>
              </Badge>
            </Dropdown>
            <Dropdown overlay={this.topMenuItems} trigger={['click']}>
              <Button style={{ border: '0px', boxShadow: 'none', fontWeight: 'bold' }}>
                { username } <Icon type='down' />
              </Button>
            </Dropdown>
          </div>
        </div>
        <Divider />
      </div>
    );
  
    return (
      <IdleTimer
        timeout={900000}
        ref={ref => { this.idleTimer = ref }}
        onIdle={this.onIdle}
        // onIdle={() => window.location = "/#/logout"}
      >
        {
          this.props.isLoading && <div id="cover-spin"></div>
        }
        {
        <Layout className={appLayout}>
          <Sider className='sider' width='300'>
            <div className='sider-logo'>
                <img alt="" height="60px" width="60px" src="/favicon.png" />
                <span className='company-name'>National Printing Office</span>
                <span className='division-name'>Production, Planning, and Control</span>
            </div>
            <div id='sidemenu' >
                { this.getMenu() }
            </div>
          </Sider>
          <Layout className='content'>
            {
              includes(routes_with_header, this.props.location.pathname) &&
              <Header style={{ backgroundColor: '#FFFFFF', margin: '15px 0px' }}>
                {topMenu}
              </Header> 
            }
            <HashRouter>
              <Switch>
                <Route path="/partner" component={Partner} />
                <Route path="/estimate" component={Estimate} />
                <Route path="/workorder" component={WorkOrder} />
                <Route path="/orderofpayment" component={OrderOfPayment} />
                <Route path="/productioncost" component={ProductionCost} />
                <Route path="/report" component={Report} />
                <Route exact path="/" render={() => <Redirect to="/estimate"/>} />
              </Switch>
            </HashRouter>
          </Layout>
        </Layout>
        }
      </IdleTimer>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.loggedInUser,
    isLoading: some(state.loadingReducer, function(item) {
      return item === true;
    }),
    notifications: state.notification,
  
  };
  
};

const mapDispatchToProps = {
  getNotifications,
  notificationCount,
  getNotificationsCount
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout);
