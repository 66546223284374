import { combineReducers } from 'redux';

import loading from './loadingReducer';
import data from './dataReducer';
import meta from './metaReducer';
import error from './errorReducer';
import work_order from './woReducer';

export default combineReducers({
  loading, data, error, meta, work_order
});