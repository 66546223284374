import { get, find, includes } from 'lodash';

export function canEdit(loggedInUser, work_order) {
  const hasPermission = includes(get(loggedInUser, 'user.permissions.ppcd'), 'ppcd_edit_work_order');
  // const isDraft = get(props, 'work_order.woe_status') === 'DRAFT';
  const canEdit = includes(['Writer Section', 'Monitoring Section'], get(work_order, 'woe_current_step'));
  return hasPermission && canEdit;// && isDraft;
}

export function canDownload(work_order) {
  return get(work_order, 'woe_status') !== 'DRAFT';
}

export function canReceive(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  const isNotReceivedYet = !get(work_order, 'release_status.received_date');
  return hasPermission && isNotReceivedYet;
}

export function canRelease(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  const isReceived = !!get(work_order, 'release_status.received_date');
  return hasPermission && isReceived;
}

export function canPartialRelease(work_order) {
  return includes(['Final Printing', 'Binding', 'Releasing'], get(work_order, 'woe_current_step'));
}

export function canReturnToPPCD(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  const isReceived = !!get(work_order, 'release_status.received_date');
  const currentProcess = find(get(work_order, 'process', []), ['step', get(work_order, 'woe_current_step')]) || {};
  return hasPermission && isReceived && currentProcess.can_return_to_ppcd;
}

export function canReleaseToOthers(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  const isReceived = !!get(work_order, 'release_status.received_date');
  const canReleaseToOthers = get(work_order, 'woe_current_step', '') === 'Monitoring Section';
  return hasPermission && isReceived && canReleaseToOthers;
}

export function shouldReloadTabs(props, prevProps) {
  if (!prevProps) {
    return false;
  }
  return get(props, 'loggedInUser.user.id') !== get(prevProps, 'loggedInUser.user.id') ||
    get(props, 'work_order.woe_current_step') !== get(prevProps, 'work_order.woe_current_step');
}

export function userCanEdit(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section')
    && get(loggedInUser, 'user.division') === 'PPCD_DIVISION';
  const isWaiting = get(work_order, 'woe_status') === 'OPEN';
  return hasPermission && isWaiting;
}

export function canEditBreakdown(loggedInUser, work_order) {
  const canEdit = includes(['Writer Section', 'Monitoring Section'], get(work_order, 'woe_current_step'));
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  return canEdit && hasPermission; 
}

export function cutStockAction(loggedInUser, work_order) {
  const canEdit = includes(['Writer Section', 'Monitoring Section'], get(work_order, 'woe_current_step'));
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  return canEdit && hasPermission; 
}

export function showActualWork(work_order) {
  // const currentProcess = find(get(work_order, 'process', []), ['step', get(work_order, 'woe_current_step')]) || {};
  // return currentProcess.has_actual_work;
  return !includes(['Writer Section', 'Monitoring Section'], get(work_order, 'woe_current_step'));
}

export function composingAttachmentsAction(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  const activeStep = get(work_order, 'woe_current_step') === 'Typeset/Layout';
  if (hasPermission && activeStep) {
    return 'edit';
  } else if (activeStep) {
    return 'view';
  } else {
    return false;
  }
}

export function composingEstimateAction(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  const activeStep = get(work_order, 'woe_current_step') === 'Typeset/Layout' &&
    get(work_order, 'type') === 'Publication';
  return hasPermission && activeStep;
}

export function canEditActualWork(loggedInUser, work_order, tab) {
  const canUserEditActualWorkDone = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  const activeTabEditable = get(work_order, 'woe_current_division') === tab;
  return canUserEditActualWorkDone && activeTabEditable;
}

export function materialsAndSuppliesAction(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  if (get(work_order, 'woe_current_step') === 'Final Printing') {
    return {
      editable: hasPermission,
      action: 'press_data',
      protected_data: 'finishing_data'
    };
  } else if (get(work_order, 'woe_current_step') === 'Binding') {
    return {
      editable: hasPermission,
      action: 'finishing_data',
      protected_data: 'press_data'
    };
  } else {
    return false;
  }
  // const editTab = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
  //   && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  // if (showTab && editTab) {
  //   return 'edit';
  // } else if (showTab) {
  //   return 'view';
  // } else {
  //   return undefined;
  // }
}

export function deliveriesAction(loggedInUser, work_order) {
  const hasPermission = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
    && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  if (get(work_order, 'woe_current_division') === 'SALES_DIVISION') {
    return {
      editable: hasPermission,
      action: 'sales_data',
      protected_data: 'finishing_data'
    };
  } else if (get(work_order, 'woe_current_division') === 'FINISHING_DIVISION') {
    return {
      editable: hasPermission,
      action: 'finishing_data',
      protected_data: 'sales_data'
    };
  } else {
    return false;
  }
  // const editTab = get(loggedInUser, 'user.division') === get(work_order, 'woe_current_division') 
  //   && get(loggedInUser, 'user.section') === get(work_order, 'woe_current_section');
  // if (showTab && editTab) {
  //   return 'edit';
  // } else if (showTab) {
  //   return 'view';
  // } else {
  //   return undefined;
  // }
}
