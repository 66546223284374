import React, { Component } from 'react';
import { Button, DatePicker, Form, Col, Row, notification, Select } from 'antd';
import { axios } from 'shared/utils/api_client';
import { create_timeline_from_progress } from '../timeline/index';
import { getAuthHeader } from 'shared/utils/api_client';

import PendingTable from './table';
import { columns } from './table';
import PendingPdf from './pdf';
import { NavLink } from 'react-router-dom';
import { division_options, estimate_types } from '../../data';


export default class MyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: []
        }
    }

    loadData = (params, is_download) => {
        this.setState({loading: true})
        axios.get('/work_orders_progress', {
            params: params,
            headers: { ...getAuthHeader() }
        })
            .then((response) => {
                var result = response.data.data;
                var data = create_timeline_from_progress(result);
                // filter items which were already release (meanin they are no longer pending)
                data = data.filter((item) => {
                    return item.released_date === null;
                })
                if (data.length === 0) {
                    notification['error']({ message: 'No match found' });
                }
                if (is_download) {
                    console.log('is_download');
                    PendingPdf({ ...params, title: 'Pending Work Orders'}, data, columns);
                    this.setState({loading: false});
                }
                else {
                    this.setState({data: data, loading: false});
                }
            })
            .catch((error) => {
                this.setState({loading: false})
            })
    }

    componentDidMount() {

    }

    handleClick = () => {
        this.doIt(false);
    }

    download = () => {
        this.doIt(true);
    }

    doIt = (is_download) => {
        this.formRef.props.form.validateFields((err, values) => {
            if (!err) {
                this.loadData({...values, 
                    sort_key: 'action_date',
                    type: [values.type], 
                    woe_current_division: [values.woe_current_division],
                    division: [values.woe_current_division],
                    start_date: values.start_date.format('YYYY-MM-DD'),
                    end_date: values.end_date.format('YYYY-MM-DD')
                }, is_download);
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <Row><h1>Pending Work Orders</h1></Row>
                <Row>
                    <Col span={12}>
                        <WrappedForm
                            wrappedComponentRef={(formRef) => { this.formRef = formRef; }} />
                    </Col>
                    <Col span={12}>
                        <p style={{textAlign: 'right'}}>
                        <Button ><NavLink to="/report">Back to reports</NavLink> </Button> &nbsp;
                        <Button type="primary" onClick={this.handleClick}>Generate</Button> &nbsp;
                        <Button type="primary" onClick={this.download}>Download</Button>
                        </p>
                    </Col>
                </Row>
                <Row style={{borderBottom: '1px solid #ccc'}}>
                    
                </Row>
                {
                    this.state.data.length > 0 &&
                    <PendingTable
                        data={this.state.data}
                        loading={this.state.loading}
                    />
                }
                
            </div>
        );
    }
}

const WrappedForm = Form.create()(
    class extends Component {
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;

            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('start_date', {
                                        rules: [{ required: true, message: 'Start Date' }]
                                    })(
                                        <DatePicker placeholder="Start Date"/>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('end_date', {
                                        rules: [{ required: true, message: 'End Date' }]
                                    })(
                                        <DatePicker placeholder="End Date"/>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('woe_current_division', {
                                        rules: [{ required: true, message: 'Division' }]
                                    })(
                                        <Select placeholder="Select Division">
                                        {
                                            division_options.map((item) => {
                                                return <Select.Option key={item}>{item}</Select.Option>
                                            })
                                        }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('type', {
                                        rules: [{ required: true, message: 'Type' }]
                                    })(
                                        <Select placeholder="Select Type">
                                        {
                                            estimate_types.map((item) => {
                                                return <Select.Option key={item}>{item}</Select.Option>
                                            })
                                        }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>

                    </Row>

                </Form>
            );
        }
    }
);