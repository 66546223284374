import React, {Component} from "react";
import "./App.css";
import { connect } from 'react-redux'
import { HashRouter, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import MainContainer from 'layouts/main';
import LandingContainer from 'layouts/landing';

const PrivateRoute = withRouter(props => {
  const { component: Component, authed, ...rest } = props;
  return (
      <Route
          {...rest}
          render={(props) => 
              { 
                  return authed
                  ? <Component {...props} />
                  : <Redirect to={{ 
                          pathname: '/login', 
                          state: { from: props.location }
                      }} 
                  />
              } 
          } 
      />
  )
});

class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Switch>
            <Route path="/logout" component={LandingContainer} />
            <Route path="/login" component={LandingContainer} />
            <PrivateRoute authed={this.props.isUserLoggedIn} path='/' component={ MainContainer } />
            {
              // <Route path="/" component={MainContainer} />
            }
          </Switch>
        </HashRouter>
      </div>

    );
  }
}

const mapStateToProps = ({ loggedInUser }) => {
  return {
      isUserLoggedIn: !!loggedInUser
  }
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
