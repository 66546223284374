import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { css } from 'emotion';
import CostEstimateDrawer from './CostEstimateDrawer';

const wrapper = css({
	width: '100%',
})

export default class WorkOrderEstimateList extends Component {
	static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {}),
      };
    }
    return null;
	}
	
	constructor (props) {
		super(props);
		this.state = { 
			value: props.value,
			ceDrawerVisible: false,
		}
	}

	columns = [
		{
			title: 'Estimate Number',
			dataIndex: 'estimate_number'
		},
		{
			title: 'Client',
			dataIndex: 'partner_name',
		},
		{
			title: 'Job Title',
			dataIndex: 'job_title'
		}
	]
	
	onChange = (value) => {
		this.setState({ value, ceDrawerVisible: false });
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

  render() {
		const { value } = this.state;
		const { readOnly } = this.props;
    return (
      <div className={wrapper} >
				<Table
					columns={this.columns}
					dataSource={value}
					rowKey='id'
					size='small'
					style={{ marginTop: '10px' }}
				/>
				{
					!readOnly &&
					<Button onClick={() => this.setState({ ceDrawerVisible: true })}>
            Select Estimates
          </Button>
				}
				<CostEstimateDrawer
          visible={this.state.ceDrawerVisible}
          onDrawerCloseClicked={() => this.setState({ ceDrawerVisible: false })}
          onSave={this.onChange}
        />
			</div>
    );
  }
}