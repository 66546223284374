export const INITIAL_PAGINATION = { page: 1, take: 20 };
export const NO_PAGINATION = { page: 1, take: 10000 };

export const UNIT_SELECTION = [
  'Boxes',
  'Sets',
  'Pads',
  'Books',
  'Booklets',
  'Sheets',
  'Copies',
];

export const COLOR_SELECTION = [
  'WHITE',
  'PINK',
  'GREEN',
  'BLUE',
  'YELLOW'
];
