import { TYPES } from '../actionCreators';
import _ from 'lodash';

export default (state = [], action) => {
	switch(action.type) {
		case TYPES.GET_LIST:
			return action.payload.data; //_.uniqBy([].concat(...data, ...state), 'id');
		case TYPES.GET:
			return _.uniqBy([].concat(action.payload, ...state), 'id');
		case TYPES.UPDATE:
		case TYPES.CREATE:
			const { order_of_payment } = action.payload;
			return _.uniqBy([].concat(order_of_payment, ...state), 'id');
		default:
			return state;
	}
}