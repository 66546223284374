import React, { Component } from 'react';
import { Tabs, Progress, Button, Steps } from 'antd'; 
import { get, mergeWith, isArray } from 'lodash';
import { css } from 'emotion';
import FormPage1 from './PublicationFormPage1';
import FormPage2 from './PublicationFormPage2';
import FormPage3 from './PublicationFormPage3';

const TabPane = Tabs.TabPane;
const Step = Steps.Step;

function mergeCustomizer (objValue, srcValue, key, object, source, stack) {
  if (isArray(objValue)) {
    return srcValue;
  }
}

const parentContainer = css({
  flex: '1 1 100%',
  display: 'flex',
  overflowY: 'auto', 
  '& .tab-container': {
    flex: 1,
    display: 'flex', 
    flexDirection: 'row',
    margin: '10px',
    overflowX: 'auto',
    '& .navigation': {
      flex: '2', 
      boxShadow: '1px 1px 5px #999999AA', 
      margin: '5px', 
      padding: '30px 10px',
      background: '#FFFFFF'
    },
    '& .form-parent': {
      flex: '8', 
      alignSelf: 'flex-start',
      boxShadow: '1px 1px 5px #999999AA', 
      margin: '5px',
      background: '#FFFFFF',
      overflow: 'hidden',
      maxWidth: '831px',
      '@media (min-width: 1600px)': {
        maxWidth: '900px', 
      }, 
      '@media (min-width: 1800px)': {
        maxWidth: '1000px', 
      }, 
    }
  }
});

const footer = css({
  background: '#FFFFFF',
  padding: '15px',
  '& .button-container': {
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center',
    '& > button': {
      margin: '5px 10px'
    }
  }
});

export default class PublicationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 1,
      values: props.work_order || undefined,
    }
    this.componentRef = {};
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.work_order && this.props.work_order) {
      this.setState({ values: this.props.work_order });
    }
  }

  saveNewValues = (values) => {
    let stateVal = get(this.state, 'values', {});
    
    if (values) {
      mergeWith(stateVal, values, mergeCustomizer);
    }
    return stateVal;
  }

  moveToNext = (values) => {
    let currentPage = this.state.activeKey;
    if (currentPage < 3) {
      currentPage++;
    }
    const newValues = this.saveNewValues(values);
    this.setState({ activeKey: currentPage, values: newValues });
  }

  moveToPrevious = () => {
    let currentPage = this.state.activeKey;
    if (currentPage <= 1) {
      return false;
    }
    currentPage--;
    this.setState({ activeKey: currentPage });
    return true;
  }

  onContinuePressed = (as_draft) => {
    const activeComponent = get(this.componentRef, this.state.activeKey, undefined);
    if (activeComponent && activeComponent.onContinuePressed) {
      activeComponent.onContinuePressed(typeof as_draft === 'boolean' ? as_draft : false);
    }
  }

  saveComponentRef = (component, id) => {
    this.componentRef[id] = component;
  }

  render() {
    const { values } = this.state;
    const { onFormSave } = this.props;
    const { activeKey } = this.state;
    return (
      <React.Fragment>
        <div className={parentContainer}>
          <div className='tab-container'>
            <div className='navigation'>
              <Steps direction='vertical' current={activeKey - 1}>
                <Step title={`Publication Work Order Details`} />
                <Step title={`Descriptions and Instructions`} />
                <Step title={`Cut Stock`} />
              </Steps>
            </div>
            <div className='form-parent'>
              <Tabs 
                className='tabs' 
                renderTabBar={()=><div></div>} 
                activeKey={this.state.activeKey.toString()}
              >
                <TabPane key="1">
                  <FormPage1 
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 1)}
                    moveToNext={this.moveToNext}
                    onFormSave={onFormSave}
                    values={values}
                  />
                </TabPane>
                <TabPane key="2">
                  <FormPage2 
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 2)}
                    moveToNext={this.moveToNext}
                    onFormSave={onFormSave}
                    values={values}
                  />
                </TabPane>
                <TabPane key="3">
                  <FormPage3 
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 3)}
                    onFormSave={onFormSave}
                    values={values}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <Progress
          showInfo={false} 
          percent={(activeKey/3*100)} 
        />
        <div className={footer}>
          <div className='button-container'>
            <Button onClick={this.moveToPrevious}>
              Back
            </Button>
            {
              activeKey !== 1 &&
              <Button onClick={() => this.onContinuePressed(true)}>
                Save as Draft
              </Button>
            }
            <Button type='primary' onClick={this.onContinuePressed}>
              { 
                this.state.activeKey === 3 ? 'Save' : 'Continue'
              }
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}