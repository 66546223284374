import { TYPES } from '../actionCreators';

export default (state = null, action) => {
	switch(action.type) {
		case TYPES.GET:
			return action.payload;
		case TYPES.UPDATE:
		case TYPES.CREATE:
			const { production_cost } = action.payload;
			return production_cost
		default:
			return state;
	}
}