import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Spin, notification } from 'antd';
import { get } from 'lodash';
import { css } from 'emotion';
import Header from 'shared/components/Header';
import { createEstimate, updateEstimate, getEstimate } from '../actionCreators';
import { getModeFromProps, getType, getEstimateIdFromUrl } from '../selectors';
import PublicationForm from '../components/PublicationForm.1';
import StandardForm from '../components/StandardForm.1';

const parentStyle = css({
	height: '100%',
	width: '100%',
	background: '#F6FCFF',
	'& .loading': {
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center', 
		position: 'absolute', 
		top: '0', 
		bottom: '0', 
		left: '0', 
		right: '0', 
		backgroundColor: '#26272955' 
	},
	'& .container': {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	}
});

function disabledAdd (props) {
	const permissions = get(props, 'loggedInUser.user.permissions.ppcd');
	if (!Array.isArray(permissions)) {
			return true;
	}
	return !permissions.includes('ppcd_add_estimate');
}

class CreateUpdate extends Component {
	componentDidMount () {
		if (disabledAdd(this.props)) {
			notification.warning({
				message: 'Uh Oh!',
				description: 'You do not have permission to view this module.',
			});
			this.props.history.replace('/estimate');
			return;
		}
		if (this.loadData()) {
			return;
		}
		if (!this.props.type && this.props.mode === 'new') {
			this.props.history.replace('/estimate');
		}
	} 

	componentDidUpdate () {
		if (this.loadData()) {
			return;
		}
		if (!this.props.type && this.props.mode === 'new') {
			this.props.history.replace('/estimate');
		}
	}

	loadData = () => {
		const currentDataId = get(this.props, 'estimate.id', -1);
		const estimateId = get(this.props, 'estimateId', -1);
		if (this.props.mode !== 'new' && estimateId && currentDataId !== estimateId) {
			this.props.getEstimate(
				this.props.estimateId, 
				undefined, 
				this.errorCallback
			);
			return true;
		}
		return false;
	}

	onFormSave = (values) => {
		if (this.props.mode === 'edit' && values.id) {
			this.props.updateEstimate(values, this.successCallback, this.errorCallback);
			return;
		}
		const { type, mode } = this.props;
		if (mode === 'new') {
			values.type = type;
		}
		this.props.createEstimate(values, this.successCallback, this.errorCallback);
	}

	close = () => {
		this.props.history.replace('/estimate');
	}

	successCallback = (data) => {
		if (get(data, 'type') === 'Publication') {
			this.props.history.push({
				pathname: '/estimate', 
				state: { reload: true }
			});
			return;
		}
		this.props.history.push({
			pathname: `/estimate/${get(data, 'id', '')}`, 
			state: { reload: true }
		});
	}

	errorCallback = (error) => {
		notification.error({ message: error.message });
	}

	renderForm = () => {
		const currentDataId = get(this.props, 'estimate.id', -1);
		const estimateId = get(this.props, 'estimateId', -1);
		if (estimateId !== currentDataId) {
			return null;
		}
		const { type, estimate } = this.props;
		switch (type) {
			case 'Standard':
			case 'Accountable':
			case 'Specialized':
				return (
					<StandardForm 
						type={type}
						onFormSave={this.onFormSave}
						estimate={estimate} 
					/>
				);
			case 'Publication':
				return (
					<PublicationForm 
						onFormSave={this.onFormSave}
						estimate={estimate} 
					/>
				);
			default:
				return null;
		}
	}

	render() {
		const { loading, type } = this.props;
		return (
			<div className={parentStyle}>
				<div className='container'>
					<Header 
						title={`Cost Estimate ${type} Form`} 
						containerStyle={{ background: '#FFFFFF', padding: '15px 20px', boxShadow: '10px 0px 5px grey' }} 
						titleStyle={{ color: '#2B3D44', fontWeight: '500' }}
					>
						<Icon onClick={this.close} type='close' style={{ fontSize: '2em', color: '#797979' }} />
					</Header>
					{
						this.renderForm()
					}
				</div>
				{
					loading &&  
						<div className='loading'>
							<Spin size='large' />
						</div>
				}
			</div>
		);
	}
}

const mapStateToProps = ({ loggedInUser, estimate}, ownProps) => {
	const mode = getModeFromProps(ownProps);
	const estimateId = getEstimateIdFromUrl(ownProps);
	return {
		loading: estimate.loading,
		estimate: mode === 'edit' || mode === 'copy' ? estimate.estimate : undefined,
		loggedInUser,
		mode,
		estimateId,
		type: getType(estimateId, estimate.estimate, ownProps),
	};
}

const mapDispatchToProps = {
	createEstimate, updateEstimate, getEstimate
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);