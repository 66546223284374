import React, { Component } from 'react';
import { Divider, Form, InputNumber } from 'antd';
import { css } from 'emotion';
import { get, isEqual, round } from 'lodash';
import accounting from 'accounting';
import DivTotal from 'shared/components/DivTotal';

const FormItem = Form.Item;

const containerStyle = css({
    flex: '1 1 100%',
    display: 'flex',
    overflowY: 'auto', 
    flexDirection: 'column', 
    alignItems: 'center', 
    '& .title': {
        color: '#415A85', 
        fontSize: '1.5em',
        marginTop: '10px',
        marginBottom: '5px'
    },
    '& .subtitle': {
        color: '#787878', 
        fontSize: '1.3em', 
        marginBottom: '5px',
    },
    '& .item-container': {
        minWidth: '80%',
    },
    '& .item': {
        display: 'flex', 
        margin: '0px 10px',
        justifyContent: 'space-between',
        '& .label': {
            color: '#93AEA7', 
            fontSize: '1.2em',
        },
        '& .value': {
            color: '#787C82', 
            fontSize: '1.2em',
        },
        '& .amount-label': {
            color: '#161717', 
            fontWeight: 'bold', 
            fontSize: '1.2em'
        },
        '& .amount-value': {
            color: '#161717', 
            fontWeight: 'bold', 
            fontSize: '1.2em'
        },
        '@media(min-width: 600px)': {
            flexDirection: 'row', 
            minWidth: '70%',
        },
        '@media(min-width: 992px)': {
            flexDirection: 'row', 
            minWidth: '60%',
        },
    }
});

class StandardFormSummaryPage extends Component {
    state = {}

    componentDidMount() {
        this.loadData();
	}

	componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.values, this.state.values)) {
            this.loadData();
        }
	}

	loadData = () => {
        const { values, form } = this.props;
        if (!values) {
            return;
        }
        const { setFieldsValue, getFieldsValue } = form;
        const fieldsValue = getFieldsValue();
        const markup = values.markup || 0;
        if (markup === fieldsValue.markup 
            && values.total_amount === fieldsValue.total_amount) {
            return;
        }
        const { total_estimate } = values;
        const total_amount = (total_estimate * (markup / 100)) + total_estimate;
        setFieldsValue({
            markup,
            total_amount,
        });
        this.setState({ values: { ...values } })
        
    }

    onMarkupChange = (value) => {
        const { values, form: { setFieldsValue } } = this.props;
        const total_estimate = get(values, 'total_estimate', 0)
        setFieldsValue({ 
          total_amount: round((total_estimate * (value / 100)) + total_estimate, 2)
        });
    }
    
    onContinuePressed = () => {
        const { moveToNext, form, values, onFormSave } = this.props;
        form.validateFields((err, val) => {
            if (!err) {
                moveToNext({ ...values, ...val });
                onFormSave({ ...values, ...val });
            }
        });
    }

    render() {
        const { type, values, form } = this.props;
        return (
            <div className={containerStyle}>
                <div className='title'>
                    { type } Form Cost Estimate Summary
                </div>
                <div className='subtitle'>
                    { get(values, 'data.partner.partner_name') }
                </div>
                <div className='subtitle'>
                    { get(values, 'job_title') }
                </div>
                <div className='subtitle'>
                    { `${get(values, 'data.quantity.value3')} ${get(values, 'data.quantity.unit3')}` }
                </div>
                <div className='item-container'>
                    <Divider dashed />
                    <div className='item'>
                        <div className='label'>Composing:</div>
                        <div className='value'>{ accounting.formatMoney(get(values, 'data.division.composing.total', 0), '₱', 2, ',', '.') }</div>
                    </div> 
                    <div className='item'>
                        <div className='label'>Photo-litography:</div>
                        <div className='value'>{ accounting.formatMoney(get(values, 'data.division.photo_litography.total', 0), '₱', 2, ',', '.') }</div>
                    </div>
                    <div className='item'>
                        <div className='label'>Press:</div>
                        <div className='value'>{ accounting.formatMoney(get(values, 'data.division.press.total', 0), '₱', 2, ',', '.') }</div>
                    </div>
                    <div className='item'>
                        <div className='label'>Finishing:</div>
                        <div className='value'>{ accounting.formatMoney(get(values, 'data.division.finishing.total', 0), '₱', 2, ',', '.') }</div>
                    </div>
                    <Divider dashed />
                    <div className='item'>
                        <div className='label' style={{ flex: 2 }}>Indirect:</div>
                        <div className='value' style={{ flex: 1 }}>{ accounting.formatMoney(get(values, 'data.division.indirect.total', 0), '₱', 2, ',', '.') }</div>
                        <div style={{ flex: 1 }}></div>
                    </div>
                    <div className='item'>
                        <div className='label' style={{ flex: 2 }}>Stock (Direct Materials):</div>
                        <div className='value' style={{ flex: 1 }}>{ accounting.formatMoney(get(values, 'data.division.stock.total', 0), '₱', 2, ',', '.') }</div>
                        <div style={{ flex: 1 }}></div>
                    </div>
                    <div className='item'>
                        <div className='label' style={{ flex: 2 }}>Admin Cost (+30% of Materials):</div>
                        <div className='value' style={{ flex: 1 }}>{ accounting.formatMoney(get(values, 'data.admin_cost', 0), '₱', 2, ',', '.') }</div>
                        <div style={{ flex: 1 }}></div>
                    </div>
                    <Divider dashed />
                    <div className='item'>
                        <div className='label'>Total Estimated Cost:</div>
                        <div className='value'>{ accounting.formatMoney(get(values, 'total_estimate', 0), '₱', 2, ',', '.') }</div>
                    </div>
                    <div className='item'>
                        <div className='label'>Mark up (%):</div>
                        <div>
                            <FormItem>
                                {
                                    form.getFieldDecorator('markup', { 
                                        rules:[{ required: true, message: 'Required field.'}], 
                                        initialValue: 0 
                                    })(
                                        <InputNumber min={0} max={200} onChange={this.onMarkupChange} />
                                    )
                                }
                            </FormItem>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='label'>Total Amount (%):</div>
                        <div>
                            <FormItem style={{ padding: 0, margin: 0 }}>
                                {
                                    form.getFieldDecorator('total_amount', { 
                                        initialValue: 0 
                                    })(
                                        <DivTotal 
                                            hideTotal 
                                            style={{ margin: '0px' }}
                                            textStyle={{ fontSize: '1.3em' }}
                                        />
                                    )
                                }
                            </FormItem>
                        </div>
                    </div>
                    <Divider style={{ 'backgroundColor':'transparent' }}/>
                </div>
            </div>
        );
    }
}

export default Form.create()(StandardFormSummaryPage);