import { TYPES } from '../actionCreators';

export default (state = null, action) => {
	switch(action.type) {
		case TYPES.GET:
			return action.payload;
		case TYPES.UPDATE:
		case TYPES.CREATE:
			const { notification } = action.payload;
			return notification;
		default:
			return state;
	}
}