import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { get } from 'lodash';
import List from './containers/List';
import View from './containers/View';
import Create from './containers/Create.1';
import CreateQL from './containers/CreateQL';

class Estimate extends Component {
    render() {
			const location = Object.assign({}, get(this.props, 'history.location', {}));
			if (location.pathname.startsWith('/estimate/ql')) {
				location.pathname = '/estimate';
			}
			return (
				<React.Fragment>
					<Switch location={location}>
						<Route exact path="/estimate/new" component={Create} />
						<Route path="/estimate/new/:id" component={Create} />
						<Route path="/estimate/edit/:id" component={Create} />
						<Route path="/estimate/:id" component={View} />
						<Route path="/estimate" component={List} />
					</Switch>
					<Route path="/estimate/ql/:id" component={CreateQL} />
				</React.Fragment>
			);
    }
}

export default Estimate;