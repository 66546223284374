import React, { Component } from 'react';
import { Form } from 'antd';
import { css } from 'emotion';
import { } from 'lodash';
import FilesUpload from 'shared/components/FilesUpload';
import { API_FILE_UPLOAD } from 'shared/constants/urls';

const wrapper = css({
	margin: '25px',
	display: 'flex', 
	flexDirection: 'column',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
	'& .top-form-container': {
		display: 'flex',
	}
});

class ComposingAttachments extends Component {
  getValidatedData = () => {
		const { form } = this.props;
		const response = {};
		form.validateFieldsAndScroll((error, values) => {
			if (error) {
				response.error = error;
			} else {
				response.values = values;
			}
		});
		return response;
  }
  
  render() {
    const { readOnly, form: { getFieldDecorator } } = this.props;
    return (
      <div className={wrapper}>
        <div className='form-group-title'>
          Attachment/s
        </div>
        <div className='top-form-container'>
        {
          getFieldDecorator('composing_data.documents', {
            rules: [
              // { required: !readOnly, message: 'Required field.'},
              { type: 'array', message: 'Documents should be an array' },
            ], 
            initialValue: [],
            hidden: readOnly 
          })(
            <FilesUpload 
              upload_url={API_FILE_UPLOAD}
              disabled={readOnly}
            />
          )
        }
        </div>
      </div>
    );
  }
}

export default Form.create()(ComposingAttachments);