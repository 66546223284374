import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Icon, Spin, Button, notification } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import Header from 'shared/components/Header'
import CreateUpdateForm from './CreateForm';
import { createPC, updatePC, getPC } from '../actionCreators';
import { getModeFromProps, getPCIdFromUrl } from '../selectors';

const drawerStyle = css({
	'& .ant-drawer-wrapper-body': {
		height: '100%',
	},
	'& .ant-drawer-body': {
		height: '100%',
		background: '#F6FCFF',
		padding: 0
	},
	'& .drawer-content': {
		display: 'flex', 
		height: '100%', 
		flexDirection: 'column',
	},
});

function disabledAdd (props) {
	const permissions = get(props, 'loggedInUser.user.permissions.ppcd');
	if (!Array.isArray(permissions)) {
			return true;
	}
	return !permissions.includes('ppcd_add_production');
}

class CreateUpdate extends Component {
	componentDidMount () {
		if (disabledAdd(this.props)) {
			notification.warning({
				message: 'Uh Oh!',
				description: 'You do not have permission to view this module.',
			});
			this.props.history.replace('/productioncost');
			return;
		}
		if (this.loadData()) {
			return;
		}
	} 

	componentDidUpdate () {
		if (this.loadData()) {
			return;
		}
	}

	loadData = () => {
		const currentDataId = get(this.props, 'pc.id', -1).toString();
		const pcId = get(this.props, 'pcId', -1);
		if (this.props.mode !== 'new' && pcId && currentDataId !== pcId) {
			this.props.getPC(
				pcId, 
				undefined, 
				this.errorCallback
			);
			return true;
		}
		return false;
	}

	onFormSave = (values) => {
		if (this.props.mode === 'edit' && values.id) {
			this.props.updatePC(values, this.successCallback, this.errorCallback);
			return;
		}
		this.props.createPC(values, this.successCallback, this.errorCallback);
	}

	successCallback = (production_cost) => {
		let url = '/productioncost';
		if (production_cost) {
			url = `/productioncost/view/${get(production_cost, 'id', '')}`
		}
		this.props.history.push({
			pathname: url,
			state: { reload: true }
		});
	}

	errorCallback = (error) => {
		notification.error({ message: error.message });
	}

	close = () => {
		this.props.history.push('/productioncost');
	}

	saveComponentRef = (ref) => {
		this.formRef = ref;
	}

	save = (release) => {
		if (!this.formRef) {
			return;
		}
		this.formRef.save(release);
	}

	render() {
		const { loading, pc, mode } = this.props;
		return (
			<Drawer
				className={drawerStyle}
				placement="top"
				height={'100%'}
				closable={false}
				destroyOnClose={true}
				visible={true}
			>
				<div className='drawer-content'>
					<Header 
						title={`Production Cost`} 
						containerStyle={{ background: '#FFFFFF', padding: '15px 20px', boxShadow: '10px 0px 5px grey' }} 
						titleStyle={{ color: '#2B3D44', fontWeight: '500' }}>
						{
							mode !== 'view' &&
							<React.Fragment>
								<Button style={{ margin: '0px 10px' }} onClick={() => { this.save(false)}}>
									Save as Draft
								</Button>
								<Button style={{ margin: '0px 10px' }} type='primary' onClick={() => { this.save(true)}}>
									Save and Release
								</Button>
							</React.Fragment>
						}
						<Icon onClick={this.close} type='close' style={{ fontSize: '2em', color: '#797979' }} />
					</Header>
					<CreateUpdateForm 
						wrappedComponentRef={this.saveComponentRef}
						onFormSave={this.onFormSave}
						pc={pc}
						mode={mode}
					/>
				</div>
				{
					loading &&  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', backgroundColor: '#26272955', zIndex: 99999 }}><Spin size='large' /></div>
				}
			</Drawer>
		);
	}
}

const mapStateToProps = ({ loggedInUser, production_cost }, ownProps) => {
	const mode = getModeFromProps(ownProps);
	const pcId = getPCIdFromUrl(ownProps);
	return {
		loading: production_cost.loading,
		pc: mode !== 'new' ? production_cost.production_cost : undefined,
		loggedInUser,
		mode,
		pcId,
	};
}

const mapDispatchToProps = {
  createPC, updatePC, getPC
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);