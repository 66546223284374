import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { css } from 'emotion';
import { get, isEqual } from 'lodash';
import EditableTable from 'shared/components/EditableTable';
import { divisions } from 'modules/work_order/data';

const FormItem = Form.Item;

const wrapper = css({
	margin: '25px',
	display: 'flex', 
	flexDirection: 'column',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
	'& .top-form-container': {
		display: 'flex',
	}
})

class CutStockTable extends Component {
	columns = [
		{
			title: 'Rolls',
			dataIndex: 'rolls',
			inputType: 'number',
			width: '5%',
			editable: true,
			required: true,
			defaultValue: 0,
			disabled: form => {
				const reams = form.getFieldValue('reams');
				const sheets = form.getFieldValue('sheets');
        return !!reams || !!sheets;
      },
		}, {
			title: 'Reams',
			dataIndex: 'reams',
			inputType: 'number',
			width: '5%',
			editable: true,
			required: true,
			defaultValue: 0,
			disabled: form => {
        const rolls = form.getFieldValue('rolls') || 0;
        return !!rolls;
      },
		}, {
			title: 'Sheets',
			dataIndex: 'sheets',
			inputType: 'number',
			width: '5%',
			editable: true,
			required: true,
			defaultValue: 0,disabled: form => {
        const rolls = form.getFieldValue('rolls') || 0;
        return !!rolls;
      },
		}, {
			title: 'Kind',
			dataIndex: 'kind',
			width: '25%',
			editable: true,
			required: true,
		}, {
			title: 'Size',
			dataIndex: 'size',
			width: '15%',
			inputType: 'dimension',
			editable: true,
			required: true,
		}, {
			title: 'Weight',
			dataIndex: 'weight',
			width: '10%',
			editable: true,
			required: true,
		}, {
			title: 'Division',
			dataIndex: 'division',
			inputType: 'select',
			options: divisions,
			width: '15%',
			editable: true,
			required: true,
		}, {
			title: 'Over',
			dataIndex: 'over',
			width: '5%',
			valuePropName: 'checked',
			inputType: 'switch',
			editable: true,
			required: true,
			defaultValue: false,
			render: checked => !checked ? 'No' : 'Yes'
		},
	];

	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(get(this, 'props.work_order.cut_stock_data'), get(prevProps, 'work_order.cut_stock_data'))) {
			this.loadData();
		}
	}

	loadData = () => {
		const { form: { setFieldsValue }, work_order } = this.props;
		if (!work_order) {
			return;
		} 
		setFieldsValue({
			...get(work_order, 'cut_stock_data', {})
		});
	}

	getValidatedData = () => {
		const { form } = this.props;
		const response = {};
		form.validateFieldsAndScroll((error, values) => {
			if (error) {
				response.error = error;
			} else {
				response.values = {
					cut_stock_data: values
				};
			}
		});
		return response;
	}

  render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;
    return (
      <div className={wrapper}>
				<div className='form-group-title'>
					Cut Stock
				</div>
				<div className='top-form-container'>
					<FormItem
						label='Pieces from sheet'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`pieces_from_sheet`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
					<FormItem
						label='Cut Sheet Works'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`cut_sheet_works`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
					<FormItem
						label='Size'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`size`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
					<FormItem
						label='Overs'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`overs`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
					<FormItem
						label='Total Pieces'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`total_pieces`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
				</div>
				<FormItem>
					{getFieldDecorator('data', {
						initialValue: []
					})(
						<EditableTable
							columns={this.columns}
							onChange={this.onChange}
						/>
					)}
				</FormItem>
      </div>
    );
  }
}

export default Form.create()(CutStockTable);