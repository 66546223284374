import { axios } from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';
import {
  API_PARTNER, 
  API_PARTNERS
} from 'shared/constants/urls';
import { getAuthHeader } from 'shared/utils/api_client';

export const TYPES = {
	GET_LIST: 'GET_PARTNERS',
	GET: 'GET_PARTNER',
	CREATE: 'CREATE_PARTNER',
	UPDATE: 'UPDATE_PARTNER',
	REQUEST: 'REQUEST_PARTNER',
	ERROR: 'ERROR_PARTNER'
}

export const getPartners = (params) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.get(API_PARTNERS, {
			headers: { ...getAuthHeader() },
			params: { ...params }
		})
		.then(response => dispatch({ type: TYPES.GET_LIST, payload: response.data }))
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}

export const getPartner = (id) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.get(`${API_PARTNER}/${id}`, {
			headers: { ...getAuthHeader() }
		})
		.then(response => dispatch({ type: TYPES.GET, payload: response.data }))
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}

export const createPartner = (partner, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_PARTNER}`, partner, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
			dispatch({ type: TYPES.CREATE, payload: response.data }); 
			if (successCallback) { 
				successCallback(); 
			} 
		})
		.catch(error => { 
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error);
			}
		})
	}
}

export const updatePartner = (partner, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.put(`${API_PARTNER}/${partner.id}`, partner, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
			dispatch({ type: TYPES.UPDATE, payload: response.data }); 
			if (successCallback) { 
				successCallback(); 
			} 
		})
		.catch(error => { 
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
			if (errorCallback) {
				errorCallback(error);
			}
		})
	}
}

export const togglePartnerStatus = (id, action) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_PARTNER}/${id}/${action}`, {}, {
			headers: { ...getAuthHeader() }
		})
		.then(response => dispatch({ type: TYPES.UPDATE, payload: response.data }))
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}
