import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Radio, Menu, Icon, Dropdown, Input, Checkbox, Popover, notification } from 'antd';
import Header from 'shared/components/Header';
import * as data from '../data';
import { getEstimates } from '../actionCreators';
import moment from 'moment';
import  { get } from 'lodash';

const { Search } = Input;
const RadioGroup = Radio.Group;

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'standard',
            standardHideColumns: [],
            publicationHideColumns: []
        }
    }

    handleEstimateMenuClick = (event, estimate) => {
        event.domEvent.stopPropagation();
        switch(event.key) {
            case 'view_estimate':
                this.viewEstimateClicked(estimate);
                return;
            case 'view_ql':
                this.viewQLClicked(estimate);
                return;
            default:
                return;
        }
    }

    estimateMenu = (estimate) => {
        return (
            <Menu onClick={event => this.handleEstimateMenuClick(event, estimate)}>
                <Menu.Item key="view_estimate">View Cost Estimate</Menu.Item>
                <Menu.Item key="view_ql">View Quotation Letter</Menu.Item>
            </Menu>
        )
    };

    onSearch = (value) => {
        var type = this.props.meta.filters.type;
        this.props.getEstimates({
          type: type,
          q: value
        });
      }
    
	standardColumns = [
    {
			title: 'Estimate Number',
			dataIndex: 'estimate_number',
			key: 'estimate_number',
    }, {
			title: 'RFQ Number',
			dataIndex: 'rfq_number',
			key: "rfq_number"
    }, {
			title: 'RFQ Date',
			dataIndex: 'request_date',
			key: "request_date"
    }, {
			title: 'Quotation',
			dataIndex: 'ql_number',
			key: "ql_number",
			render: (data, record) => data || 'Draft'
    }, {
			title: 'Client',
			dataIndex: 'data.partner.partner_name',
			key: 'partner_name',
			sorter: true,

    }, {
			title: 'Job Title',
			dataIndex: 'job_title',
			key: 'job_title',
			width: 200,
    }, {
			title: 'Created At',
			dataIndex: 'created_at',
			key: 'created_at',
			render: text => !!text ? moment(text).format('MMMM D, YYYY - hh:mm:ss a') : '',
			sorter: true,
			defaultSortOrder: 'descend'
    }, {
			title:'',
			dataIndex: 'actions',
			key: 'actions',
			width: 40,
			render: (text, estimate) => {
				const menu = this.estimateMenu(estimate);
				return (
					<Dropdown overlay={menu} trigger={['click']}>
						<Button
							style={{ border: 'none', background: 'none', transform: 'rotate(90deg)'}} 
							icon="ellipsis"
							onClick={e => e.stopPropagation()}
						/>
					</Dropdown> 
				)
			},
    }
  ];
    
    publicationColumns = [{
        title: 'Estimate Number',
        dataIndex: 'estimate_number',
        key: 'estimate_number'
    }, {
        title: 'RFQ Number',
        dataIndex: 'rfq_number',
        key: 'rfq_number'
    }, {
        title: 'RFQ Date',
        dataIndex: 'request_date',
        key: 'request_date'
    }, {
        title: 'Publication Fee',
        dataIndex: 'total_amount',
        key: 'total_amount'
    }, {
        title: 'Client',
        dataIndex: 'data.partner.partner_name',
        key: 'partner_name'
    }, {
        title: 'Description',
        dataIndex: 'estimate_description',
        key: 'estimate_description'
    }, {
        title:'',
        dataIndex: 'actions',
        width: 40,
        key: 'actions',
        render: (text, record) => {
                return (
					<Button 
                        style={{border: 'none', background: 'none'}} 
                        icon="edit"
                        onClick={(e) => {
                            e.stopPropagation();
                            this.onEditEstimate(record);
                        }}
                        className='editDeleteButtons'
                    />
                )
            },
        }];

	componentDidMount() {
        if (this.disableView()) {
            notification.warning({
              message: 'Uh Oh!',
                      description: 'You do not have permission to view this module.',
            });
            return;
        }

        this.props.getEstimates({
            type: [this.state.type],
            page: 1,
            take: 10,
            sort_dir: 'descend',
            sort_key: 'created_at',
        });
    }

    componentDidUpdate() {
        const { state } = this.props.location;
        const { filters, ...rest } = this.props.meta;
        if (state && state.reload) {
            const stateCopy = { ...state };
            delete stateCopy.reload;
            this.props.history.replace({ state: stateCopy });
            if (this.disableView()) {
                notification.warning({
                  message: 'Uh Oh!',
                          description: 'You do not have permission to view this module.',
                });
                return;
            }
            this.props.getEstimates({
                ...filters,
                ...rest,
                type: [this.state.type]
            });
        }
    }

    handleMenuClick = ({ key }) => {
        this.props.history.push(`/estimate/new?type=${key}`)
    }

    onEditEstimate = (estimate) => {
        this.props.history.push(`/estimate/edit/${estimate.id}`)
    }
    
    menu = (
        <Menu onClick={this.handleMenuClick}>
            {
                data.estimate_types.map(datum => <Menu.Item key={datum}>{datum}</Menu.Item>)
            }
        </Menu>
    );

    viewEstimateClicked = (estimate) => {
        this.props.history.push(`/estimate/${estimate.id}`);
    }

    viewQLClicked = (estimate) => {
        this.props.history.push(`/estimate/ql/${estimate.id}`);
    }

    onEstimateTypeChanged = (e) => {
        if (this.disableView()) {
            notification.warning({
              message: 'Uh Oh!',
                      description: 'You do not have permission to view this module.',
            });
            return;
        }
        const type = e.target.value;
        if (this.state.type === type) {
            return;
        }
        this.setState({ type });
        this.props.getEstimates({
            page: 1,
            take: 10,
            sort_dir: 'descend',
            sort_key: 'created_at',
            type: [type]
        });
    }
   
    disabledAdd = () => {
        const permissions = get(this.props, 'loggedInUser.user.permissions.ppcd');
      
        if (!Array.isArray(permissions)) {
            return true;
        }
         return !permissions.includes('ppcd_add_estimate');
    }

    disableView = () => {
        const permissions = get(this.props, 'loggedInUser.user.permissions.ppcd');
        if (!Array.isArray(permissions)) {
            return true;
        }
        return !permissions.includes('ppcd_view_estimate');
    }
    
    filteredColumns = () => {
        const columns = this.state.type === 'publication' ? this.publicationColumns : this.standardColumns;
        const columnsToHide = this.state.type === 'publication' ? this.state.publicationHideColumns : this.state.standardHideColumns;
        return columns.filter(column => !columnsToHide.includes(column.key))
    }

    toggleColumn = (column) => {
        const columnsToHide = this.state.type === 'publication' ? this.state.publicationHideColumns : this.state.standardHideColumns;
        const columns = [...columnsToHide];
        const index = columns.indexOf(column);
        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
        if (this.state.type === 'publication') {
            this.setState({ publicationHideColumns: columns })
        } else {
            this.setState({ standardHideColumns: columns })
        }
        
    }

    columnsPopOver = () => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
                this.state.type !== 'publication'
                ? this.standardColumns.filter(column => column.key !== 'actions').map(column => {
                const checked = !this.state.standardHideColumns.includes(column.key);
                return (
                  <div key={column.key}>
                    <Checkbox
                      onChange={e => this.toggleColumn(column.key)}
                      checked={checked}
                    >
                      { column.title }
                    </Checkbox>
                  </div>
                );
              })
              : this.publicationColumns.filter(column => column.key !== 'actions').map(column => {
                const checked = !this.state.publicationHideColumns.includes(column.key);
                return (
                  <div key={column.key}>
                    <Checkbox
                      onChange={e => this.toggleColumn(column.key)}
                      checked={checked}
                    >
                      { column.title }
                    </Checkbox>
                  </div>
                );
              })
            }
          </div>
        );
    }

    onChangeTable = (pagination, filter, sorter) => {
        const { filters, ...rest } = this.props.meta;
        this.props.getEstimates({
            ...filters,
            ...rest,
            page: pagination.current,
            take: pagination.pageSize,
            sort_dir: sorter.order,
            sort_key: sorter.columnKey,
            type: [this.state.type]
        });
    } 

	render() {
        const filteredColumns = this.filteredColumns();
		return (
			<div style={{ margin: '20px 40px' }} >
				<Header 
					loading={this.props.loading}
					titleComponent={
						<Search 
							placeholder='Search estimates'
							onSearch={value => this.onSearch(value)}
							style={{ width: 400 }}  disabled ={this.disableView()}
						/> 
					}		
				>    
					<Popover
						title='Configure Columns'
						content={this.columnsPopOver()}
						trigger='click'>
							<Button
							shape='circle'
							size='large'
							icon='setting'
							/>
					</Popover>
					<Dropdown overlay={this.menu} trigger={['click']}  disabled = {this.disabledAdd()} >
							<Button type='primary' >
									<Icon type="plus" />New Estimate
							</Button>
					</Dropdown> 
				</Header>
				<div>
					<RadioGroup defaultValue="standard" buttonStyle="solid" onChange={this.onEstimateTypeChanged} style={{}}>
						<Radio.Button style={{ margin: '5px', borderRadius: '50px', border: '0px solid white' }} value="standard">Standard</Radio.Button>
						<Radio.Button style={{ margin: '5px', borderRadius: '50px',  border: '0px solid white' }} value="accountable">Accountable</Radio.Button>
						<Radio.Button style={{ margin: '5px', borderRadius: '50px',border: '0px solid white' }} value="specialized">Specialized</Radio.Button>
						<Radio.Button style={{ margin: '5px', borderRadius: '50px',border: '0px solid white' }} value="publication">Publication</Radio.Button>
					</RadioGroup>
				</div>
				<Table
					columns = {filteredColumns}
					dataSource={this.props.data}
					loading={this.props.loading}
					rowKey='id'
					style={{margin: "10px"}}
					size="middle"
					pagination={{ 
                        defaultPageSize: 10, 
                        showSizeChanger: true, 
                        pageSizeOptions: ['10', '20', '50'],
                        total: get(this, 'props.meta.total', 0),
					}}
					scroll={{ y: 360 }}
					onChange = {this.onChangeTable}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ loggedInUser , estimate }) => {
	return {
		...estimate,
		loggedInUser,
	};
}

const mapDispatchToProps = {
	getEstimates, 
}


export default connect(mapStateToProps, mapDispatchToProps)(List);

