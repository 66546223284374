import React from 'react';
import { Table, Input, InputNumber, DatePicker, Form, Button, Icon, Select, Switch, AutoComplete } from 'antd';
import { isEqual } from 'lodash';
import UnitSearchBox from 'shared/components/UnitSearchBox';
import DimensionInput from 'shared/components/DimensionsInput';

const FormItem = Form.Item;
const EditableContext = React.createContext();

class EditableCell extends React.Component {
  onChange = (value, form) => {
    const { onUpdate } = this.props;
    if (!!onUpdate && !!form) {
      onUpdate(value, form);
    }
  }

  getInput = (options, suffix, disabled, form) => {
    if (this.props.inputType === 'unit') {
      return <UnitSearchBox onChange={value => this.onChange(value, form)} disabled={disabled} />;
    }
    if (this.props.inputType === 'number') {
      return <InputNumber onFocus={e => e.target.select()} onChange={value => this.onChange(value, form)} disabled={disabled} autoComplete='disabled-auto-complete' />;
    }
    if (this.props.inputType === 'float') {
      return <InputNumber onFocus={e => e.target.select()} onChange={value => this.onChange(value, form)} disabled={disabled} step={0.01} autoComplete='disabled-auto-complete' />;
    }
    if (this.props.inputType === 'date') {
      return <DatePicker onChange={value => this.onChange(value, form)} disabled={disabled} />;
    }
    if (this.props.inputType === 'dimension') {
      return <DimensionInput onChange={value => this.onChange(value, form)} disabled={disabled} />
    }
    if (this.props.inputType === 'autocomplete') {
      return (
        <AutoComplete
          style={{ width: '100%' }} 
          disabled={disabled}
          dataSource={options}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ width: 'auto' }}
          filterOption={(inputValue, option) => {
            return !inputValue || option.key.toLocaleUpperCase().includes(inputValue.toLocaleUpperCase())
          }}
          onChange={value => this.onChange(value, form)} 
        />
      )
    }
    if (this.props.inputType === 'select') {
      return (
        <Select
          style={{ width: '100%' }}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ width: 'auto' }}
          disabled={disabled}
          onChange={value => this.onChange(value, form)} 
        >
          {
            options.map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)
          }
        </Select>
      )
    }
    if (this.props.inputType === 'switch') {
      return <Switch disabled={disabled} onChange={value => this.onChange(value, form)} />
    }
    return (
      <Input 
        disabled={disabled} 
        autoComplete='disabled-auto-complete' 
        suffix={suffix} 
        onChange={e => this.onChange(e.target.value, form)} 
        onFocus={e => e.target.select()}
      />
    );
  };

  getRules = ({ rules, required, title, disabled }) => {
    const isRequired = required && !disabled;
    if (rules && !disabled) {
      if (Array.isArray(rules)) {
        return [].concat(
          { required: isRequired, message: `Please input ${title}!`},
          ...rules);
      } else {
        return [].concat(
          { required: isRequired, message: `Please input ${title}!`},
          rules);
      }
    } else {
      return [{ required: isRequired, message: `Please input ${title}!`}]
    }
  }

  render() {
    const {
      required,
      disabled,
      editing,
      dataIndex,
      title,
      record,
      options,
      rules,
      inputType,
      suffix,
      valuePropName,
      defaultValue,
      onUpdate,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {
          (form) => {
            const { getFieldDecorator } = form;
            const isDisabled = disabled && disabled(form);
            return (
              <td {...restProps}>
              {
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: this.getRules({ rules, required, title, disabled: isDisabled }),
                    initialValue: record[dataIndex] || defaultValue,
                    valuePropName: valuePropName
                  })(this.getInput(options, suffix, isDisabled, form))}
                  </FormItem>
                ) : restProps.children
              }
              </td>
            );
          }
        }
      </EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    const columns = [].concat(...this.props.columns);
    const isEditable = columns.some(column => column.editable);
    if (isEditable) {
      columns.push({
        title: '',
        dataIndex: 'operation',
        width: '10%',
        fixed: 'right',
        render: (text, record, index) => {
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <EditableContext.Consumer>
                    {
                      form => (
                        <Button
                          style={{ border: 'none', background: 'none', marginRight: 2 }}
                          onClick={(e) => this.save(e, form, record.key)}
                        >
                          <Icon type='check' style={{ color: '#5A5' }} />
                        </Button>
                      )
                    }
                  </EditableContext.Consumer>
                  <Button style={{ border: 'none', background: 'none' }} onClick={e => this.cancel(e)}><Icon type='close' style={{ color: '#A55' }} /></Button>
                </div>
              ) : (
                <Button 
                  style={{ border: 'none', background: 'none' }}
                  onClick={e => this.delete(e, index)}
                >
                  <Icon type='close' style={{ color: '#A55' }} />
                </Button>
              )}
            </div>
          );
        },
      });
    }
    this.state = { value: props.value || [], editingKey: '', mode: '', columns, isEditable };
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.value && !isEqual(this.props.value, prevProps.value)) {
			this.setState({ value: this.props.value })
    }
    if (this.props.columns && !isEqual(this.props.columns, prevProps.columns)) {
      const columns = [].concat(...this.props.columns);
      const isEditable = columns.some(column => column.editable);
      if (isEditable) {
        columns.push({
          title: '',
          dataIndex: 'operation',
          width: '10%',
          fixed: 'right',
          render: (text, record, index) => {
            const editable = this.isEditing(record);
            return (
              <div>
                {editable ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <EditableContext.Consumer>
                      {
                        form => (
                          <Button
                            style={{ border: 'none', background: 'none', marginRight: 2 }}
                            onClick={(e) => this.save(e, form, record.key)}
                          >
                            <Icon type='check' style={{ color: '#5A5' }} />
                          </Button>
                        )
                      }
                    </EditableContext.Consumer>
                    <Button style={{ border: 'none', background: 'none' }} onClick={e => this.cancel(e)}><Icon type='close' style={{ color: '#A55' }} /></Button>
                  </div>
                ) : (
                  <Button 
                    style={{ border: 'none', background: 'none' }}
                    onClick={e => this.delete(e, index)}
                  >
                    <Icon type='close' style={{ color: '#A55' }} />
                  </Button>
                )}
              </div>
            );
          },
        });
      }
      this.setState({ columns, isEditable });
    }
	}

  isEditing = (record) => {
    return record.key === this.state.editingKey;
  };

  edit = (key) => {
    if (this.state.editingKey === key) {
      return;
    }
    this.setState({ editingKey: key, mode: 'edit' });
  }

  delete = (e, index) => {
    e.stopPropagation();
    const newState = this.state.value.filter((val, indx) => indx !== index);
    this.setState({ value: newState, editingKey: '', mode: '' })

    if (this.props.onChange) {
      this.props.onChange(newState);
    }
  }

  save = (e, form, key) => {
    e.stopPropagation();
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      if (this.props.beforeRowSave) {
        this.props.beforeRowSave(row);
      }
      const newData = [...this.state.value];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        const newItem = { ...item, ...row }
        newData.splice(index, 1, newItem);
        this.setState({ value: newData, editingKey: '', mode: '' });
      } else {
        newData.push(row);
        this.setState({ value: newData, editingKey: '', mode: '' });
      }

      const onChange = this.props.onChange;
      if (onChange) {
        onChange(newData);
      }
    });
  }

  cancel = (e) => {
    e.stopPropagation();
    const value = this.state.value;
    if (this.state.mode === 'edit') {
      this.setState({ value, editingKey: '', mode: '' });
      return;
    }
    const editingIndex = this.state.editingKey;
    const lastItemIndex = value.length - 1;
    if (lastItemIndex === editingIndex) {
      value.splice(lastItemIndex, 1);
    }
    this.setState({ value, editingKey: '', mode: '' });
  };

  add = () => {
    const value = this.state.value;
    let lastItem;
    if (value.length > 0) {
      lastItem = {...value[value.length - 1]};
    }
    if (value.length > 0 && !this.isItemNotEmpty(lastItem)) {
      return;
    }
    const keys = this.props.columns.map(column => column.dataIndex);
    const newObj = keys.reduce((o, key) => ({ ...o, [key]: ''}), {})
    if (this.props.instantiateItem) {
      this.props.instantiateItem(newObj);
    }
    const index = !lastItem ? 0 : ++lastItem.key;
    newObj.key = index;
    value.push(newObj);
    this.setState({ value: value, editingKey: index, mode: 'new' })
  }

  isItemNotEmpty = (obj) => {
    if (!obj) {
      return false;
    }
    const object = { ...obj };
    object.key = '';
    const valuesExist = Object.values(object).some(value => {
      return /\S/.test(value);
    });
    return valuesExist;
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.state.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => {
          return ({
            record,
            inputType: !!col.inputType ? col.inputType : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: this.isEditing(record),
            required: col.required,
            disabled: col.disabled,
            options: !!col.options ? col.options : [],
            valuePropName: !!col.valuePropName ? col.valuePropName : 'value',
            suffix: !!col.suffix ? col.suffix : '',
            rules: !!col.rules ? col.rules : [],
            defaultValue: col.defaultValue !== null ? col.defaultValue : '',
            onUpdate: col.onUpdate,
          })
        },
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          className='editable-table'
          size='small'
          components={components}
          dataSource={this.state.value}
          columns={columns}
          onRow={(record) => {
            return {
              onClick: () => this.edit(record.key),
            };
          }}
          pagination={false}
          scroll={{ x: 1200 }}
        />
        {
          this.state.isEditable &&
          <Button 
            ghost 
            type='primary' 
            icon='plus'
            style={{ border:'transparent', padding:'0px' }} 
            onClick={this.add}>
              Add more item/s
          </Button>
        }
      </EditableContext.Provider>
    );
  }
}

export default Form.create()(EditableTable);