import React, { Component } from 'react';
import { get } from 'lodash';

export default class extends Component {
    render() {
        return (
            <div style={{ fontWeight: '500', fontSize: '1.3em', color: '#979797' }}>
                { 
                    `${get(this.props, 'value.length', 0)}" X ${get(this.props, 'value.width', 0)}"`
                }
            </div>
        );
    }
}