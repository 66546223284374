import React, { Component } from 'react';
import { Form } from 'antd';
import { css } from 'emotion';
import { concat, get, reject, some, isEqual } from 'lodash';
import EditableTable from 'shared/components/EditableTable';

const FormItem = Form.Item;

const wrapper = css({
	maxWidth: '100%',
	margin: '25px',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
	'& .top-form-container': {
		
	}
})

class DeliveriesTab extends Component {
	columns = [
		{
			title: 'Date',
			dataIndex: 'date',
			editable: true,
			required: true,
		}, {
			title: 'Copies Delivered',
			dataIndex: 'copies_delivered',
			editable: true,
			required: true,
		}, {
			title: 'Total Delivered',
			dataIndex: 'total_delivered',
			editable: true,
			required: true,
		}, 
	];

	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(get(this, 'props.data'), get(prevProps, 'data'))) {
			this.loadData();
		}
	}

	loadData = () => {
		const { form: { setFieldsValue }, data, protected_data } = this.props;
		setFieldsValue({ deliveries: concat(protected_data, data) });
	}

	getValidatedData = () => {
		const { form, action, protected_data } = this.props;
		const response = {};
		form.validateFieldsAndScroll((error, values) => {
			if (error) {
				response.error = error;
			} else {
				values.deliveries = reject(values.deliveries, function(material) { return some(protected_data, material) });
				response.values = {
					[action]: values
				};
			}
		});
		return response;
	}

  render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;
    return (
      <div className={wrapper}>
				<div className='form-group-title'>
					Deliveries
				</div>
				<div className='top-form-container'>
					<FormItem>
						{getFieldDecorator('deliveries', {
							initialValue: []
						})(
							<EditableTable
								columns={this.columns}
								onChange={this.onChange}
							/>
						)}
					</FormItem>
				</div>
      </div>
    );
  }
}

export default Form.create()(DeliveriesTab);