import React, { Component } from 'react';
import { 
  Form, 
  Input, 
  DatePicker, 
  Divider, 
  Col, 
  Cascader, 
  InputNumber 
} from 'antd';
import moment from 'moment';
import { css } from 'emotion';
import PartnerSearch from './PartnerSearchBar';
import { publication_types } from '../data';

const FormItem = Form.Item;
const { TextArea } = Input;

const wrapper = css({
  margin: '10px 15px',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
  '& .formcol': {
    margin: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
});

class PublicationFormPage1 extends Component {  
  componentDidMount () {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.values && this.props.values) {
      this.loadData();
    }
  }

  loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
    }
    const { setFieldsValue } = form;
    setFieldsValue({
        rfq_number: values.rfq_number,
        request_date: moment(values.request_date),
        case_number: values.case_number,
        estimate_description: values.estimate_description,
        total_amount: values.total_amount,
        data: {
            publication_type: values.data.publication_type,
            partner: values.data.partner,
            number_of_books: values.data.number_of_books,
        },
    });
  }

  onContinuePressed = () => {
    const { moveToNext, form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        moveToNext({
          ...values,
          request_date: values.request_date.format('YYYY-MM-DD HH:mm:ss'),
          partner_id: values.data.partner.id,
          publication_type: values.data.publication_type.join(' '),
          job_title: values.data.publication_type.join(' '),
        });
      }
    });
  }
  
  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={wrapper}>
        <div className='form-group-title' >
          Publication Estimate Reference
        </div>
        <div className='formcol'>
          <div>
            <Col sm={{ span: 24 }} md={{ span: 16 }}>
              <FormItem
                label='Request for Quotation Number'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('rfq_number', {
                  rules: [{
                    required: true, message: 'Required field.',
                  }],
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 8 }}>
              <FormItem
                label='Request Date'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('request_date', {
                  rules: [{
                    required: true, message: 'Required field.',
                  }],
                })(
                  <DatePicker />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 24 }}>
                <FormItem
                  label='Publication Type'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.publication_type', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }],
                    getValueFromEvent: this.inputJobTitle
                  })(
                    <Cascader style={{ width: '100%' }} options={publication_types} />
                  )}
                </FormItem>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 24 }}>
                <FormItem
                  label='Client'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.partner', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }],
                  })(
                    <PartnerSearch />
                  )}
                </FormItem>
              </Col>
            </div>
          </div>
          <Divider dashed />
          <div className='form-group-title' >
            Publication Details
          </div>
          <div className='formcol' >
            <div>
              <Col sm={{ span: 24 }} md={{ span: 16 }}>
                <FormItem
                  label='Case Number'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('case_number', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }],
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 8 }}>
                <FormItem
                  label='Number of Books'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.number_of_books', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }, {
                      type: 'number', message: 'Please input a number!',
                    }],
                    initialValue: 0
                  })(
                    <InputNumber onFocus={e => e.target.select()} min={0} step={1}/>
                  )}
                </FormItem>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 24 }}>
                <FormItem
                  label='Notes'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('estimate_description', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }],
                  })(
                    <TextArea rows={3} />
                  )}
                </FormItem>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 10 }}>
                <FormItem
                  label='Publication Fee'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('total_amount', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }, {
                      type: 'number', message: 'Please input a number!',
                    }],
                    initialValue: 0
                  })(
                    <InputNumber style={{ width: '100%' }} onFocus={e => e.target.select()} min={0} step={0.01}/>
                  )}
                </FormItem>
              </Col>
            </div>
          </div>
      </div>
    );
  }
}

export default Form.create()(PublicationFormPage1);