import React, { Component } from 'react';
import { 
  Timeline, 
  Divider,
  Popover
} from 'antd';
import { css } from 'emotion';
import { get, isEmpty, filter } from 'lodash';

const { Item } = Timeline;

const timelineStyle = css({
  padding: '15px',
  background: '#FFFFFF', 
  boxShadow: '1px 0px 3px grey',
});

const timelineFinishedStyle = css({
  '& .ant-timeline-item-tail': {
    borderLeft: '2px solid #67BB01'
  },
  '& .ant-timeline-item-head': {
    backgroundColor: '#67BB01',
    borderColor: '#67BB01'
  }
});

const timelineNotFinishedStyle = css({
  '& .ant-timeline-item-tail': {
    borderLeft: '2px solid #A6AAAE'
  },
  '& .ant-timeline-item-head': {
    backgroundColor: '#FFFFFF',
    borderColor: '#A6AAAE'
  }
});

const finishedDotStyle = css({
  backgroundColor: '#67BB01',
  border: '1px solid #67BB01',
  height: '10px',
  width: '10px',
  borderRadius: '100px',
  margin: '10px'
})

// const notFinishedDotStyle = css({
//   backgroundColor: '#FFFFFF',
//   border: '1px solid #A6AAAE',
//   height: '10px',
//   width: '10px',
//   borderRadius: '100px',
//   margin: '10px'
// })

export default class extends Component {
  showProgress (step, progress) {
    const status = progress.filter(prog => prog.step === step.step);
    return status.map((stat, index) => {
      return (
        <Popover key={index.toString()} content={`"${stat.notes || ''}"`} title={stat.action_by_name} >
          <div style={{ display: 'flex', margin: '2px' }}>
            <div className={finishedDotStyle}></div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{get(stat, 'action')} by { get(stat, 'action_by_name') }</div>
              <div>{ get(stat, 'action_date') }</div>
            </div>
          </div>
        </Popover>
      );
    });
  }

  renderTimelineItems = (work_order) => {
    if (!work_order) {
      return null;
    }
    const { process, progress } = work_order;
    return process.map((step, index) => 
      <Item 
        key={index.toString()} 
        className={ 
          !isEmpty(filter(progress, prog => prog.step === step.step && prog.action === 'Release'))
            ? timelineFinishedStyle : timelineNotFinishedStyle } 
      >
        <h3>{step.division}</h3>
        {
          this.showProgress(step, progress)
        }
      </Item>
    );
  }

  render() {
    const { work_order } = this.props;  
    return (
      <div className={timelineStyle}>
        <h2>
          Routes
        </h2>
        <Divider />
        <Timeline>
          {
            this.renderTimelineItems(work_order)
          }
        </Timeline>
      </div>
    );
    // return (
    //   <Timeline>
    //     <Item className={!!data.released_date ? timelineFinishedStyle : timelineNotFinishedStyle} >
    //       <h3>PPCD</h3>
    //       <div style={{ display: 'flex', margin: '10px' }}>
    //         <div className={finishedDotStyle}></div>
    //         <div style={{ display: 'flex', flexDirection: 'column' }}>
    //           <div>WO created by {data.woe_created_by_name}</div>
    //           <div>{data.woe_created_date}</div>
    //         </div>
    //       </div>
    //       <div style={{ display: 'flex', margin: '10px' }}>
    //         <div className={!!data.cs_generated_date ? finishedDotStyle : notFinishedDotStyle}></div>
    //         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    //           <div>CS generated by {data.cs_generated_by_name}</div>
    //           <div>{data.cs_generated_date}</div>
    //         </div>
    //       </div>
    //       <div style={{ display: 'flex', margin: '10px' }}>
    //         <div className={!!data.released_date ? finishedDotStyle : notFinishedDotStyle}></div>
    //         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    //           <div>CS released by {data.released_by_name}</div>
    //           <div>{data.released_date}</div>
    //         </div>
    //       </div>
    //     </Item>
    //     {
    //       this.renderTimelines()
    //     }
    //   </Timeline>
    // );
  }
}
