import React, { Component } from 'react';
import { Radio, Checkbox, Select, Input, Form, Row, Col } from 'antd';
import ContactPerson, { contactValidator } from '../components/ContactPerson';
import PartnerSearchBar from '../components/PartnerSearchBar';
import { client_types } from '../data';
import philippines from 'shared/constants/philippines';
import { keys, values, get } from 'lodash';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;

class CreateUpdateForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, fieldsValues) => {
      if (!err) {
        const sameDeliveryAddress = this.props.form.getFieldValue('is_same_delivery_address');
        const values = {
          ...fieldsValues,
          delivery_address: sameDeliveryAddress 
          ? `${fieldsValues['building_name'] || ''} ${fieldsValues['address']} ${fieldsValues['municipality']} ${fieldsValues['region']} ${fieldsValues['province']}`
          : `${fieldsValues['delivery_address']}`
        }
        this.props.handleSubmit(values);
      }
    });
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { data, form } = this.props;
		const { setFieldsValue } = form;
    if (data) {
      setFieldsValue({
				// is_active: data.is_active,
				partner_type: data.partner_type,
				client_type: data.client_type,
				tin: data.tin,
				assign_parent: !!data.parent_partner_id,
				parent_partner_id: data.parent_partner_id,
				partner_name: data.partner_name,
				building_name: data.building_name,
				address: data.address,
				region: data.region,
				province: data.province,
				municipality: data.municipality, 
				zip_code: data.zip_code,
        is_same_delivery_address: data.is_same_delivery_address,
        delivery_address: data.delivery_address,
				contact_persons: data.contact_persons.map(person => ({...person}))
      });
    }
  }

  showRegionSelect = () => {
    const regions = values(philippines);
    return (
      <Select
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {
          regions.map(region => <Option key={region.region_name} value={region.region_name}>{region.region_name}</Option>)
        }
      </Select>
    )
  }

  showProvinceSelect = () => {
    const { getFieldValue } = this.props.form;
    const selectedRegion = getFieldValue('region');
    const region = get(philippines, `[${selectedRegion}]`)
    let children = [];
    if (region && region.province_list) {
      children = keys(region.province_list);
    }

    return (
      <Select
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {
          children.map(province => <Option key={province} value={province}>{province}</Option>)
        }
      </Select>
    );
  }

  showMunicipalitySelect = () => {
    const { getFieldValue } = this.props.form;
    const selectedRegion = getFieldValue('region');
    const selectedProvince = getFieldValue('province');
    const province = get(philippines, `[${selectedRegion}].province_list[${selectedProvince}]`)
    let children = [];
    if (province && province.municipality_list) {
      children = keys(province.municipality_list);
    }

    return (
      <Select
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {
          children.map(municipality => <Option key={municipality} value={municipality}>{municipality}</Option>)
        }
      </Select>
    );
  }

  render() {
    const { form, data } = this.props;
    const { getFieldDecorator } = form;
		const editMode = !!data;
    const partner_type = form.getFieldValue('partner_type');
    const same_delivery_address = form.getFieldValue('is_same_delivery_address');
		const assign_parent = form.getFieldValue('assign_parent')
    return (
      <Form id='partnerForm' onSubmit={this.handleSubmit}>
        {/*<FormItem
         label="Status"
          labelCol={{ sm: {span: 24}, md: {span: 2} }}
          wrapperCol={{ sm: {span: 24}, md: {span: 4} }}
          style={{ marginTop: '0px'}}
        >
          {getFieldDecorator('is_active', {
            valuePropName: 'checked',
            initialValue: true,
          })(
            <Switch />
          )}
          </FormItem>*/}
        <FormItem
          label='Partner Type'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ margin: '0px' }}
        >
          {getFieldDecorator('partner_type', {
            rules: [{
              required: true, message: 'Required field.',
            }],
          })(
            <RadioGroup disabled={editMode}>
              <Radio value='Client'>Client</Radio>
              <Radio value='Supplier'>Supplier</Radio>
            </RadioGroup>
          )}
				</FormItem>
				<FormItem
					label={partner_type === 'Client' ? 'Client Type' : ''}
					labelCol={{ span: partner_type === 'Client' ? 24 : 0 }}
					wrapperCol={{ span: partner_type === 'Client' ? 24 : 0 }}
					style={{ margin: '0px',padding: "10px"  }}
				>
					{getFieldDecorator('client_type', {
						// hidden: partner_type !== 'Client',
						rules: [{
							required: partner_type === 'Client', message: 'Required field.',
            }],
            initialValue: partner_type === 'Client' ? '-' : ''
					})(
						<Select disabled={editMode}>
							{
								client_types.map(type => <Option key={type} value={type}>{type}</Option>)
							}
						</Select>
					)}
				</FormItem>
				<FormItem
					label={partner_type === 'Supplier' ? 'Tax Identification Number' : ''}
					labelCol={{ span: partner_type === 'Supplier' ? 24 : 0 }}
					wrapperCol={{ span: partner_type === 'Supplier' ? 24 : 0 }}
					style={{ margin: '0px' }}
				>
					{getFieldDecorator('tin', {
						hidden: partner_type !== 'Supplier',
						rules: [{
							required: partner_type === 'Supplier', message: 'Required field.',
						}],
					})(
						<Input />
					)}
				</FormItem>
        <FormItem
          labelCol={{ sm: {span: 24} }}
          wrapperCol={{ sm: {span: 24} }}
          style={{ margin: '0px' }}
        >
          {getFieldDecorator('assign_parent', {
            
          })(
            <Checkbox disabled={editMode}>Assign Parent</Checkbox>
          )}
        </FormItem>
        <FormItem
          wrapperCol={{ sm: {span: 8} }}
          style={{ margin: '0px' }}
        >
          {getFieldDecorator('parent_partner_id', {
            hidden: !assign_parent,
            rules: [{
              required: assign_parent, message: 'Required field.'
            }]
          })(
            <PartnerSearchBar disabled={!assign_parent} />
          )}
        </FormItem>
        <FormItem
          label='Partner Name'
          labelCol={{ sm: {span: 24}, md: {span: 4} }}
          wrapperCol={{ sm: {span: 24} }}
          style={{ marginTop: '10px' }}
        >
          {getFieldDecorator('partner_name', {
            rules: [{
              required: true, message: 'Required field.',
            }],
          })(
            <Input disabled={editMode} />
          )}
        </FormItem>
        <div className='ant-form-item-label' style={{ margin: '10px 0px', fontWeight: 'bold' }}>Address:</div>
        <Row gutter={16}>
          {
          //   <Col sm={{ span: 24 }} md={{ span: 12 }}>
          //   <FormItem
          //     label='Building Name - Optional'
					// 		labelCol={{ span: 24 }}
					// 		wrapperCol={{ span: 24 }}
          //     style={{ margin: '0px' }}
          //   >
          //     {getFieldDecorator('building_name', {
                
          //     })(
          //       <Input />
          //     )}
          //   </FormItem>
          // </Col>
          }
          <Col sm={{ span: 24 }} md={{ span: 24 }}>
            <FormItem
              label='Street Address'
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
            >
              {getFieldDecorator('address', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                <Input />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <FormItem
              label='Region'
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
            >
              {getFieldDecorator('region', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                this.showRegionSelect()
              )}
            </FormItem>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <FormItem
              label='Province'
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
            >
              {getFieldDecorator('province', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                this.showProvinceSelect()
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <FormItem
              label='City/Municipality'
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
            >
              {getFieldDecorator('municipality', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                this.showMunicipalitySelect()
              )}
            </FormItem>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <FormItem
              label='Zip Code'
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
            >
              {getFieldDecorator('zip_code', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                <Input />
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem
          label='Delivery Address'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ marginTop: '20px' }}
        >
          {getFieldDecorator('is_same_delivery_address', {
            valuePropName: 'checked'
          })(
            <Checkbox>Same as the address above</Checkbox>
          )}
        </FormItem>
        <FormItem
          wrapperCol={{ sm: {span: 24} }}
          style={{ margin: '0px' }}
        >
          {getFieldDecorator('delivery_address', {
            hidden: same_delivery_address,
            rules: [{
              required: !same_delivery_address, message: 'Required field.'
            }]
          })(
            <Input disabled={same_delivery_address} autoComplete='disabled-auto-complete' />
          )}
        </FormItem>
        <div className='ant-form-item-label' style={{ margin: '10px 0px', fontWeight: 'bold' }}>Contact Details:</div>
        <FormItem
          wrapperCol={{ sm: {span: 24} }}
          style={{ marginBottom: '100px'  }}
        >
          {getFieldDecorator('contact_persons', {
            rules: [{
              validator: contactValidator
            }],
          })(
            <ContactPerson />
          )}
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(CreateUpdateForm)