import React, { Component } from 'react';
import { Upload, notification } from 'antd';
import { Icon } from 'antd';
import { getAuthHeader } from 'shared/utils/api_client';
import { downloadFile } from 'shared/utils/actionCreators';

const Dragger = Upload.Dragger; 

class FilesUpload extends Component {
    constructor(props) {
        super(props);
    
        const value = props.value || [];
        this.state = {
          value: value
        };
    }

    // // for handling resetFields
    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value || []
        });  
    }

    componentDidUpdate(prevProps) {
        if(this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value || []
            });
        }
    }
    
    onDraggerChange = (info) => {
        let {fileList} = info;
        
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.location || file.url
            }
            return file;
        })

        fileList = fileList.filter(function(file){
            // if (file.response) {
            //     return file.response.status === 'success' || file.response.status === 'done';
            // }
            return true;
        });
        // this.setState({value: fileList});
        this.props.onChange(fileList);
    }

    downloadFile = (document) => {
        downloadFile(document.name, document.url, (error) => {
          notification.error({ message: error.message });
        })
    }

    render() {
        const uploadProps = {
            accept: '*',
            action: this.props.upload_url,
            multiple: true,
            headers: {...getAuthHeader() },
            data: {
            },
            listType: "text",
            onChange: this.onDraggerChange,
            defaultFileList: this.props.value,
            fileList: this.state.value,
            disabled: this.props.disabled,
            onPreview: this.downloadFile
        }

        return <Dragger {...uploadProps} >
            <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
    }
}

export default FilesUpload;
