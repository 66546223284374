import queryString from 'query-string';
import { toNumber } from 'lodash';

export const getEstimate = (estimates, props) => {
  if (!props.match || !props.match.params || !props.match.params.id) {
      return undefined;
  }
  const id = props.match.params.id;

  if (!estimates || estimates.length <= 0) {
      return undefined;
  }

  return estimates.find(estimate => estimate.id === parseInt(id))
}

export const getEstimateIdFromUrl = (props) => {
  if (!props.match || !props.match.params || !props.match.params.id) {
    return undefined;
  }
  return toNumber(props.match.params.id) || undefined;
}

export const getModeFromProps = (props) => {
  if (!props.location || 
      !props.location.pathname) {
          return 'new';
  }

  if (props.location.pathname.includes('edit')) {
      return 'edit';
  }

  if (!props.match || !props.match.params || !props.match.params.id) {
      return 'new';
  }

  return 'copy';
}

export const getType = (estimateId, estimate, props) => {
  if (estimateId && estimate && estimate.type) {
    return estimate.type;
  }

  const params = queryString.parse(props.location.search);
  return params.type;
}