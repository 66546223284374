import React, { Component } from 'react';
import { Input } from 'antd';
import { get, isEqual } from 'lodash';
import EditableTable from 'shared/components/EditableTable';

const TextArea = Input.TextArea;

export default class extends Component {
	state = { 
		value: {
			data: []
		}
	};

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.loadData();
    }
  }

  loadData = () => {
    const { data, notes } = this.props.value;
    const newData = data.map((datum, index) => {
      return {
        ...datum,
        key: index
      };
    });
    this.setState({ value: { data: newData, notes } });
	}
	
  columns = [
    {
      title: 'Work Item Title',
      dataIndex: 'title',
			editable: !this.props.readOnly,
			disabled: () => false,
      required: true,
    }, {
      title: 'Work Item Description',
      dataIndex: 'description',
      editable: !this.props.readOnly,
			disabled: () => false,
      required: true,
    }, 
  ];

  onChange = (value) => {
		const notes = get(this.state, 'value.notes', '');
    this.setState({ 
      value: { data: value, notes }
    });

    if (this.props.onChange) {
      this.props.onChange({ data: value, notes });
    }
	}
	
	onNotesChange = (e) => {
    const value = e.target.value;
		const data = get(this.state, 'value.data', '');
		this.setState({ 
      value: { data, notes: value }
    });

    if (this.props.onChange) {
      this.props.onChange({ data, notes: value });
    }
	}

  render() {
		const { withNotes, readOnly } = this.props;
		const { value } = this.state;
    return (
			<div style={{ padding: '20px' }} >
				<EditableTable
					value={value.data}
					columns={this.columns}
					onChange={this.onChange}
				/>
				{
					withNotes &&
					<TextArea 
						value={value.notes}
						placeholder='Notes'
						disabled={readOnly}
						autoSize={{ minRows: 4, maxRows: 4 }} 
            onChange={this.onNotesChange}
            style={{ margin: '10px 0px' }} 
					/>
				}
      </div>
    );
  }
}