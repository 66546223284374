import React, { Component } from 'react';
import accounting from 'accounting';
import { isEqual } from 'lodash';
import EditableTable from 'shared/components/EditableTable';
import { worktype_options } from '../data';
import DivTotal from 'shared/components/DivTotal';

export default class extends Component {
	state = {};

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.loadData();
    }
  }

  loadData = () => {
    const { data, total } = this.props.value;
    const newData = data.map((datum, index) => {
      return {
        ...datum,
        key: index
      };
    });
    this.setState({ value: { data: newData, total } });
  }

  columns = [
    {
      title: 'Work Type',
      dataIndex: 'work_type',
      inputType: 'autocomplete',
      options: worktype_options.finishing,
      width: '30%',
      editable: !this.props.readOnly,
      required: true,
    }, {
      title: 'Qty',
      dataIndex: 'quantity',
      inputType: 'number',
      rules: [{ type: 'number', message: 'Invalid input' }],
      width: '10%',
      editable: !this.props.readOnly,
      disabled: form => {
        const work_type = form.getFieldValue('work_type');
        return !worktype_options.finishing.includes(work_type);
      },
      onUpdate: (value, form) => {
        const { getFieldValue, setFieldsValue } = form;
        const rate = getFieldValue('rate') || 0;
        const buffer = getFieldValue('buffer') || 0;
        setFieldsValue({
          amount: value * rate * buffer
        });
      }
    }, {
      title: 'Rate',
      dataIndex: 'rate',
      inputType: 'float',
      rules: [{ type: 'number', message: 'Invalid input' }],
      width: '10%',
      editable: !this.props.readOnly,
      disabled: form => {
        const work_type = form.getFieldValue('work_type');
        return !worktype_options.finishing.includes(work_type);
      },
      onUpdate: (value, form) => {
        const { getFieldValue, setFieldsValue } = form;
        const quantity = getFieldValue('quantity') || 0;
        const buffer = getFieldValue('buffer') || 0;
        setFieldsValue({
          amount: value * quantity * buffer
        });
      }
    }, {
      title: 'Copies',
      dataIndex: 'copies',
      inputType: 'number',
      rules: [{ type: 'number', message: 'Invalid input' }],
      width: '10%',
      editable: !this.props.readOnly,
      disabled: form => {
        const work_type = form.getFieldValue('work_type');
        return !worktype_options.finishing.includes(work_type);
      }
    }, {
      title: 'Buffer',
      dataIndex: 'buffer',
      inputType: 'number',
      defaultValue: 20,
      editable: !this.props.readOnly,
      width: '10%',
      disabled: form => {
        const work_type = form.getFieldValue('work_type');
        return !worktype_options.finishing.includes(work_type);
      },
      onUpdate: (value, form) => {
        const { getFieldValue, setFieldsValue } = form;
        const rate = getFieldValue('rate') || 0;
        const quantity = getFieldValue('quantity') || 0;
        setFieldsValue({
          amount: value * rate * quantity
        });
      }
    }, {
      title: 'Total Amount',
      dataIndex: 'amount',
      inputType: 'float',
      width: '20%',
      editable: !this.props.readOnly,
      render: (text, record, index) => <span style={{ fontWeight: 'bold' }}>{accounting.formatMoney(text, '₱', 2, ',', '.')}</span>,
      disabled: form => {
        const work_type = form.getFieldValue('work_type');
        return worktype_options.finishing.includes(work_type);
      }
    }
  ];

  beforeRowSave = (row) => {
    if (worktype_options.finishing.findIndex(option => option.toLowerCase() === row.work_type.toLowerCase()) === -1) {
      row.quantity = '-';
      row.rate = '-';
      row.copies = '-';
      row.buffer = 'none';
    } else {
      // row.amount = row.copies * row.rate * row.buffer;
    }
  }

  instantiateItem = (item) => {
    
  }

  onChange = (value) => {
    const total = value.reduce((a, b) => {
      const first = typeof a === 'number' ? a : parseFloat(a.amount, 10)
      const second = typeof b === 'number' ? b : parseFloat(b.amount, 10)
      return first + second
    }, 0);
    // this.setState({ 
    //   value: { data: value, total }
    // });

    if (this.props.onChange) {
      this.props.onChange({ data: value, total });
    }
  }

  render() {
    const { value } = this.state;
    if (!value) {
      return null;
    }
    const { data, total } = value;
    return (
      <div style={{ margin: '20px' }}>
        <EditableTable
          value={data}
          columns={this.columns}
          beforeRowSave={this.beforeRowSave}
          onChange={this.onChange}
        />
        <DivTotal value={total} />
      </div>
    );
  }
}