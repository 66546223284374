import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';

export const columns = [
    {
        title: 'WOE Date',
        dataIndex: 'created_at',
        render: (value) => {
            return moment(value).format('YYYY-MM-DD')
        }
    },
    {
        title: 'WOE Number',
        dataIndex: 'woe_number',
    },
    {
        title: 'Step',
        dataIndex: 'step',
    },
    {
        title: 'Date Received',
        dataIndex: 'received_date',
        render: (value) => {
            if (value == null) {
                return 'Waiting'
            }
            else {
                return value;
            }
        }
    },
    {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Client',
        dataIndex: 'partner_name',
    },
    {
        title: 'Job Title',
        dataIndex: 'job_title',
    },
];


class List extends Component {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <Table
                    columns={columns}
                    dataSource={this.props.data}
                    loading={this.props.loading}
                    rowKey='id'
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50']
                    }}
                    style={{ marginTop: "10px" }}
                />
            </div>
        );
    }
}

export default List;