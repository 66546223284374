import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { login, API_LOGIN_TYPE } from '../actionCreators';
import { Spin } from 'antd';
import { OAUTH_URL } from 'shared/constants/oauth';

class Login extends Component {
  componentDidMount() {
    if (this.props.loggedInUser != null) {
      this.props.history.push('/');
    } else {
      const query = queryString.parse(this.props.location.search);
      if (query.code) {
        this.props.login({code: query.code});
      }
      else {
        window.location.href = OAUTH_URL;
      }
    }
  }

  componentDidUpdate() {
    if (this.props.loggedInUser != null) {
      this.props.history.push('/');
    }
  }
  

  render() {
    // const errorMessage = 
    //   <div>
    //       { this.props.loginError? <Alert message={this.props.loginError.message} type="error" />: '' }
    //       <br />
    //   </div>

    return (
      <div className="row">
        <Spin />
      </div>
    );
  }
}

const mapStateToProps = state => ({
    loginLoading: state.loadingReducer[API_LOGIN_TYPE],
    loginError: state.errorReducer[API_LOGIN_TYPE],
    loggedInUser: state.loggedInUser,
})

const mapDispatchToProps = {
    login
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
