import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Icon, Input, notification, Checkbox, Popover } from 'antd';
import moment from 'moment';
import CreateUpdate from './CreateUpdate';
import { Header } from 'shared/components/acc-components';
import { getOPs } from '../actionCreators';
import { estimate_types } from 'modules/estimate/data';
import {get} from 'lodash';
import accounting from 'accounting';

const types_filter = estimate_types.map(type => ({ text: type, value: type }));
const status_filter = ['DRAFT', 'RELEASED', 'FINALIZED', 'PAID'].map(status => ({ text: status, value: status }));

const { Search } = Input;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opModalVisible: false,
      opModalData: '',
      columnsToShow: [ 
        'op_number', 
        'op_date', 
        'op_amount', 
        'estimate_number', 
        'type', 
        'partner_name', 
        'partner_name',
        'op_particulars',
        'op_status',
        'created_at',
        'actions'
      ]
    }
  }

  columns = [{
    title: 'OP Number',
    dataIndex: 'op_number',
    key: "op_number"
  }, {
    title: 'OP Date',
    dataIndex: 'op_date',
    key: 'op_date'
  }, {
    title: 'Amount',
    dataIndex: 'op_amount',
    key:  'op_amount',
    render: value => accounting.formatMoney(value, '₱', 2, ',', '.')
  }, {
    title: 'CE Number',
    dataIndex: 'estimate_number',
    key: 'estimate_number'
  }, {
    title: 'Request Type',
    dataIndex: 'type',
    filters: types_filter,
    key: 'type'
    // onFilter: (value, record) => value.localeCompare(record.type, 'en', {sensitivity: 'base'})
  }, {
    title: 'Client Name',
    dataIndex: 'partner_name',
    key: 'partner_name'
  }, {
    title:'Particulars',
    dataIndex: 'op_particulars',
    key: 'op_particulars'
  }, {
    title: 'Status',
    dataIndex: 'op_status',
    key: 'op_status',
    filters: status_filter,
    // onFilter: (value, record) => value.localeCompare(record.op_status, 'en', {sensitivity: 'base'})
  }, {
    title: 'Created At',
    dataIndex: 'created_at',
    key:'created_at',
    render: value => moment(value).format('MM-DD-YYYY HH:mm:ss A')
  }, {
    title:'',
    dataIndex: 'actions',
    key: 'actions',
    render: (text, partner) => {
        return (
          <div>
            <Button 
              style={{border: 'none', background: 'none'}} 
              icon="delete"
              onClick={(e) => {} /* this.onEditUserClicked(e, user) */}
              className='editDeleteButtons'
            />
          </div>
        )
      },
    }
  ];

  componentDidMount() {
    if (this.disableView()) {
      notification.warning({
        message: 'Uh Oh!',
        description: 'You do not have permission to view this module.',
      });
      return;
    }
    this.props.getOPs({
      take: 20, 
      page: 1, 
      sort_key: 'op_number', 
      sort_dir: 'descend'
    });
  }

  onRowClicked = (row) => {
    this.setState({ opModalVisible: true, opModalData: row })
  }
  
  onNewClicked = () => {
    this.setState({ opModalVisible: true })
  }

  onDrawerCloseClicked = () => {
    this.setState({ opModalVisible: false, opModalData: '' })
  }

  onSearch = (value) => {
    this.props.getOPs({
      ...this.props.meta,
      q: value
    });
  }

  onCreateSuccess = () => {
    this.props.getOPs({
      ...this.props.meta,
    });
  }

  onTableChange = (pagination, filters, sorter) => {
    this.props.getOPs({
      ...this.props.meta,
      page: pagination.current,
      take: pagination.pageSize,
      ...filters,
      sort_dir: sorter.order,
      sort_key: sorter.columnKey
    });
  }
  
  disableAdd = () => {
    const permissions = get(this.props, 'loggedInUser.user.permissions.ppcd');
    if (!Array.isArray(permissions)) {
      return true;
    }
    return !permissions.includes('ppcd_add_order_payment');
  }

  disableView = () => {
    const permissions = get(this.props, 'loggedInUser.user.permissions.ppcd');
    if (!Array.isArray(permissions)) {
        return true;
    }
    return !permissions.includes('ppcd_view_order_payment');
  }

  filteredColumns = () => {
    return this.columns.filter(column => column.key === 'actions' || this.state.columnsToShow.includes(column.key))
  }

  toggleColumn = (column) => {
    const columns = [...this.state.columnsToShow];
    const index = columns.indexOf(column);
    if (index >= 0) {
      columns.splice(index, 1);
    } else {
      columns.push(column);
    }
    this.setState({ columnsToShow: columns })
  }

  columnsPopOver = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          this.columns.filter(column => column.key !== 'actions').map(column => {
            const checked = this.state.columnsToShow.includes(column.key);
            return (
              <div key={column.key}>
                <Checkbox
                  onChange={e => this.toggleColumn(column.key)}
                  checked={checked}
                >
                  { column.title }
                </Checkbox>
              </div>
            );
          })
        }
      </div>
    );
  }

  render() {
    const filteredColumns = this.filteredColumns();
    return (
      <div style={{ margin: '20px 40px' }} >
        <Header 
          loading={this.props.loading}
          titleComponent={
            <Search 
              placeholder='Search order of payments'
              onSearch={value => this.onSearch(value)}
              style={{ width: 400 }} 
              disabled ={this.disableView()}  
            /> }
        > 
          <Popover
            title='Configure Columns'
            content={this.columnsPopOver()}
            trigger='click'
          >
            <Button
              shape='circle'
              size='large'
              icon='setting'
            />
          </Popover>
          <Button 
            type='primary' 
            onClick={this.onNewClicked} 
            disabled={this.disableAdd()}
          >
            <Icon type="plus" />New Order of Payment
          </Button>
        </Header>
        <Table
          columns={filteredColumns}
          dataSource={this.props.data}
          loading={this.props.loading}
          rowKey='id'
          style={{margin: "10px"}}
          size="middle"
          pagination={{ 
            defaultPageSize: 10, 
            showSizeChanger: true, 
            pageSizeOptions: ['10', '20', '50'], 
            total: get(this.props, 'meta.total', 0)
          }}
          scroll={{ y: 360 }}
          onChange={this.onTableChange}
          onRow={record => {
            return {
              onClick: () => { this.onRowClicked(record) }, 
            };
          }}
        />
        <CreateUpdate 
          visible={this.state.opModalVisible}
          onDrawerCloseClicked={this.onDrawerCloseClicked}
          successCallback={this.onCreateSuccess}
          data={this.state.opModalData}
        />
      </div>
    );
  }
}

const mapStateToProps = ({loggedInUser, order_of_payment }) => {
  return {
    ...order_of_payment,
    loggedInUser
  };
}

const mapDispatchToProps = {
  getOPs
}

export default connect(mapStateToProps, mapDispatchToProps)(List);