import React, { Component } from 'react';
import { Select, Divider, Icon } from 'antd';
import { debounce, some, concat } from 'lodash';
import { UNIT_SELECTION } from 'shared/constants/definitions';

const Option = Select.Option;

export default class UnitSearchBox extends Component {
  constructor(props) {
    super(props);    
    const value = props.value || undefined;
    this.state = {
      value: value,
      searchValue: '',
      options: []
    };
    this.onSearch = debounce(this.onSearch, 500);
  }

  static getDerivedStateFromProps(props) {
    if ('value' in props) {
      return {
        value: props.value || undefined
      }
    }
  }

  // // // for handling resetFields
  // componentWillReceiveProps(props) {
  //   if ('value' in props) {
  //     this.setState({
  //         value: props.value || undefined,
  //     });
  //   }
  // }

  componentDidMount() {
    const unit_options = localStorage.getItem('unit_options');
    const storedItems = !unit_options ? [] : JSON.parse(unit_options);
    this.setState({
      options: concat(UNIT_SELECTION, storedItems)
    });
  }

  onSearch = (search) => {
    this.setState({ searchValue: search })
  }

  handleChange = (value) => {
    this.setState({ value: value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  addItem = () => {
    const unit_options = localStorage.getItem('unit_options');
    let storedItems = !unit_options ? [] : JSON.parse(unit_options);
    storedItems = concat(storedItems, [this.state.searchValue]);
    localStorage.setItem('unit_options', JSON.stringify(storedItems));
    this.setState({
      options: concat(this.state.options, [this.state.searchValue])
    });
  }

  renderDropDown = (menu) => {
    const { searchValue } = this.state;
    const hasValue = !searchValue || some(this.state.options, function(option) {
      return option.toLowerCase() === searchValue.toLowerCase()
    });
    
    if (hasValue) {
      return menu;
    } else {
      return (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div
            style={{ padding: '4px 8px', cursor: 'pointer' }}
            onMouseDown={e => e.preventDefault()}
            onClick={this.addItem}
          >
            <Icon type="plus" /> {` "${searchValue}"`}
          </div>
        </div>
      );
    }
  }

  render() {
    const { options, value } = this.state;
    const { disabled } = this.props;
    return (
      <Select
        showSearch
        mode='default'
        value={value}
        placeholder='unit'
        onSearch={this.onSearch}
        onChange={this.handleChange}
        style={{ width: '100%' }}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 'auto' }}
        dropdownRender={this.renderDropDown}
        notFoundContent={null}
        disabled={disabled}
      >
        {
          options.map(d => <Option key={d}>{d}</Option>)
        }
      </Select>
    )

  }
}