import React, { Component } from 'react';
import { Divider, notification } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import accounting from 'accounting';
import { downloadFile } from 'shared/utils/actionCreators';

const container = css({
  flex: 1,
  background: '#FFFFFF', 
  padding: '15px 20px', 
  margin: '10px',
  boxShadow: '1px 0px 5px grey',
  '& .title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px' 
  },
  '& .summary-details': {
    display: 'flex', 
    flexDirection: 'column',
    alignSelf: 'stretch',
    '& .column-details': {
      flex: 4,
      padding: '10px'
    },
    '& .column-amount': {
      flex: 3,
      padding: '10px',
      '@media (min-width: 992px)': {
        borderLeft: '1px dashed #9AA0A5',
      }, 
    },
    '& .amount-item': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .label': {
        margin: '0px 15px',
        color: '#9AA0A5', 
        fontSize: '1em',
      },
      '& .value': {
        margin: '0px 15px',
        color: '#646970', 
        fontSize: '1.1em',
      },
    },
    '& .item': {
      margin: '10px',
      '& .label': {
        color: '#9AA0A5', 
        fontSize: '0.9em',
      },
      '& .value': {
          color: '#646970', 
          fontSize: '1.2em',
      },
    },
    '@media (min-width: 992px)': {
      flexDirection: 'row', 
    }, 
  },
});

const urlLink = css({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'inline',
  margin: 0,
  padding: 0,
  color: '#1890ff',
  ':hover, :focus': {
  textDecoration: 'underline'
  }
});

export default class EstimateDetailsView extends Component {
  downloadFile = (document) => {
    downloadFile(document.name, document.url, (error) => {
      notification.error({ message: error.message });
    })
  }

  render() {
    const { estimate } = this.props;
    if (!estimate) {
      return null;
    }
    return (
      <div className={container}>
        <div className='title'>
          Summary
        </div>
        <div className='summary-details'>
          <div className='column-details'>
            <div className='item'>
              <div className='label'>Request for Quotation:</div>
              <div className='value'>{ get(estimate, 'rfq_number') }</div>
            </div> 
            <div className='item'>
              <div className='label'>Client:</div>
              <div className='value'>{ get(estimate, 'partner_name') }</div>
            </div>
            <div className='item'>
              <div className='label'>Quantity:</div>
              <div className='value'>
              {`${get(estimate, 'data.quantity.value1')} ${get(estimate, 'data.quantity.unit1')}, 
                ${get(estimate, 'data.quantity.value2')} ${get(estimate, 'data.quantity.unit2')},
                ${get(estimate, 'data.quantity.value3')} ${get(estimate, 'data.quantity.unit3')}`}
              </div>
            </div>
            <div className='item'>
              <div className='label'>Size:</div>
              <div className='value'>
              { 
                `${get(estimate, 'data.size.length')} X ${get(estimate, 'data.size.width')}`
              }
              </div>
            </div>
            <div className='item'>
              <div className='label'>Works:</div>
              <div className='value'>
              { 
                `${get(estimate, 'data.works.length')} X ${get(estimate, 'data.works.width')}`
              }
              </div>
            </div>
            <div className='item'>
              <div className='label'>On:</div>
              <div className='value'>{ get(estimate, 'data.on') }</div>
            </div>
            <div className='item'>
              <div className='label'>Request Date:</div>
              <div className='value'>{ get(estimate, 'request_date') }</div>
            </div> 
            <div className='item'>
              <div className='label'>Job Title:</div>
              <div className='value'>{ get(estimate, 'job_title') }</div>
            </div>
            <div className='item'>
              <div className='label'>Packaging:</div>
              <div className='value'>{ get(estimate, 'data.packaging') }</div>
            </div>  
            <div className='item'>
              <div className='label'>Processe:</div>
              <div className='value'>{ get(estimate, 'data.process') }</div>
            </div> 
            <div className='item'>
              <div className='label'>Notes:</div>
              <div className='value'>{ get(estimate, 'estimate_description') }</div>
            </div> 
            <div className='item'>
              <div className='label'>Documents:</div>
              <div className='value'>
              {  
                get(estimate, 'data.documents', []).map((document, index) => {
                  return (
                    <div key={index.toString()}>
                      <button 
                        className={urlLink} 
                        onClick={() => this.downloadFile(document)}
                      >
                        {document.name}
                      </button>
                    </div>
                  )
                })
              }
              </div>
            </div> 
          </div>
          <div className='column-amount'>
            <div className='amount-item'>
              <div className='label'>Composing:</div>
              <div className='value'>{ accounting.formatMoney(get(estimate, 'data.division.composing.total', 0), '₱', 2, ',', '.') }</div>
            </div>
            <div className='amount-item'>
              <div className='label'>Photo-Litography:</div>
              <div className='value'>{ accounting.formatMoney(get(estimate, 'data.division.photo_litography.total', 0), '₱', 2, ',', '.') }</div>
            </div>
            <div className='amount-item'>
              <div className='label'>Press:</div>
              <div className='value'>{ accounting.formatMoney(get(estimate, 'data.division.press.total', 0), '₱', 2, ',', '.') }</div>
            </div>
            <div className='amount-item'>
              <div className='label'>Finishing:</div>
              <div className='value'>{ accounting.formatMoney(get(estimate, 'data.division.finishing.total', 0), '₱', 2, ',', '.') }</div>
            </div>
            <Divider />
            <div className='amount-item'>
              <div className='label' style={{ flex: 2 }}>Indirect Materials:</div>
              <div className='value' style={{ flex: 1 }}>{ accounting.formatMoney(get(estimate, 'data.division.indirect.total', 0), '₱', 2, ',', '.') }</div>
              <div style={{ flex: 1 }}></div>
            </div>
            <div className='amount-item'>
              <div className='label' style={{ flex: 2 }}>Stock (Direct Materials):</div>
              <div className='value' style={{ flex: 1 }}>{ accounting.formatMoney(get(estimate, 'data.division.stock.total', 0), '₱', 2, ',', '.') }</div>
              <div style={{ flex: 1 }}></div>
            </div>
            <Divider />
            <div className='amount-item'>
              <div className='label'>Admin Cost (+30% of Materials):</div>
              <div className='value'>{ accounting.formatMoney(get(estimate, 'data.admin_cost', 0), '₱', 2, ',', '.') }</div>
            </div>
            <div className='amount-item'>
              <div className='label'>Total Estimated Cost:</div>
              <div className='value'>{ accounting.formatMoney(get(estimate, 'total_estimate', 0), '₱', 2, ',', '.') }</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}