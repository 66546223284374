import React, { Component } from 'react';
import { Tabs, Form } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import WorkEnvelopeTable from 'modules/work_order/components/WorkEnvelopeTable';

const TabPane = Tabs.TabPane;

const wrapper = css({
	margin: '25px',
	display: 'flex', 
	flexDirection: 'column',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
  '& .formcol': {
    display: 'flex',
    flexDirection: 'row',
  },
})

class PublicationFormPage2 extends Component {
	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
    if ((!prevProps.values && this.props.values) ||
      (prevProps.values && this.props.values && prevProps.values.id !== this.props.values.id)) {
      this.loadData();
    }
	}

	loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
    }
		const { setFieldsValue } = form;
    setFieldsValue({
			woe_data: {
				work_item: get(values, 'woe_data.work_item')
			},
    });
	}
	
	onContinuePressed = (saveAsDraft) => {
    const { onFormSave, values, moveToNext, form } = this.props;
    form.validateFields((err, val) => {
      if (saveAsDraft) {
        onFormSave({
          ...values,
					...val,
					woe_data: {
						...get(values, 'woe_data', {}),
						...get(val, 'woe_data', {})
					},
          as_draft: true
        });
      } else if (!err) {
        moveToNext({
          ...val,
        });
			}
    });
	}

  render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;
    return (
      <div className={wrapper} >
				<div className='form-group-title' >
					Descriptions and Instructions
				</div>
				<Tabs>
					<TabPane tab='Size' key='size' forceRender>
						{getFieldDecorator('woe_data.work_item.size', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable />
						)}
					</TabPane>
					<TabPane tab='Works' key='works' forceRender>
						{getFieldDecorator('woe_data.work_item.works', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable />
						)}
					</TabPane>
					<TabPane tab='Composing' key='composing' forceRender>
						{getFieldDecorator('woe_data.work_item.composing', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable withNotes />
						)}
					</TabPane>
					<TabPane tab='Photo-Litography' key='photo_litography' forceRender>
						{getFieldDecorator('woe_data.work_item.photo_litography', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable withNotes />
						)}
					</TabPane>
					<TabPane tab='Press' key='press' forceRender>
						{getFieldDecorator('woe_data.work_item.press', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable withNotes />
						)}
					</TabPane>
					<TabPane tab='Finishing' key='finishing' forceRender>
						{getFieldDecorator('woe_data.work_item.finishing', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable withNotes />
						)}
					</TabPane>
					<TabPane tab='Sales and Marketing' key='sales_marketing' forceRender>
						{getFieldDecorator('woe_data.work_item.sales_marketing', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable withNotes />
						)}
					</TabPane>
					<TabPane tab='Machine' key='machine' forceRender>
						{getFieldDecorator('woe_data.work_item.machine', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable withNotes />
						)}
					</TabPane>
					<TabPane tab='Cutters' key='cutters' forceRender>
						{getFieldDecorator('woe_data.work_item.cutters', {
							initialValue: { data: [], notes: '' }
						})(
							<WorkEnvelopeTable withNotes />
						)}
					</TabPane>
				</Tabs>
			</div>
    );
  }
}

export default Form.create()(PublicationFormPage2);