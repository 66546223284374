import base_url from './base_url';

export const API_PARTNER = '/partner';
export const API_PARTNERS = '/partners';
export const API_ESTIMATE = '/estimate';
export const API_ESTIMATES = '/estimates';
export const API_WORK_ORDER = '/work_order';
export const API_WORK_ORDERS = '/work_orders';
export const API_PC = '/production_cost';
export const API_PCS = '/production_costs';
export const API_OP = '/order_of_payment';
export const API_OPS = '/order_of_payments';
export const API_NOTIFICATIONS = '/notifications';
export const API_NOTIFICATION = '/notification';
export const API_NOTIFICATIONSCLEAR = '/notification/clear'
export const API_FILE = '/file';
export const API_QUOTATION_LETTER = '/quotation';

export const API_FILE_UPLOAD = `${base_url}${API_FILE}`;