import { axios } from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';
import {
  API_ESTIMATE, 
  API_ESTIMATES,
	API_QUOTATION_LETTER
} from 'shared/constants/urls';
import { getAuthHeader } from 'shared/utils/api_client';

export const TYPES = {
	GET_LIST: 'GET_ESTIMATES',
	GET: 'GET_ESTIMATE',
	CREATE: 'CREATE_ESTIMATE',
	UPDATE: 'UPDATE_ESTIMATE',
	REQUEST: 'REQUEST_ESTIMATE',
	ERROR: 'ERROR_ESTIMATE',
	QUOTATION: 'QUOTATION',
}

export const getEstimates = (params) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.get(API_ESTIMATES, {
			headers: { ...getAuthHeader() },
			params: { ...params }
		})
		.then(response => {
			dispatch({ type: TYPES.GET_LIST, payload: response.data })
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
		})
	}
}

export const getEstimate = (id, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });
		
		axios.get(`${API_ESTIMATE}/${id}`, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
			if (successCallback) {
				successCallback(response.data);
			} 
			dispatch({ type: TYPES.GET, payload: response.data})
		})
		.catch(error => { 
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}

export const createEstimate = (estimate, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_ESTIMATE}`, estimate, {
			headers: {...getAuthHeader() },
		})
		.then(response => { 
			if (successCallback) {
				successCallback(response.data.estimate);
			} 
			dispatch({ type: TYPES.CREATE, payload: response.data})
		})
		.catch(error => { 
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}

export const updateEstimate = (estimate, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });
		
		axios.put(`${API_ESTIMATE}/${estimate.id}`, estimate, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
			if (successCallback) {
				successCallback(response.data.estimate);
			} 
			dispatch({ type: TYPES.UPDATE, payload: response.data})
		})
		.catch(error => { 
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}

export const createQuotationLetter = (ql, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(API_QUOTATION_LETTER, ql, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			if (successCallback) {
				successCallback(response);
			} 
			dispatch({ type: TYPES.QUOTATION, payload: response.data }) 
		})
		.catch(error => {
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}

export const updateQuotationLetter = (ql, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(API_QUOTATION_LETTER, ql, {
			headers: { ...getAuthHeader() }
		})
		.then(response => {
			if (successCallback) {
				successCallback(response);
			} 
			dispatch({ type: TYPES.QUOTATION }) 
		})
		.catch(error => {
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}

export const downloadQuotation = (estimate_id, errorCallback) => {
	return (dispatch) => {
			axios.get(`${API_QUOTATION_LETTER}/${estimate_id}/quotation_letter`, {
					responseType: 'blob',
					headers: { ...getAuthHeader() }
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `quotationletter-${estimate_id}.pdf`); 
				document.body.appendChild(link);
				link.click();
			})
			.catch(error => {
				if (errorCallback) {
					errorCallback(getError(error));
				} 
			})
	}
}
