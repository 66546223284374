export const estimate_types = [
    'Standard',
    'Accountable',
    'Specialized',
    'Publication'
];

export const standard_form_types = [
    {
        value: 'Civil Service Forms',
        label: 'Civil Service Forms',
        children: [
            {
                value: '6 - Application for Vacation Leave of Absence',
                label: '6 - Application for Vacation Leave of Absence',
            }, {
                value: '32 - Oath of Office',
                label: '32 - Oath of Office',
            }, {
                value: '33 - Appointment (Rev. 1993)',
                label: '33 - Appointment (Rev. 1993)',
            }, {
                value: '41 - Medical Certificate',
                label: '41 - Medical Certificate',
            }, {
                value: '48 - Daily Time Record',
                label: '48 - Daily Time Record',
            }, {
                value: '56 - Acceptance of Resignation',
                label: '56 - Acceptance of Resignation',
            }, {
                value: '67 - Service Record',
                label: '67 - Service Record',
            }, {
                value: '211 - Medical Certificate for Employment',
                label: '211 - Medical Certificate for Employment',
            }, {
                value: '212 - Personal Data Sheet',
                label: '212 - Personal Data Sheet',
            }, {
                value: 'ELC - Employees Leave Card',
                label: 'ELC - Employees Leave Card',
            }
        ]
    }, 
    {
        value: 'COA Circulars',
        label: 'COA Circulars',
        children: [
            {
                value: 'Annex A - Obligaition Slip',
                label: 'Annex A - Obligaition Slip',
            }, {
                value: 'Annex B - Budget Utilization Slip',
                label: 'Annex B - Budget Utilization Slip',
            }, {
                value: 'Annex C - Disbursement Voucher',
                label: 'Annex C - Disbursement Voucher',
            },
        ]
    },
    {
        value: 'General Forms',
        label: 'General Forms',
        children: [
            {
                value: 'Gen. 2 - Reimbursement Receipt',
                label: 'Gen. 2 - Reimbursement Receipt',
            }, {
                value: 'Gen. 6 - Summary of Payroll',
                label: 'Gen. 6 - Summary of Payroll',
            }, {
                value: 'Gen. 9 - Memorandum Receipt for Equipment, Semi-Expendable and Non-Expendable Property',
                label: 'Gen. 9 - Memorandum Receipt for Equipment, Semi-Expendable and Non-Expendable Property',
            }, {
                value: '9 - A - Bill of Lading',
                label: '9 - A - Bill of Lading',
            }, {
                value: '10 - A - Invoice and Receipt for Transfer',
                label: '10 - A - Invoice and Receipt for Transfer',
            }, {
                value: 'Gen. 11 - Invoice Receipt for Property',
                label: 'Gen. 11 - Invoice Receipt for Property',
            }, {
                value: '12 - A - Abstract of Receipt and Account Current',
                label: '12 - A - Abstract of Receipt and Account Current',
            }, {
                value: '15 - A - Power of Attorney',
                label: '15 - A - Power of Attorney',
            }, {
                value: '18 - A - Bond of Indty. for issue of Due Warrant',
                label: '18 - A - Bond of Indty. for issue of Due Warrant',
            }, {
                value: '25 - A - Quarterly Report of Cash Balance of Disbursing Officer',
                label: '25 - A - Quarterly Report of Cash Balance of Disbursing Officer',
            }, {
                value: '33 - A - Invoice and Receipt of Accountable Forms',
                label: '33 - A - Invoice and Receipt of Accountable Forms',
            }, {
                value: '34 - AW - Supplies Adjustment Sheet-Wide',
                label: '34 - AW - Supplies Adjustment Sheet-Wide',
            }, {
                value: '34 - AN - Supplies Adjustment Sheet-Narrow',
                label: '34 - AN - Supplies Adjustment Sheet-Narrow',
            }, {
                value: '34 - AS',
                label: '34 - AS - Supplies Adjustment Sheet - Small',
            }, {
                value: '50 - A - Supplies Adjustment Sheet - Small',
                label: '50 - A - Official Cash Book Large',
            }, {
                value: '51 - A - Official Cash Book Small',
                label: '51 - A - Official Cash Book Small',
            }, {
                value: '55 - A - Official Cash Book-Pocket Size',
                label: '55 - A - Official Cash Book-Pocket Size',
            }, {
                value: '57 - A - Request for Bonding Officials and Employees',
                label: '57 - A - Request for Bonding Officials and Employees',
            }, {
                value: '58 - A - Application for Bonding Officials and Employees',
                label: '58 - A - Application for Bonding Officials and Employees',
            }, {
                value: '71 - A - Inventory Tag Card',
                label: '71 - A - Inventory Tag Card',
            }, {
                value: '74 - A - Report of Cash Examination',
                label: '74 - A - Report of Cash Examination',
            }, {
                value: '78 - A - Signature and Handwriting Specimen Card',
                label: '78 - A - Signature and Handwriting Specimen Card',
            }, {
                value: '85 - A - Sealed Comb. Vault/ Safe Env.',
                label: '85 - A - Sealed Comb. Vault/ Safe Env.',
            }, {
                value: '103 - Official Cash Book for Reg. D. O.',
                label: '103 - Official Cash Book for Reg. D. O.',
            }, {
                value: '104 - Official Cash Book for Bank Cash Book',
                label: '104 - Official Cash Book for Bank Cash Book',
            }, {
                value: '105 - Warrant Register',
                label: '105 - Warrant Register',
            }, {
                value: '106 - Cash Receipt Record',
                label: '106 - Cash Receipt Record',
            }, {
                value: '107 - A - Project Cost Sheet',
                label: '107 - A - Project Cost Sheet',
            }, {
                value: 'Cover - L / L Cover (Legal Size)',
                label: 'Cover - L / L Cover (Legal Size)',
            },
        ]
    }, 
    {
        value: 'Judicial Forms',
        label: 'Judicial Forms',
        children: [
            {
                value: '43 - Court Civil Docket',
                label: '43 - Court Civil Docket',
            }, {
                value: '44 - Court Criminal Docket',
                label: '44 - Court Criminal Docket',
            }, 
        ]
    },
    {
        value: 'Municipal Forms',
        label: 'Municipal Forms',
        children: [
            {
                value: '20 - A - Municipal Treasurer\'s Account Book',
                label: '20 - A - Municipal Treasurer\'s Account Book',
            }, {
                value: '25 - Register of Marriage',
                label: '25 - Register of Marriage',
            }, {
                value: '26 - 1 - Register of Birth',
                label: '26 - 1 - Register of Birth',
            }, {
                value: '27 - Register of Death',
                label: '27 - Register of Death',
            }, {
                value: '63 - Director\'s Minute Book',
                label: '63 - Director\'s Minute Book',
            }, {
                value: '65 - Personal Appearance',
                label: '65 - Personal Appearance',
            }, {
                value: '66 - Affidavit of Official Priest or Minister',
                label: '66 - Affidavit of Official Priest or Minister',
            }, {
                value: '67 - Sworn Statement Advice Parents Ask',
                label: '67 - Sworn Statement Advice Parents Ask',
            }, {
                value: '68 - Advice of Intended Marriage',
                label: '68 - Advice of Intended Marriage',
            }, {
                value: '89 - Comparative Statement of Market / Slaughter',
                label: '89 - Comparative Statement of Market / Slaughter',
            }, {
                value: '90 - Application for Marriage License',
                label: '90 - Application for Marriage License',
            }, {
                value: '91 - ID Certificate of Marriage',
                label: '91 - ID Certificate of Marriage',
            }, {
                value: '92 - Consent of Marriage',
                label: '92 - Consent of Marriage',
            }, {
                value: '94 - Notice of Re-applicants',
                label: '94 - Notice of Re-applicants',
            }, {
                value: '97 - Marriage Contract',
                label: '97 - Marriage Contract',
            }, {
                value: '100 - Register of Application Marriage License',
                label: '100 - Register of Application Marriage License',
            }, {
                value: '102 - Certificate of Birth',
                label: '102 - Certificate of Birth',
            }, {
                value: '103 - Certificate of Death',
                label: '103 - Certificate of Death',
            }, {
                value: '103 - A - Certificate of Fetal Death',
                label: '103 - A - Certificate of Fetal Death',
            }, {
                value: '109 - Municipal Treasurer\'s Journal of Collection and Deposit',
                label: '109 - Municipal Treasurer\'s Journal of Collection and Deposit',
            }, {
                value: 'OCRG',
                label: 'OCRG',
            }, {
                value: '101 - Certificate of Foundling',
                label: '101 - Certificate of Foundling',
            }, {
                value: '109 - Register of Foundling',
                label: '109 - Register of Foundling',
            }, {
                value: '110 - Register of Court Decree / Order',
                label: '110 - Register of Court Decree / Order',
            }, {
                value: '111 - Register of Legal Instrument',
                label: '111 - Register of Legal Instrument',
            }
        ]
    }, 
    {
        value: 'NGAS FORMS - LGU',
        label: 'NGAS FORMS - LGU',
        children: [
            {
                value: '1 - General Journal',
                label: '1 - General Journal',
            }, {
                value: '2 - Cash Receipts Journal',
                label: '2 - Cash Receipts Journal',
            }, {
                value: '3 - Check Disbursements Journal',
                label: '3 - Check Disbursements Journal',
            }, {
                value: '4 - Cash Disbursements Journal',
                label: '4 - Cash Disbursements Journal',
            }, {
                value: '5 - General Ledger',
                label: '5 - General Ledger',
            }, {
                value: '6 - Subsidiary Ledger',
                label: '6 - Subsidiary Ledger',
            }, {
                value: '7 - Supplies Ledger Card',
                label: '7 - Supplies Ledger Card',
            }, {
                value: '8 - Work, Other Animals And Breeding Stocks Ledger Card',
                label: '8 - Work, Other Animals And Breeding Stocks Ledger Card',
            }, {
                value: '9 - Property, Plant And Equipment Ledger Card',
                label: '9 - Property, Plant And Equipment Ledger Card',
            }, {
                value: '10 - Investment Ledger Card',
                label: '10 - Investment Ledger Card',
            }, {
                value: '11 - Real Property Ledger Card',
                label: '11 - Real Property Ledger Card',
            }, {
                value: '12 - Construction in Progress Ledger Card',
                label: '12 - Construction in Progress Ledger Card',
            }, {
                value: '13 - Registry of Appropriations, Allotments and Obligations Capital Outlay',
                label: '13 - Registry of Appropriations, Allotments and Obligations Capital Outlay',
            }, {
                value: '14 - Registry of Appropriations, Allotment and Obligations Maintenance and Other Operating Services',
                label: '14 - Registry of Appropriations, Allotment and Obligations Maintenance and Other Operating Services',
            }, {
                value: '15 - Registry of Appropriations, Allotments and Obligations Personal Services',
                label: '15 - Registry of Appropriations, Allotments and Obligations Personal Services',
            }, {
                value: '16 - Registry of Appropriations, Allotments and Obligations Financial Expenses',
                label: '16 - Registry of Appropriations, Allotments and Obligations Financial Expenses',
            }, {
                value: '17 - Registry of Public Infrustructures',
                label: '17 - Registry of Public Infrustructures',
            }, {
                value: '18 - Registry of Reforestation Project',
                label: '18 - Registry of Reforestation Project',
            }, {
                value: '19 - Cash Book (Treasury)',
                label: '19 - Cash Book (Treasury)',
            }, {
                value: '20 - Cash Book (Bank)',
                label: '20 - Cash Book (Bank)',
            }, {
                value: '21 - Cash Book (Disbursing)',
                label: '21 - Cash Book (Disbursing)',
            }, {
                value: '22 - Allotment And Obligation Slip',
                label: '22 - Allotment And Obligation Slip',
            }, {
                value: '23 - Journal Entry Voucher',
                label: '23 - Journal Entry Voucher',
            }, {
                value: '24 - Disbursement Voucher',
                label: '24 - Disbursement Voucher',
            }, {
                value: '25 - Petty Cash Voucher',
                label: '25 - Petty Cash Voucher',
            }, {
                value: '26 - General Payroll',
                label: '26 - General Payroll',
            }, {
                value: '27 - Daily Wage Payroll',
                label: '27 - Daily Wage Payroll',
            }, {
                value: '28 - Index of Payments',
                label: '28 - Index of Payments',
            }, {
                value: '29 - Purchase Order',
                label: '29 - Purchase Order',
            }, {
                value: '30 - Purchase Request',
                label: '30 - Purchase Request',
            }, {
                value: '31 - Acceptance and Inspection',
                label: '31 - Acceptance and Inspection',
            }, {
                value: '32 - Supplies Availability Inquiry',
                label: '32 - Supplies Availability Inquiry',
            }, {
                value: '33 - Requisition And Issue Slip',
                label: '33 - Requisition And Issue Slip',
            }, {
                value: '34 - Acknowledgement Receipt For Equipment',
                label: '34 - Acknowledgement Receipt For Equipment',
            }, {
                value: '35 - Summary of Supplies and Materials Issued',
                label: '35 - Summary of Supplies and Materials Issued',
            }, {
                value: '36 - Stock Card',
                label: '36 - Stock Card',
            }, {
                value: '37 - Property Card',
                label: '37 - Property Card',
            }, {
                value: '38 - Work, Other Animals And Breeding Stocks Property Card',
                label: '38 - Work, Other Animals And Breeding Stocks Property Card',
            }, {
                value: '39 - Check Register',
                label: '39 - Check Register',
            }, {
                value: '40 - Accountant\'s Advice for Local Check Disbursement',
                label: '40 - Accountant\'s Advice for Local Check Disbursement',
            }, {
                value: '41 - Report of Collections And Deposits',
                label: '41 - Report of Collections And Deposits',
            }, {
                value: '42 - Report of Accountability For Accountable Forms',
                label: '42 - Report of Accountability For Accountable Forms',
            }, {
                value: '43 - Consolidated Report of Accountability For Accountable Form',
                label: '43 - Consolidated Report of Accountability For Accountable Form',
            }, {
                value: '44 - Abstract of Real Property Tax Collections',
                label: '44 - Abstract of Real Property Tax Collections',
            }, {
                value: '45 - Liquidation Report',
                label: '45 - Liquidation Report',
            }, {
                value: '46 - Report of Disbursements',
                label: '46 - Report of Disbursements',
            }, {
                value: '47 - Report of Checks Issued',
                label: '47 - Report of Checks Issued',
            }, {
                value: '48 - Report on the Physical Count of Inventories',
                label: '48 - Report on the Physical Count of Inventories',
            }, {
                value: '49 - Report of the Physical Count of Property, Plant & Equipment',
                label: '49 - Report of the Physical Count of Property, Plant & Equipment',
            }, {
                value: '50 - Waste Materials Report',
                label: '50 - Waste Materials Report',
            }, {
                value: '51 - Inventory And Inspection Report of Unserviceable Property',
                label: '51 - Inventory And Inspection Report of Unserviceable Property',
            }
        ]
    },
    {
        value: 'NGAS FORMS - National Government',
        label: 'NGAS FORMS - National Government',
        children: [
            {
                value: '1 - General Journal',
                label: '1 - General Journal',
            }, {
                value: '2 - Cash Receipts Journal',
                label: '2 - Cash Receipts Journal',
            }, {
                value: '3 - Cash Journal',
                label: '3 - Cash Journal',
            }, {
                value: '4 - Check Disbursements Journal',
                label: '4 - Check Disbursements Journal',
            }, {
                value: '5 - Cash Disbursements Journal',
                label: '5 - Cash Disbursements Journal',
            }, {
                value: '6 - General Ledger',
                label: '6 - General Ledger',
            }, {
                value: '7 - Supplies Ledger Card',
                label: '7 - Supplies Ledger Card',
            }, {
                value: '8 - Property, Plant And Equipment Ledger Card',
                label: '8 - Property, Plant And Equipment Ledger Card',
            }, {
                value: '8 - A - Property, Plant And Equipment Ledger Card-Specific Item',
                label: '8 - A - Property, Plant And Equipment Ledger Card-Specific Item',
            }, {
                value: '9 - Subsidiary Ledger',
                label: '9 - Subsidiary Ledger',
            }, {
                value: '10 - Construction in Progress Ledger Card',
                label: '10 - Construction in Progress Ledger Card',
            }, {
                value: '11 - Investment Ledger Card',
                label: '11 - Investment Ledger Card',
            }, {
                value: '12 - Registry of Appropriations, Allotments and Obligations',
                label: '12 - Registry of Appropriations, Allotments and Obligations',
            }, {
                value: '13 - Registry of Allotments and Notice of Cash Allocation (NCA) Issued',
                label: '13 - Registry of Allotments and Notice of Cash Allocation (NCA) Issued',
            }, {
                value: '14 - Registry of Notice of Cash Allocation and Replenishments',
                label: '14 - Registry of Notice of Cash Allocation and Replenishments',
            }, {
                value: '15 - Registry of Allotments and Obligations Capital Outlay (RAOCO)',
                label: '15 - Registry of Allotments and Obligations Capital Outlay (RAOCO)',
            }, {
                value: '16 - Registry of Allotments and Obligations Maintenance and Other Operating Expenses (RAOMO)',
                label: '16 - Registry of Allotments and Obligations Maintenance and Other Operating Expenses (RAOMO)',
            }, {
                value: '17 - Registry of Allotments and Obligations Personal Services (PS)',
                label: '17 - Registry of Allotments and Obligations Personal Services (PS)',
            }, {
                value: '18 - Registry of Allotments and Obligations Financial Expenses (RAOFE)',
                label: '18 - Registry of Allotments and Obligations Financial Expenses (RAOFE)',
            }, {
                value: '19 - Registry of Allotments and Notice of Cash Allocation',
                label: '19 - Registry of Allotments and Notice of Cash Allocation',
            }, {
                value: '20 - Registry of Public Infrastructures-Specific Project',
                label: '20 - Registry of Public Infrastructures-Specific Project',
            }, {
                value: '21 - Registry of Public Infrastructures-Summary',
                label: '21 - Registry of Public Infrastructures-Summary',
            }, {
                value: '22 - Registry of Reforestation Projects',
                label: '22 - Registry of Reforestation Projects',
            }, {
                value: '23 - Registry of Dormant Accounts',
                label: '23 - Registry of Dormant Accounts',
            }, {
                value: '24 - Registry of Accounts Written-Off',
                label: '24 - Registry of Accounts Written-Off',
            }, {
                value: '25 - Registry of Loan Availments and Repayments-Foreign Individual',
                label: '25 - Registry of Loan Availments and Repayments-Foreign Individual',
            }, {
                value: '26 - Registry of Loans Availment s and Repayments-Foreign Summary',
                label: '26 - Registry of Loans Availment s and Repayments-Foreign Summary',
            }, {
                value: '27 - Registry of Loans Availments and Repayments-Domestic Individual',
                label: '27 - Registry of Loans Availments and Repayments-Domestic Individual',
            }, {
                value: '28 - Registry of Loans Availments and Repayments-Domestic Summary',
                label: '28 - Registry of Loans Availments and Repayments-Domestic Summary',
            }, {
                value: '29 - Registry of Grant Availments and Utilization-Foreign Individual Donor',
                label: '29 - Registry of Grant Availments and Utilization-Foreign Individual Donor',
            }, {
                value: '30 - Registry of Grant Availments and Utilization-Foreign Summary',
                label: '30 - Registry of Grant Availments and Utilization-Foreign Summary',
            }, {
                value: '31 - Registry of Grants Availments and Utilization-Domestic Individual Donor',
                label: '31 - Registry of Grants Availments and Utilization-Domestic Individual Donor',
            }, {
                value: '32 - Registry of Grants Availments and Utilization-Domestic Summary',
                label: '32 - Registry of Grants Availments and Utilization-Domestic Summary',
            }, {
                value: '33 - Registry of Guaranteed Loans Loans-Domestic',
                label: '33 - Registry of Guaranteed Loans Loans-Domestic',
            }, {
                value: '34 - Registry of Guaranteed Loans-Foreign',
                label: '34 - Registry of Guaranteed Loans-Foreign',
            }, {
                value: '35 - Cash Receipts Record',
                label: '35 - Cash Receipts Record',
            }, {
                value: '36 - Check Disbursements Record',
                label: '36 - Check Disbursements Record',
            }, {
                value: '37 - Cash Disbursements Record',
                label: '37 - Cash Disbursements Record',
            }, {
                value: '38 - Stock Card',
                label: '38 - Stock Card',
            }, {
                value: '39 - Property Card',
                label: '39 - Property Card',
            }, {
                value: '40 - Journal Entry Voucher',
                label: '40 - Journal Entry Voucher',
            }, {
                value: '41 - Allotment and Obligation Slip',
                label: '41 - Allotment and Obligation Slip',
            }, {
                value: '42 - Order of Payment',
                label: '42 - Order of Payment',
            }, {
                value: '43 - Disbursement Voucher',
                label: '43 - Disbursement Voucher',
            }, {
                value: '44 - Petty Cash Voucher',
                label: '44 - Petty Cash Voucher',
            }, {
                value: '45 - General Payroll',
                label: '45 - General Payroll',
            }, {
                value: '46 - Itinerary of Travel',
                label: '46 - Itinerary of Travel',
            }, {
                value: '47 - Payroll Payment Slip',
                label: '47 - Payroll Payment Slip',
            }, {
                value: '48 - Index of Payments',
                label: '48 - Index of Payments',
            }, {
                value: '49 - Supplies Availability Inquiry',
                label: '49 - Supplies Availability Inquiry',
            }, {
                value: '50 - Requisition and Issue Slip',
                label: '50 - Requisition and Issue Slip',
            }, {
                value: '51 - Purchase Request',
                label: '51 - Purchase Request',
            }, {
                value: '52 - Purchase Order',
                label: '52 - Purchase Order',
            }, {
                value: '53 - Acknowledgement Receipt for Equipment',
                label: '53 - Acknowledgement Receipt for Equipment',
            }, {
                value: '54 - Report of Collections and Deposits',
                label: '54 - Report of Collections and Deposits',
            }, {
                value: '55 - Reports of Checks Issued',
                label: '55 - Reports of Checks Issued',
            }, {
                value: '56 - Report of Disbursements',
                label: '56 - Report of Disbursements',
            }, {
                value: '57 - Petty Cash Replenishment Report',
                label: '57 - Petty Cash Replenishment Report',
            }, {
                value: '58 - Liquidation Report',
                label: '58 - Liquidation Report',
            }, {
                value: '59 - Report of Supplies and Materials Issued',
                label: '59 - Report of Supplies and Materials Issued',
            }, {
                value: '60 - Waste Materials Report',
                label: '60 - Waste Materials Report',
            }, {
                value: '61 - Inventory And Inspection Report of Unserviceable Property',
                label: '61 - Inventory And Inspection Report of Unserviceable Property',
            }, {
                value: '62 - Report on the Physical Count of Inventories',
                label: '62 - Report on the Physical Count of Inventories',
            }, {
                value: '63 - Report on the Physical Count of Property, Plant & Equipment',
                label: '63 - Report on the Physical Count of Property, Plant & Equipment',
            }, {
                value: '64 - Inspection And Acceptance',
                label: '64 - Inspection And Acceptance',
            }, {
                value: '65 - Report of Accountability For Accountable Forms',
                label: '65 - Report of Accountability For Accountable Forms',
            }, 
        ]
    },
    {
        value: 'Other Forms',
        label: 'Other Forms',
        children: [
            {
                value: 'Hospital Form No. 106 - Surgical Memorandum',
                label: 'Hospital Form No. 106 - Surgical Memorandum',
            }, {
                value: 'Hospital Form No. 126 - Clinical Case Record',
                label: 'Hospital Form No. 126 - Clinical Case Record',
            }, {
                value: 'Hospital Form No. 128 - History Record',
                label: 'Hospital Form No. 128 - History Record',
            }, {
                value: 'BSC Form No. 1(numbered) - Requisition for Equipment & Supplies',
                label: 'BSC Form No. 1(numbered) - Requisition for Equipment & Supplies',
            }, {
                value: 'NPO Form No. 1 - Requisition for Printing and Binding',
                label: 'NPO Form No. 1 - Requisition for Printing and Binding',
            }, {
                value: 'BSC Form No. 1 - Requisition for Equipment & Supplies',
                label: 'BSC Form No. 1 - Requisition for Equipment & Supplies',
            }, {
                value: 'AFP JAGO - Morning Report',
                label: 'AFP JAGO - Morning Report',
            }, {
                value: 'AFP No. 63 - Report of Medical Examination',
                label: 'AFP No. 63 - Report of Medical Examination',
            }, {
                value: 'RAF - Record of Accountable forms',
                label: 'RAF - Record of Accountable forms',
            }, {
                value: 'Philippine Reports Vol. 85',
                label: 'Philippine Reports Vol. 85',
            }, {
                value: 'Philippine Reports Vol. 87',
                label: 'Philippine Reports Vol. 87',
            }, {
                value: 'Philippine Reports Vol. 88',
                label: 'Philippine Reports Vol. 88',
            }, {
                value: 'Philippine Reports Vol. 90',
                label: 'Philippine Reports Vol. 90',
            }, {
                value: 'Philippine Reports Vol. 94',
                label: 'Philippine Reports Vol. 94',
            }, {
                value: 'Philippine Reports Vol. 99',
                label: 'Philippine Reports Vol. 99',
            }, {
                value: 'Philippine Reports Vol. 104',
                label: 'Philippine Reports Vol. 104',
            }, {
                value: 'Philippine Reports Vol. 111',
                label: 'Philippine Reports Vol. 111',
            }, {
                value: 'Philippine Reports Vol. 115',
                label: 'Philippine Reports Vol. 115',
            }, {
                value: 'Philippine Reports Vol. 116',
                label: 'Philippine Reports Vol. 116',
            }, {
                value: 'Philippine Reports Vol. 117',
                label: 'Philippine Reports Vol. 117',
            }, {
                value: 'Philippine Reports Vol. 119',
                label: 'Philippine Reports Vol. 119',
            }, {
                value: 'Philippine Reports Vol. 120',
                label: 'Philippine Reports Vol. 120',
            }, {
                value: 'Philippine Reports Vol. 121',
                label: 'Philippine Reports Vol. 121',
            }, {
                value: 'Philippine Reports Vol. 122',
                label: 'Philippine Reports Vol. 122',
            }, {
                value: 'Philippine Reports Vol. 124',
                label: 'Philippine Reports Vol. 124',
            }, {
                value: 'Philippine Reports Vol. 125',
                label: 'Philippine Reports Vol. 125',
            }, {
                value: 'Philippine Reports Vol. 126',
                label: 'Philippine Reports Vol. 126',
            }, {
                value: 'Philippine Reports Vol. 125 and 126 Paperbound',
                label: 'Philippine Reports Vol. 125 and 126 Paperbound',
            }, {
                value: 'L & R Vol. 1 - R.A. 1-89 Paperbound',
                label: 'L & R Vol. 1 - R.A. 1-89 Paperbound',
            }, {
                value: 'L & R Vol. II - R.A. 90-197 Paperbound',
                label: 'L & R Vol. II - R.A. 90-197 Paperbound',
            }, {
                value: 'L & R Vol. IV - R.A. 90-197 Paperbound',
                label: 'L & R Vol. IV - R.A. 90-197 Paperbound',
            }, {
                value: 'L & R Vol. VIII - R.A. 834 - 972 Paperbound',
                label: 'L & R Vol. VIII - R.A. 834 - 972 Paperbound',
            }, {
                value: 'L & R Vol. X - R.A. 1202 - 1411 Paperbound',
                label: 'L & R Vol. X - R.A. 1202 - 1411 Paperbound',
            }, {
                value: 'L & R Vol. XI - R.A. 1412 - 1612',
                label: 'L & R Vol. XI - R.A. 1412 - 1612',
            }, {
                value: 'L & R Vol. XII - R.A. 1613 - 2049',
                label: 'L & R Vol. XII - R.A. 1613 - 2049',
            }, {
                value: 'L & R Vol. XV - R.A. 2617 - 3020 Paperbound',
                label: 'L & R Vol. XV - R.A. 2617 - 3020 Paperbound',
            }, {
                value: 'L & R Vol. XVII - R.A. 3451 - 3511 Paperbound',
                label: 'L & R Vol. XVII - R.A. 3451 - 3511 Paperbound',
            }, {
                value: 'L & R Vol. XVIII - R.A. 3513 - 3846 Paperbound',
                label: 'L & R Vol. XVIII - R.A. 3513 - 3846 Paperbound',
            }, {
                value: 'L & R Vol. XIX - R.A. 3847 - 4172 Paperbound',
                label: 'L & R Vol. XIX - R.A. 3847 - 4172 Paperbound',
            }, {
                value: 'L & R Vol. XX - R.A. 4173 - 4641 Paperbound Local Government Code',
                label: 'L & R Vol. XX - R.A. 4173 - 4641 Paperbound Local Government Code',
            }, {
                value: 'Audtg. Reqmt. - Auditing Requirement Typical Disb.',
                label: 'Audtg. Reqmt. - Auditing Requirement Typical Disb.',
            }, {
                value: 'Local Tax Code - Paper Cover',
                label: 'Local Tax Code - Paper Cover',
            }, {
                value: 'Rules - Regulations Implementing Labor Code',
                label: 'Rules - Regulations Implementing Labor Code',
            }, {
                value: 'R.A. 897 - Amendment Republic Act No. 304',
                label: 'R.A. 897 - Amendment Republic Act No. 304',
            }, {
                value: 'Retirement - Compilation of Retirement Laws',
                label: 'Retirement - Compilation of Retirement Laws',
            }, {
                value: 'C.S. Laws - Compilation of Civil Service Laws',
                label: 'C.S. Laws - Compilation of Civil Service Laws',
            }, {
                value: 'R.A. 4864 - Police Act of 1969',
                label: 'R.A. 4864 - Police Act of 1969',
            }, {
                value: 'R.A 4968 - New Retirement Law',
                label: 'R.A 4968 - New Retirement Law',
            }, {
                value: 'R.A 6111 - Philippine Medicare Commission',
                label: 'R.A 6111 - Philippine Medicare Commission',
            }, {
                value: 'P.D. 1146 - Retire Benefit',
                label: 'P.D. 1146 - Retire Benefit',
            }, {
                value: 'P.D. Vol. 11 - Presidential Decree Vol. II',
                label: 'P.D. Vol. 11 - Presidential Decree Vol. II',
            }, {
                value: 'Omnibus Election Code',
                label: 'Omnibus Election Code',
            }, {
                value: 'Administrative Code of 1987, Executive Order No. 292',
                label: 'Administrative Code of 1987, Executive Order No. 292',
            }, {
                value: 'IRR Local Government Code',
                label: 'IRR Local Government Code',
            },
        ]
    }, 
    {
        value: 'Provincial Forms',
        label: 'Provincial Forms',
        children: [
            {
                value: '10 - A - Abstract of Real Property Tax',
                label: '10 - A - Abstract of Real Property Tax',
            }, {
                value: '13 - A - List of Taxpayments',
                label: '13 - A - List of Taxpayments',
            }, {
                value: '22 - A - List of Taxpayments',
                label: '22 - A - Records of Check and Warrant',
            }, {
                value: '22 - A1 - Record of Check and Warrants Received',
                label: '22 - A1 - Record of Check and Warrants Received',
            }, {
                value: '26 - A - Prisoner\'s Subsistence Voucher',
                label: '26 - A - Prisoner\'s Subsistence Voucher',
            }, {
                value: '32 - A - Prov\'l. Treas. Record Book of Pre-Audit',
                label: '32 - A - Prov\'l. Treas. Record Book of Pre-Audit',
            }, {
                value: '32 - A - 1 - Prov\'l. Auditor\'s Record Book Pre-Audit',
                label: '32 - A - 1 - Prov\'l. Auditor\'s Record Book Pre-Audit',
            }, {
                value: '34 - Prov\'l. Prison Diary',
                label: '34 - Prov\'l. Prison Diary',
            }, {
                value: '35 - Prisoner\'s Data Sheet',
                label: '35 - Prisoner\'s Data Sheet',
            }, {
                value: '38 - A - Prov\'l. / Municipal Payroll',
                label: '38 - A - Prov\'l. / Municipal Payroll',
            }, {
                value: '40 - Record of Assessment',
                label: '40 - Record of Assessment',
            }, {
                value: '41 - Real Property Tax Register Revised 1975',
                label: '41 - Real Property Tax Register Revised 1975',
            }, {
                value: '41 - A - Real Property Tax Register 1973',
                label: '41 - A - Real Property Tax Register 1973',
            }, {
                value: '50 - Certificate of Discharge from Prison',
                label: '50 - Certificate of Discharge from Prison',
            }, {
                value: '55 - A - Account Current of Accountable Forms',
                label: '55 - A - Account Current of Accountable Forms',
            }, {
                value: '58 - Cover for Provincial Form No. 140 / RPA Form',
                label: '58 - Cover for Provincial Form No. 140 / RPA Form',
            }, {
                value: '59 - Bolts for Real Property Forms',
                label: '59 - Bolts for Real Property Forms',
            }, {
                value: '60 - A - Summary of Collections',
                label: '60 - A - Summary of Collections',
            }, {
                value: '61 - A - Special Journal Voucher',
                label: '61 - A - Special Journal Voucher',
            }, {
                value: '85 - A - Official Cash Book',
                label: '85 - A - Official Cash Book',
            }, {
                value: '109 - A - Ledger Sheet',
                label: '109 - A - Ledger Sheet',
            }, {
                value: '112 - A - Special Ledger Sheet',
                label: '112 - A - Special Ledger Sheet',
            }, {
                value: '124 - A - Guide Cards',
                label: '124 - A - Guide Cards',
            }, {
                value: '129 - A - Abstract of Deposits and Trust Funds',
                label: '129 - A - Abstract of Deposits and Trust Funds',
            }, {
                value: '130 - A - Record of General Collections',
                label: '130 - A - Record of General Collections',
            }, {
                value: '144 - Real Property Tax Acct. Req',
                label: '144 - Real Property Tax Acct. Req',
            }, {
                value: '145 - A - Report of Prov\'l. Assessor',
                label: '145 - A - Report of Prov\'l. Assessor',
            }, {
                value: '146 - A - Accession Book',
                label: '146 - A - Accession Book',
            }, {
                value: '147 - Property Record Form',
                label: '147 - Property Record Form',
            }, {
                value: '148 - Book Card',
                label: '148 - Book Card',
            }, {
                value: '149 - Borrower\'s Card',
                label: '149 - Borrower\'s Card',
            }, {
                value: '150 - Catalogue Card Puched and Printed',
                label: '150 - Catalogue Card Puched and Printed',
            }, {
                value: '152 - Card Envelope',
                label: '152 - Card Envelope',
            }, {
                value: '185 - Time Card for Bundy Clock',
                label: '185 - Time Card for Bundy Clock',
            }, {
                value: '216 - A - Report of Cash Disbursement',
                label: '216 - A - Report of Cash Disbursement',
            }, {
                value: '217 - A - Application Allotment and Expense Subsidiary Ledger',
                label: '217 - A - Application Allotment and Expense Subsidiary Ledger',
            }, {
                value: '219 - A - Treasurer\'s General Journal',
                label: '219 - A - Treasurer\'s General Journal',
            }, {
                value: '220 - A - Treasurer\'s Journal of Cash Disbursement',
                label: '220 - A - Treasurer\'s Journal of Cash Disbursement',
            }, {
                value: '221 - A - Treasurer\'s Journal of Cash Disbursement',
                label: '221 - A - Treasurer\'s Journal of Cash Disbursement',
            }, {
                value: '222 - A - Treasurer\'s Journal of Cash Issued',
                label: '222 - A - Treasurer\'s Journal of Cash Issued',
            }, {
                value: '223 - A - Treasurer\'s Journal of Bills Rendered',
                label: '223 - A - Treasurer\'s Journal of Bills Rendered',
            },
        ]
    },
    {
        value: 'RPA Forms',
        label: 'RPA Forms',
        children: [
            {
                value: '1 - W - Declaration of Real Prop.-White, front and back',
                label: '1 - W - Declaration of Real Prop.-White, front and back',
            }, {
                value: '1 - Blue - Declaration of Real Prop.-Copies front and back',
                label: '1 - Blue - Declaration of Real Prop.-Copies front and back',
            }, {
                value: '1 - Canary - Declaration of Real Prop.-Copies front and back',
                label: '1 - Canary - Declaration of Real Prop.-Copies front and back',
            }, {
                value: '1 - Pink - Declaration of Real Prop.-Copies front and back',
                label: '1 - Pink - Declaration of Real Prop.-Copies front and back',
            }, {
                value: '1 - Declaration of Real Prop. one side',
                label: '1 - Declaration of Real Prop. one side',
            }, {
                value: '1 - A - Real Property Field Appraisal and Assessment Sheet, Land, Plants and Trees',
                label: '1 - A - Real Property Field Appraisal and Assessment Sheet, Land, Plants and Trees',
            }, {
                value: '1 - B - Real Property Field Appraisal & Assessment Sheet, Machinery',
                label: '1 - B - Real Property Field Appraisal & Assessment Sheet, Machinery',
            }, {
                value: '1 - C - Real Property Field Appraisal & Assessment Sheet, Machinery',
                label: '1 - C - Real Property Field Appraisal & Assessment Sheet, Machinery',
            }, {
                value: '3 - Assessment Roll',
                label: '3 - Assessment Roll',
            }, {
                value: '4 - Journal of Assessment Trasaction',
                label: '4 - Journal of Assessment Trasaction',
            }, {
                value: '5 - Ownership Record Form',
                label: '5 - Ownership Record Form',
            }, {
                value: '5 - A - Real Property Ownership Card',
                label: '5 - A - Real Property Ownership Card',
            }, 
        ]
    }, {
        value: 'Hospital Forms',
        label: 'Hospital Forms',
        children: [
            {
                value: '1 - Statement of Daily Market Purchase',
                label: '1 - Statement of Daily Market Purchase',
            }, {
                value: '2 - In-Patient Record',
                label: '2 - In-Patient Record',
            }, {
                value: '3 - Doctor\'s Prescription',
                label: '3 - Doctor\'s Prescription',
            }, {
                value: '4 - Outside Patient Record Form',
                label: '4 - Outside Patient Record Form',
            }, {
                value: '5 - Record of Admission',
                label: '5 - Record of Admission',
            }, {
                value: '8 - Record of Person Subsisted',
                label: '8 - Record of Person Subsisted',
            }, {
                value: '9 - Monthly Subsistence Report',
                label: '9 - Monthly Subsistence Report',
            }, {
                value: '10 - Pharmacist\'s Record of Issues',
                label: '10 - Pharmacist\'s Record of Issues',
            }, {
                value: '11 - Pharmacist\'s Monthly Report of Drug Medicine Issued',
                label: '11 - Pharmacist\'s Monthly Report of Drug Medicine Issued',
            }, {
                value: '12 - Record of Services Outside Patients',
                label: '12 - Record of Services Outside Patients',
            }, {
                value: '16 - Record of Hospital Collections',
                label: '16 - Record of Hospital Collections',
            }, {
                value: '20 - Temperature Record',
                label: '20 - Temperature Record',
            }, {
                value: '26 - Treatment Record',
                label: '26 - Treatment Record',
            }, {
                value: '30 - Patient\'s Ledger Card',
                label: '30 - Patient\'s Ledger Card',
            }, {
                value: '36 - Laboratory Request',
                label: '36 - Laboratory Request',
            }, {
                value: '43 - Clinical Laboratory Record',
                label: '43 - Clinical Laboratory Record',
            }
        ]
    }, 
]

export const publication_types = [
    {
        value: 'Proclamations, Executive Orders and Memorandum Orders',
        label: 'Proclamations, Executive Orders and Memorandum Orders',
    }, {
        value: 'Republic Acts',
        label: 'Republic Acts',
    }, {
        value: 'Decisions of Supreme Courts',
        label: 'Decisions of Supreme Courts',
    }, {
        value: 'Decisions of Court of Appeals',
        label: 'Decisions of Court of Appeals',
    }, {
        value: 'Departments / Bureaus / Offices / Administrative Orders and Regulations',
        label: 'Departments / Bureaus / Offices / Administrative Orders and Regulations',
    }, {
        value: 'Legal and Official Notes',
        label: 'Legal and Official Notes',
        children: [
            {
                value: 'Naturalization',
                label: 'Naturalization',
                children: [
                    {
                        value: 'Order',
                        label: 'Order',
                    }, {
                        value: 'Amended Petitions',
                        label: 'Amended Petitions',
                    }, {
                        value: 'Certifications',
                        label: 'Certifications',
                    },
                ]
            }, {
                value: 'Reconstitutions',
                label: 'Reconstitutions',
                children: [
                    {
                        value: 'Notice of Initial Hearing',
                        label: 'Notice of Initial Hearing',
                    }, {
                        value: 'Order',
                        label: 'Order',
                    }, 
                ]
            }, {
                value: 'Land Registration Authority',
                label: 'Land Registration Authority',
                children: [
                    {
                        value: 'Notice of Hearing',
                        label: 'Notice of Hearing',
                    }, {
                        value: 'Sale of Public Lands',
                        label: 'Sale of Public Lands',
                    }, {
                        value: 'Lease of Public Lands',
                        label: 'Lease of Public Lands',
                    }, 
                ]
            },
        ]
    }, 
];

export const worktype_options = {
    composing: [
        'Composition',
        'Text',
        'Cover',
        'Half-tones',
        'Inserts',
        'Job Work',
        'Chargeable Corrections, Hand',
        'Chargeable Corrections, Machine'
    ],
    press: [
        'Text',
        'Cover',
        'Colored Ink',
        'Half-tones',
        'Numbering',
        'Embossing and printing',
        'Changes',
        'Extra Charge',
    ],
    finishing: [
        'Folding',
        'Ruling',
        'Perforating',
        'Numbering',
        'Punching',
        'Tableting',
        'Inserting',
        'Binding',
        'Counting',
        'Cutting',
        'Verifying',
        'Boxing'
    ]
}