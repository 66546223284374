import React, { Component } from 'react';
import { Input } from 'antd';

const InputGroup = Input.Group;

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.getValue(props.value) || [0, 0]
        }
    }

    getValue = (propsValue) => {
        if (!propsValue) {
            return undefined;
        }

        const value = [];
        const values = propsValue.split(' X ');
        value[0] = parseFloat(values[0].replace('"', ''));
        value[1] = parseFloat(values[1].replace('"', ''));
        return value;
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && !!this.props.value) {
            const newValue = this.getValue(this.props.value);
            this.setState({ value: newValue })
        }
    }

    onLengthChange = (e) => {
        const input = e.target.value;
        const value = this.state.value;
        value[0] = input;
        this.setState({ value });

        if (this.props.onChange) {
            this.props.onChange(`${value[0]}" X ${value[1]}"`);
        }
    }

    onWidthChange = (e) => {
        const input = e.target.value;
        const value = this.state.value;
        value[1] = input;
        this.setState({ value });

        if (this.props.onChange) {
            this.props.onChange(`${value[0]}" X ${value[1]}"`);
        }
    }

    render() {
        return (
            <InputGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5px' }}>
                <Input 
                    style={{ width: '60%' }} 
                    onChange={this.onLengthChange} 
                    value={this.state.value[0]} 
                    suffix='"' 
                    type="number" 
                    step={0.01} 
                    min={0.00}
                    onFocus={e => e.target.select()}
                />
                <Input 
                    style={{ width: '60%' }} 
                    onChange={this.onWidthChange} 
                    value={this.state.value[1]} 
                    suffix='"' 
                    type="number" 
                    step={0.01} 
                    min={0.00}
                    onFocus={e => e.target.select()} 
                />
            </InputGroup>
        );
    }
}

// <div style={{ color: '#767879', margin: '0px 5px' }}>X</div>