import React from 'react';
import { 
  Modal,
  Form,
  Input
} from 'antd';

export default Form.create()(
  class extends React.Component {
    render() {
      const { visible, loading, onCancel, onRelease, form } = this.props;
      const { getFieldDecorator, validateFieldsAndScroll } = form;
      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Confirm Partial Release"
          okText="Release"
          onCancel={onCancel}
          onOk={() => 
            validateFieldsAndScroll((error, values) => {
              if (!error) {
                onRelease(values);
              }
            })
          }
        >
          <Form>
            <Form.Item label="Quantity">
              {
                getFieldDecorator('quantity', {
                  rules: [{ required: true, message: 'Please input quantity' }],
                })(
                  <Input />
                )
              }
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);