import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const _format = (data) => {
    return data.map(item => {
        var official_gazette = '';
        if (item.volume) {
            official_gazette = `Volume ${item.volume} Issue ${item.issue}`;
        }
        return ([
            { text: item.request_date },
            { text: item.case_number },
            { text: item.publication_type },
            { text: item.partner_name },
            { text: official_gazette },
            { text: item.op_status },
            { text: item.receipt_number },
            { text: item.receipt_date },
        ]);
    });
}

const _formatHeader = (data) => {
    return data.map(item => {
        return ({ text: item.title, style: 'tableHeader' });
    });
}

export default (params, data, columns, filename='report.pdf') => {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    const formattedData = _format(data);
    const formattedHeader = _formatHeader(columns)
    
    var optionalParams = [];
    if (params.publication_type) {
        optionalParams.push({ text: `Publication Type: ${params.publication_type}`, fontSize: 10 });
    }
    if (params.partner_id) {
        optionalParams.push({ text: `Partner: ${data[0].partner_name}`, fontSize: 10 });
    }
    if (params.region) {
        optionalParams.push({ text: `Region: ${params.region}`, fontSize: 10 });
    }
    if (params.province) {
        optionalParams.push({ text: `Province: ${params.province}`, fontSize: 10 });
    }
    if (params.municipality) {
        optionalParams.push({ text: `Municipality: ${params.municipality}`, fontSize: 10 });
    }
    if (params.volume) {
        optionalParams.push({ text: `Volume: ${params.volume}`, fontSize: 10 });
    }
    if (params.issue) {
        optionalParams.push({ text: `Issue: ${params.issue}`, fontSize: 10 });
    }

    var documentDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
            { text: params.title, fontSize: 16 },
            { text: 'Date: ' + params.start_date + ' - ' + params.end_date, fontSize: 12 },
            ...optionalParams,
            { text: 'Generated: ' + (new Date()).toDateString(), fontSize: 10 },
            { text: ' ', fontSize: 10 },
            {
                table: {
                    widths: [
                        '*',
                        '*',
                        'auto',
                        'auto',
                        'auto',
                        '*',
                        '*',
                        '*'
                    ],
                    headerRows: 1,
                    body: [
                        formattedHeader,
                        ...formattedData
                    ]
                }
            }
        ],
        defaultStyle: {
            fontSize: 10
        },
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            }
        },
    };

    pdfMake.createPdf(documentDefinition).download(filename);
}
