import React, { Component } from 'react';
import { Tabs } from 'antd';
import { get } from 'lodash';
import IndirectTable from '../components/IndirectTable.1';
import StockTable from '../components/StockTable.1';
import ComposingTable from '../components/ComposingTable.1';
import PhotoLitographyTable from '../components/PhotoLitographyTable.1';
import PressTable from '../components/PressTable.1';
import FinishingTable from '../components/FinishingTable.1';
import WorkEnvelopeTable from '../components/WorkEnvelopeTable';

const TabPane = Tabs.TabPane;

export default class ActualWorkTabs extends Component {
  render() {
    const { work_order, values, form, readOnly } = this.props;
    if (!work_order) {
      return null;
    } 
    const { getFieldDecorator } = form;
    return (
      <div style={{ 
        background: '#FFFFFF', 
        boxShadow: '1px 0px 3px grey',
        padding: '35px'
        }}
      >
        <Tabs>
          <TabPane tab='Indirect' key='indirect' forceRender>
            <WorkEnvelopeTable 
              readOnly
              value={get(work_order, 'woe_data.work_item.indirect', { data: [], notes: '' })}
            />
            {getFieldDecorator('pc_data.division.indirect', {
              initialValue: get(values, 'pc_data.division.indirect', { data: [], total: 0, utilities: 0 })
            })(
              <IndirectTable readOnly={readOnly} />
            )}
          </TabPane>
          <TabPane tab='Stock' key='stock' forceRender>
            <WorkEnvelopeTable 
              readOnly
              value={get(work_order, 'woe_data.work_item.stock', { data: [], notes: '' })}
            />
            {getFieldDecorator('pc_data.division.stock', {
              initialValue: get(values, 'pc_data.division.stock', { data: [], total: 0, utilities: 0 })
            })(
              <StockTable readOnly={readOnly} />
            )}
          </TabPane>
          <TabPane tab='Composing' key='composing' forceRender>
            <WorkEnvelopeTable 
              readOnly
              value={get(work_order, 'woe_data.work_item.composing', { data: [], notes: '' })}
            />
            {getFieldDecorator('pc_data.division.composing', {
              initialValue: get(values, 'pc_data.division.composing', { data: [], total: 0, utilities: 0 })
            })(
              <ComposingTable readOnly={readOnly} />
            )}
          </TabPane>
          <TabPane tab='Photo-Litography' key='photo_litography' forceRender>
            <WorkEnvelopeTable 
              readOnly
              value={get(work_order, 'woe_data.work_item.photo_litography', { data: [], notes: '' })}
            />
            {getFieldDecorator('pc_data.division.photo_litography', {
              initialValue: get(values, 'pc_data.division.photo_litography', { data: [], total: 0, utilities: 0 })
            })(
              <PhotoLitographyTable readOnly={readOnly} />
            )}
          </TabPane>
          <TabPane tab='Press' key='press' forceRender>
            <WorkEnvelopeTable 
              readOnly
              value={get(work_order, 'woe_data.work_item.press', { data: [], notes: '' })}
            />
            {getFieldDecorator('pc_data.division.press', {
              initialValue: get(values, 'pc_data.division.press', { data: [], total: 0, utilities: 0 })
            })(
              <PressTable readOnly={readOnly} />
            )}
          </TabPane>
          <TabPane tab='Finishing' key='finishing' forceRender>
            <WorkEnvelopeTable 
              readOnly
              value={get(work_order, 'woe_data.work_item.finishing', { data: [], notes: '' })}
            />
            {getFieldDecorator('pc_data.division.finishing', {
              initialValue: get(values, 'pc_data.division.finishing', { data: [], total: 0, utilities: 0 })
            })(
              <FinishingTable readOnly={readOnly} />
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}