import React, { Component } from 'react';
import { Tabs } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import IndirectTable from './IndirectTable.1';
import StockTable from './StockTable.1';
import ComposingTable from './ComposingTable.1';
import PhotoLitographyTable from './PhotoLitographyTable.1';
import PressTable from './PressTable.1';
import FinishingTable from './FinishingTable.1';

const { TabPane } = Tabs;

const container = css({
  flex: 1,
  background: '#FFFFFF', 
  margin: '10px',
  padding: '15px 20px', 
  boxShadow: '1px 0px 5px grey',
  '& .title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px'  
  },
  '& .ant-tabs': {
    margin: '15px',
    maxWidth: '1000px'
  },
  '& .ant-tabs-nav-scroll': {
    display: 'flex',
    justifyContent: 'center'
  },
});

export default class EstimateBreakdownView extends Component {
  render() {
    const { estimate } = this.props;
    if (!estimate) {
      return null;
    }
    return (
      <div className={container}>
        <div className='title'>
          Breakdown
        </div>
        <Tabs>
          <TabPane tab='Indirect' key='indirect' forceRender>
              <IndirectTable 
                  readOnly
                  value={get(estimate, 'data.division.indirect')}
              />
          </TabPane>
          <TabPane tab='Stock' key='stock' forceRender>
              <StockTable 
                  readOnly
                  value={get(estimate, 'data.division.stock')}
              />
          </TabPane>
          <TabPane tab='Composing' key='composing' forceRender>
              <ComposingTable 
                  readOnly
                  value={get(estimate, 'data.division.composing')}
              />
          </TabPane>
          <TabPane tab='Photo-Litography' key='photo_litography' forceRender>
              <PhotoLitographyTable 
                  readOnly
                  value={get(estimate, 'data.division.photo_litography')}
              />
          </TabPane>
          <TabPane tab='Press' key='press' forceRender>
              <PressTable
                  readOnly
                  value={get(estimate, 'data.division.press')}
              />
          </TabPane>
          <TabPane tab='Finishing' key='finishing' forceRender>
              <FinishingTable
                  readOnly
                  value={get(estimate, 'data.division.finishing')}
              />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}