import { TYPES } from '../actionCreators';
import _ from 'lodash';

export default (state = [], action) => {
	switch(action.type) {
		case TYPES.GET_LIST:
			return action.payload.data; //_.uniqBy([].concat(...data, ...state), 'id');
		case TYPES.GET:
		case TYPES.UPDATE:
		case TYPES.CREATE:
			const { partner } = action.payload;
			return _.uniqBy([].concat(partner, ...state), 'id');
		default:
			return state;
	}
}