import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { axios } from 'shared/utils/api_client';
import { getAuthHeader } from 'shared/utils/api_client';
import { isEmpty } from 'lodash';

function fetchData(work_order_id, setData, setError) {
    axios.get(`/work_order/${work_order_id}/partial_releases`, { headers: { ...getAuthHeader() }})
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            setError(error.message);
        });
}

const columns = [
    {
        title: 'DIVISION',
        dataIndex: 'source_division',
    },
    {
        title: 'QUANTITY',
        dataIndex: 'quantity',
    },
    {
        title: 'DATE & TIME',
        dataIndex: 'released_date',
    },
    {
        title: 'RELEASER',
        dataIndex: 'released_by_name',
    },
];

export default function (props) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchData(props.work_order_id, setData, setError);
    }, [props.work_order_id]);
    if (!data && !error) {
        return (
            <Spin />
        );
    }
    if (!isEmpty(data)) {
        return (
            <div>
                <div>
                    <h1>Partial Releases</h1>
                </div>
                <div 
                    style={{ margin: '10px', background: '#FFFFFF' }}
                >
                    <Table
                        rowKey='id'
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
            </div>
        );
    } else {
        return <div></div>
    }
}
