import React, { Component } from 'react';
import { Form, Input, Col, DatePicker, Divider } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import PartnerSearchBar from 'modules/work_order/components/PartnerSearchBar';
import { FilesUpload } from 'shared/components/acc-components';
import { API_FILE_UPLOAD } from 'shared/constants/urls';
import moment from 'moment';

const FormItem = Form.Item;

const container = css({
	margin: '25px',
	display: 'flex', 
	flexDirection: 'column',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
  '& .formcol': {
    display: 'flex',
    flexDirection: 'row',
  },
});

class StandardFormPage2 extends Component {
	componentDidMount () {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.values && this.props.values) {
      this.loadData();
    }
  }

  loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
		}
    const { setFieldsValue } = form;
    setFieldsValue({
			partner_id: get(values, 'partner_id'),
			po_number: get(values, 'po_number'),
			po_date: get(values, 'po_date') ? moment(get(values, 'po_date')) : undefined,
			woe_data: {
				obligation_number: get(values, 'woe_data.obligation_number'),
				deliver_to: get(values, 'woe_data.deliver_to'),
				issue_date: get(values, 'woe_data.issue_date') ? moment(get(values, 'woe_data.issue_date')) : undefined,
				delivery_date: get(values, 'woe_data.delivery_date') ? moment(get(values, 'woe_data.delivery_date')) : undefined,
				proof_to: get(values, 'woe_data.proof_to'),
				bill_of_lading: get(values, 'woe_data.bill_of_lading'),
				documents: get(values, 'woe_data.documents'),
			}
    });
	}
	
	onContinuePressed = (saveAsDraft) => {
    const { onFormSave, values, moveToNext, form } = this.props;
    form.validateFields((err, val) => {
      if (saveAsDraft) {
        onFormSave({
          ...values,
					...val,
					po_date: val.po_date ? val.po_date.format('YYYY-MM-DD HH:mm:ss') : '',
					woe_data: {
            ...val.woe_data,
						issue_date: val.woe_data.issue_date ? val.woe_data.issue_date.format('YYYY-MM-DD HH:mm:ss') : '',
						delivery_date: val.woe_data.delivery_date ? val.woe_data.delivery_date.format('YYYY-MM-DD HH:mm:ss') : '',
          },
          as_draft: true
        });
      } else if (!err) {
				const temp_woe_data = get(values, 'woe_data', {})
        moveToNext({
					...val,
					po_date: val.po_date.format('YYYY-MM-DD HH:mm:ss'),
					woe_data: {
						...temp_woe_data,
            ...val.woe_data,
						issue_date: val.woe_data.issue_date.format('YYYY-MM-DD HH:mm:ss'),
						delivery_date: val.woe_data.delivery_date ? val.woe_data.delivery_date.format('YYYY-MM-DD HH:mm:ss') : '',
          },
        });
			}
    });
	}

	render() {
		const { form, type } = this.props;
		const { getFieldDecorator } = form;
		return (
			<div className={container} >
				<div className='form-group-title' >
					Reference Details
				</div>
				<Col sm={{ span: 24 }} md={{ span: 18 }} >
					<FormItem
						label='Client'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ margin: '10px' }}
					>
						{getFieldDecorator('partner_id', {
							rules: [{
								required: true, message: 'Required field.'
							}]
						})(
								<PartnerSearchBar disabled />
						)}
					</FormItem>
				</Col>
				<Divider dashed />
				<Col sm={{ span: 24 }} md={{ span: 18 }} >
					<FormItem
						label='Deliver To'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ margin: '10px' }}
					>
						{getFieldDecorator('woe_data.deliver_to', {
								rules: [{
										required: true, message: 'Required field.',
								}],
						})(
								<Input />
						)}
					</FormItem>
				</Col>
				<Col sm={{ span: 24 }} md={{ span: 18 }} >
					<FormItem
						label='OR Number'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ margin: '10px' }}
					>
						{getFieldDecorator('woe_data.obligation_number', {
								rules: [{
										required: true, message: 'Required field.',
								}],
						})(
								<Input />
						)}
					</FormItem>
				</Col>
				<div className='formcol'>
					<Col sm={{ span: 24 }} md={{ span: 12 }} >
						<FormItem
							label='Issue Date'
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							style={{ margin: '10px' }}
						>
							{getFieldDecorator('woe_data.issue_date', {
									rules: [{
											required: true, message: 'Required field.',
									}],
							})(
									<DatePicker style={{ width: '100%' }} />
							)}
						</FormItem>
					</Col>
					<Col sm={{ span: 24 }} md={{ span: 12 }} >
						<FormItem
							label='Delivery Date'
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							style={{ margin: '10px' }}
						>
							{getFieldDecorator('woe_data.delivery_date', {
									rules: [{
											required: type !== 'Standard', 
											message: 'Required field.',
									}],
							})(
									<DatePicker style={{ width: '100%' }} />
							)}
						</FormItem>
					</Col>
				</div>
				<Col sm={{ span: 24 }} md={{ span: 18 }} >
					<FormItem
						label='Proof To'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ margin: '10px' }}
					>
						{getFieldDecorator('woe_data.proof_to', {
								rules: [{
										required: true, message: 'Required field.',
								}],
						})(
								<Input />
						)}
					</FormItem>
				</Col>
				<Col sm={{ span: 24 }} md={{ span: 18 }} >
					<FormItem
						label='Bill of Lading'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ margin: '10px' }}
					>
						{getFieldDecorator('woe_data.bill_of_lading', {
								rules: [{
										required: true, message: 'Required field.',
								}],
						})(
								<Input />
						)}
					</FormItem>
				</Col>
				<Col sm={{ span: 24 }} md={{ span: 18 }} >
					<FormItem
						label='Purchase Order Number'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ margin: '10px' }}
					>
						{getFieldDecorator('po_number', {
								rules: [{
										required: true, message: 'Required field.',
								}],
						})(
								<Input />
						)}
					</FormItem>
				</Col>
				<Col sm={{ span: 24 }} md={{ span: 12 }} >
					<FormItem
						label='Purchase Order Date'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ margin: '10px' }}
					>
						{getFieldDecorator('po_date', {
								rules: [{
										required: true, message: 'Required field.',
								}],
						})(
								<DatePicker style={{ width: '100%' }} />
						)}
					</FormItem>
				</Col>
				<Col span={24}>
					<FormItem
						label='Attach Document'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ margin: '10px' }}
					>
						{getFieldDecorator('woe_data.documents', {
								rules: [{
										required: true, message: 'Required field.',
								}],
						})(
								<FilesUpload 
										upload_url={API_FILE_UPLOAD}
								/>
						)}
					</FormItem>
				</Col>
			</div>
		);
	}
}

export default Form.create()(StandardFormPage2);