import React, { Component } from 'react';
import { isInt } from 'shared/utils/numbers';

export default class extends Component {
    render() {
        return (
            <div style={{ fontWeight: '500', fontSize: '1.3em', color: '#979797' }}>
                { 
                    typeof this.props.value === 'number' && !isInt(this.props.value)
                    ? this.props.value.toFixed(2) 
                    : this.props.value 
                }
            </div>
        );
    }
}