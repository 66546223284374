import React, { Component } from 'react';
import { Form, Input, InputNumber, Tabs, Row, Col } from 'antd';
import { css } from 'emotion';
import { get, round } from 'lodash';
import IndirectTable from './IndirectTable.1';
import StockTable from './StockTable.1';
import ComposingTable from './ComposingTable.1';
import PhotoLitographyTable from './PhotoLitographyTable.1';
import PressTable from './PressTable.1';
import FinishingTable from './FinishingTable.1';

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

const wrapper = css({
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
  '& .ant-tabs-nav-scroll': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .ant-form-item-label': {
    textAlign: 'left',
    color: '#646970',
    fontSize: '15px',
    '& label': {
      color: '#646970',
      fontSize: '15px',
    }
  },
})

class StandardFormDetailsPage extends Component {
	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
    if ((!prevProps.values && this.props.values) ||
      (prevProps.values && this.props.values && prevProps.values.id !== this.props.values.id)) {
      this.loadData();
    }
	}

	loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
    }
    const { setFieldsValue } = form;
    setFieldsValue({
        data: {
          size: {
            length: get(values, 'data.size.length'),
            width: get(values, 'data.size.width')
          },
          works: {
            length: get(values, 'data.works.length'),
            width: get(values, 'data.works.width')
          },
          on: get(values, 'data.on'),
          process: get(values, 'data.process'),
          packaging: get(values, 'data.packaging'),
          division: get(values, 'data.division')
        }
    });
  }
  
  getTotalEstimate = (values, admin_cost) => {
    const { division } = values.data;
    const total_service = division.composing.total 
      + division.photo_litography.total 
      + division.press.total
      + division.finishing.total;
    return round(total_service + admin_cost, 2);
  }

  getTotalAdminCost = (values) => {
    const { division } = values.data;
    const total_materials = round(division.stock.total, 2)
      + round(division.indirect.total, 2);
    return round(total_materials + (total_materials * 0.30), 2);
  }
    
  onContinuePressed = () => {
    const { moveToNext, form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const admin_cost = this.getTotalAdminCost(values);
        const markup = values.markup || 0;
        const total_estimate = this.getTotalEstimate(values, admin_cost)
        moveToNext({
          ...values,
          total_estimate,
          total_amount: round((total_estimate * (markup / 100)) + total_estimate, 2),
          data: {
            ...values.data,
            admin_cost
          }
        });
      }
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldsValue } = form;
    const works = get(getFieldsValue(), 'data.works', {});
    return (
      <div className={wrapper}>
        <div className='form-group-title' >
          Description and Instructions
        </div>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 16 }}>
            <FormItem
              required
              label='Size'
              labelCol={{ span: 24 }}
              style={{ margin: '0px 10px' }}
            />
            <Col span={12}>
              <FormItem
                wrapperCol={{ span: 24 }}
                style={{ margin: '0px' }}
                label='(in Length)'
                required={false}
              >
                {getFieldDecorator('data.size.length', {
                  rules: [{
                    required: true,
                    pattern: new RegExp('^[a-zA-Z0-9,.-/"]+$'),
                    message: 'Invalid format.',
                  }],
                  initialValue: 0
                })(
                  <Input
                    style={{ width: '100%' }} 
                    // step={0.01} 
                    // min={0.00}
                    // formatter={value => `${value}"`}
                    // parser={value => value.replace('"', '')}
                    onFocus={e => e.target.select()} 
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                wrapperCol={{ span: 24 }}
                style={{ margin: '0px' }}
                label='(in Width)'
                required={false}
              >
                {getFieldDecorator('data.size.width', {
                  rules: [{
                    required: true,
                    pattern: new RegExp('^[a-zA-Z0-9,.-/"]+$'),
                    message: 'Invalid format.',
                  }],
                  initialValue: 0
                })(
                  <Input
                    style={{ width: '100%' }} 
                    // step={0.01} 
                    // min={0.00}
                    // formatter={value => `${value}"`}
                    // parser={value => value.replace('"', '')}
                    onFocus={e => e.target.select()} 
                  />
                )}
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 16 }}>
            <FormItem
              required
              label='Works'
              labelCol={{ span: 24 }}
              style={{ margin: '10px' }}
            />
            <Col span={12}>
              <FormItem
                wrapperCol={{ span: 24 }}
                style={{ margin: '0px' }}
                label='(in Length)'
                required={false}
              >
                {getFieldDecorator('data.works.length', {
                  rules: [{
                    required: true,
                    // pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
                    // message: 'Invalid format.',
                  }],
                  initialValue: 0
                })(
                  <InputNumber
                    style={{ width: '100%' }} 
                    step={1} 
                    min={0}
                    // formatter={value => `${value}"`}
                    // parser={value => value.replace('"', '')}
                    onFocus={e => e.target.select()} 
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                wrapperCol={{ span: 24 }}
                style={{ margin: '0px' }}
                label='(in Width)'
                required={false}
              >
                {getFieldDecorator('data.works.width', {
                  rules: [{
                    required: true,
                    // pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
                    // message: 'Invalid format.',
                  }],
                  initialValue: 0
                })(
                  <InputNumber
                    style={{ width: '100%' }} 
                    step={1} 
                    min={0}
                    // formatter={value => `${value}"`}
                    // parser={value => value.replace('"', '')}
                    onFocus={e => e.target.select()} 
                  />
                )}
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <FormItem
              label='On'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ margin: '10px' }}
            >
              {getFieldDecorator('data.on', {
                rules: [{
                  required: true,
                  pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
                  message: 'Invalid format.',
                }],
              })(
                <Input
                  style={{ width: '100%' }} 
                  // step={0.01} 
                  // min={0.00}
                  onFocus={e => e.target.select()} 
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={{ span: 24 }} md={{ span: 16 }}>
            <FormItem
              label='Process'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ margin: '10px' }}
            >
              {getFieldDecorator('data.process', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                <Input />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={{ span: 24 }} md={{ span: 16 }}>
            <FormItem
              label='Packaging / Construction'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ margin: '10px' }}
            >
              {getFieldDecorator('data.packaging', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                <Input />
              )}
            </FormItem>
          </Col>
        </Row>
        <Tabs size='small'>
          <TabPane tab='Indirect' key='indirect' forceRender>
            {getFieldDecorator('data.division.indirect', {
              initialValue: { data: [], total: 0 }
            })(
              <IndirectTable />
              // <div style={{ height: '400px', width: '80%', background: '#005500' }}></div>
            )}
          </TabPane>
          <TabPane tab='Stock' key='stock' forceRender>
            {getFieldDecorator('data.division.stock', {
              initialValue: { data: [], total: 0 }
            })(
              <StockTable />
              // <div style={{ height: '400px', width: '80%', background: '#009900' }}></div>
            )}
          </TabPane>
          <TabPane tab='Composing' key='composing' forceRender>
            {getFieldDecorator('data.division.composing', {
              initialValue: { data: [], total: 0 }
            })(
              <ComposingTable />
              // <div style={{ height: '400px', width: '80%', background: '#00FF00' }}></div>
            )}
          </TabPane>
          <TabPane tab='Photo-Litography' key='photo_litography' forceRender>
            {getFieldDecorator('data.division.photo_litography', {
              initialValue: { data: [], total: 0 }
            })(
              <PhotoLitographyTable works={works} />
              // <div style={{ height: '400px', width: '80%', background: '#005555' }}></div>
            )}
          </TabPane>
          <TabPane tab='Press' key='press' forceRender>
            {getFieldDecorator('data.division.press', {
              initialValue: { data: [], total: 0 }
            })(
              <PressTable />
              // <div style={{ height: '400px', width: '80%', background: '#005599' }}></div>
            )}
          </TabPane>
          <TabPane tab='Finishing' key='finishing' forceRender>
            {getFieldDecorator('data.division.finishing', {
              initialValue: { data: [], total: 0 }
            })(
              <FinishingTable />
              // <div style={{ height: '400px', width: '80%', background: '#0055FF' }}></div>
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Form.create()(StandardFormDetailsPage);