import React, { Component } from 'react';
import { Divider } from 'antd';
import { css } from 'emotion';
import accounting from 'accounting';

const containerStyle = css({
    flex: '1 1 100%',
    display: 'flex',
    overflowY: 'auto', 
    flexDirection: 'column', 
    alignItems: 'center', 
    '& .title': {
        color: '#415A85', 
        fontSize: '1.5em',
        marginTop: '10px',
        marginBottom: '5px'
    },
    '& .subtitle': {
        color: '#787878', 
        fontSize: '1.3em', 
        marginBottom: '5px',
    },
    '& .item-container': {
        minWidth: '80%',
    },
    '& .item': {
        display: 'flex', 
        margin: '0px 10px',
        justifyContent: 'space-between',
        '& .label': {
            color: '#93AEA7', 
            fontSize: '1.2em',
        },
        '& .value': {
            color: '#787C82', 
            fontSize: '1.2em',
        },
        '& .amount-label': {
            color: '#161717', 
            fontWeight: 'bold', 
            fontSize: '1.2em'
        },
        '& .amount-value': {
            color: '#161717', 
            fontWeight: 'bold', 
            fontSize: '1.2em'
        },
        '@media(min-width: 600px)': {
            flexDirection: 'row', 
            minWidth: '70%',
        },
        '@media(min-width: 992px)': {
            flexDirection: 'row', 
            minWidth: '60%',
        },
    }
});

export default class extends Component {
    onContinuePressed = () => {
        this.props.onFormSave(this.props.values);
    }

    render() {
        const { values } = this.props;
        return (
            <div className={containerStyle}>
                <div className='title'>
                    Publication Estimate Summary
                </div>
                <div className='item-container'>
                    <div className='item'>
                        <div className='label'>Publication Type:</div>
                        <div className='value'>{ values.job_title }</div>
                    </div> 
                    <div className='item'>
                        <div className='label'>Client:</div>
                        <div className='value'>{ values.data.partner.partner_name }</div>
                    </div> 
                    <div className='item'>
                        <div className='label'>Case Number:</div>
                        <div className='value'>{ values.case_number }</div>
                    </div> 
                    <div className='item'>
                        <div className='amount-label'>Total Amount:</div>
                        <div className='amount-value'>{ accounting.formatMoney(values.total_amount, '₱', 2, ',', '.') }</div>
                    </div> 
                </div>
                <Divider style={{ 'backgroundColor':'transparent' }}/>
            </div>
        );
    }
}