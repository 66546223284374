import React, { Component } from 'react';
import { css } from 'emotion';
import { get } from 'lodash';
import moment from 'moment';

const container = css({
  display: 'flex', 
  flex: '1 0 0%',
  flexDirection: 'column',
  alignSelf: 'stretch',
  '& .floating-parent': {
    margin: '10px',
    position: 'absolute',
    top: 35,
    right: 20,
    '@media (max-width: 720px)': {
      position: 'static'
    },
    '& .floating-item': {
      margin: '10px',
      minWidth: '200px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .label': {
        color: '#C0C9C7', 
        fontSize: '0.9em',
      },
      '& .value': {
          color: '#6B6B6B', 
          fontSize: '1.1em',
      },
    },
  },
  '& .item': {
    margin: '10px',
    '& .label': {
      color: '#C0C9C7', 
      fontSize: '0.9em',
    },
    '& .value': {
        color: '#6B6B6B', 
        fontSize: '1.1em',
    },
  },
});

function formatDate (date) {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format('MM-DD-YYYY');
}

export default class WorkOrderDetailsView extends Component {
  render() {
    const { work_order } = this.props;
    if (!work_order) {
      return null;
    }
    const type = get(work_order, 'type')
    return (
      <div className={container}>
        <div className='floating-parent'>
          <div className='floating-item'>
            <div className='label'>Issue Date:</div>
            <div className='value'>{ formatDate(get(work_order, 'woe_data.issue_date')) }</div>
          </div> 
          <div className='floating-item'>
            <div className='label'>Delivery Date:</div>
            <div className='value'>{ formatDate(get(work_order, 'woe_data.delivery_date')) }</div>
          </div> 
        </div>
        <div className='item'>
          <div className='label'>Client:</div>
          <div className='value'>{ get(work_order, 'partner_name', type === 'Publication' ? 'PPCD' : 'N/A') }</div>
        </div>
        <div className='item'>
          <div className='label'>Job Title:</div>
          <div className='value'>
            { 
              get(work_order, 'job_title', 'N/A') 
            }
          </div>
        </div> 
        <div className='item'>
          <div className='label'>Cut Stock Number:</div>
          <div className='value'>
          { 
            get(work_order, 'woe_status') === 'DRAFT'
            ? 'DRAFT'
            : get(work_order, 'cut_stock_number')
          }
          </div>
        </div> 
        <div className='item'>
          <div className='label'>Quantity:</div>
          <div className='value'>
          {
            get(work_order, 'type', '').localeCompare('Publication') !== 0
            ? `${get(work_order, 'woe_data.quantity.value1')} ${get(work_order, 'woe_data.quantity.unit1')}, 
            ${get(work_order, 'woe_data.quantity.value2')} ${get(work_order, 'woe_data.quantity.unit2')},
            ${get(work_order, 'woe_data.quantity.value3')} ${get(work_order, 'woe_data.quantity.unit3')}`
            : get(work_order, 'woe_data.quantity', 'N/A')
          }
          </div>
        </div>
        <div className='item'>
          <div className='label'>Packaging:</div>
          <div className='value'>{ get(work_order, 'estimates[0].data.packaging', 'N/A') }</div>
        </div>  
        <div className='item'>
          <div className='label'>Size:</div>
          <div className='value'>
          { 
            !!get(work_order, 'estimates[0].data.size', undefined)
            ? `${get(work_order, 'estimates[0].data.size.length')} X ${get(work_order, 'estimates[0].data.size.width')}`
            : 'N/A'
          }
          </div>
        </div>
        <div className='item'>
          <div className='label'>Works:</div>
          <div className='value'>
          { 
            !!get(work_order, 'estimates[0].data.works', undefined)
            ? `${get(work_order, 'estimates[0].data.works.length')} X ${get(work_order, 'estimates[0].data.works.width')}`
            : 'N/A'
          }
          </div>
        </div>
        <div className='item'>
          <div className='label'>Notes:</div>
          <div className='value'>{ get(work_order, 'woe_data.note', 'N/A') }</div>
        </div> 
      </div>
    );
  }
}