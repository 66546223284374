import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { axios } from 'shared/utils/api_client';
import moment from 'moment';
import { getAuthHeader } from 'shared/utils/api_client';
import { sortBy } from 'lodash';

function fetchData(work_order_id, setData, setError) {
    axios.get(`/work_order/${work_order_id}/progress`, { headers: { ...getAuthHeader() }})
        .then((response) => {
            setData(sortBy(response.data, ['id']).reverse());
        })
        .catch((error) => {
            setError(error.message);
        });
}

const columns = [
    {
        title: 'ACTION',
        dataIndex: 'action',
    },
    {
        title: 'DIVISION',
        dataIndex: 'division',
    },
    {
        title: 'REMARKS',
        dataIndex: 'notes',
    },
    {
        title: 'DATE & TIME',
        dataIndex: 'created_at',
        render: item => moment(item).format("MMM D YYYY, h:mm:ss a")
    },
    {
        title: 'RELEASER',
        dataIndex: 'action_by_name',
    },
];

export default function WOTimestamp (props) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchData(props.work_order_id, setData, setError);
    }, [props.work_order_id]);
    if (!data && !error) {
        return (
            <Spin />
        );
    }
    return (
        <div 
            style={{ margin: '10px', background: '#FFFFFF' }}
        >
            <Table
                rowKey='id'
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
}
