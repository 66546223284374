import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { Drawer, Button, Icon, notification } from 'antd';
import Header from 'shared/components/Header';
import CreateUpdateForm from './CreateUpdateForm';
import { createPartner, updatePartner } from '../actionCreators';

const drawerStyle = css({
	'& .drawer-content': {
		display: 'flex', 
		height: '100%', 
		overflow: 'hidden'
	},
	'& .ant-drawer-body': {
		height: '100%',
		background: '#FFF',
		padding: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column'
	},
	'& .form-container': {
		flex: 1,
		overflow: 'auto',
		padding: '25px',
	},
	'& .footer': { 
		backgroundColor: '#FFF', 
		width: '100%', 
		borderTop: '1px solid #e8e8e8', 
		padding: '20px', 
		textAlign: 'left',
	},
	'& .loading': {
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center', 
		position: 'absolute', 
		top: '0', 
		bottom: '0', 
		left: '0', 
		right: '0', 
		backgroundColor: '#26272955' 
	},
	'& .container': {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	}
});

class CreateUpdate extends Component {
	handleSubmit = (values) => {
		if (this.props.data) {
			const updateValue = Object.assign(this.props.data, values);
			this.props.updatePartner(updateValue, this.successCallback, this.errorCallback)
		} else {
			this.props.createPartner(values, this.successCallback, this.errorCallback);
		}
	}

	successCallback = () => {
		this.props.onDrawerCloseClicked();
	}

	errorCallback = (error) => {
		notification['error']({ message: error.message });
	}

	render() {
		const title = this.props.data ? 'Modify Details' : 'Create Partner';
		return (
			<Drawer
				width={520}
				placement="right"
				closable={false}
				maskClosable={false}
				destroyOnClose={true}
				visible={this.props.visible}
				className={drawerStyle}
			>
				<Header 
					title={title} 
					containerStyle={{ background: `linear-gradient(to left, #2c83cb, #57aff4)`, padding: '15px 20px', boxShadow: '1px 0px 2px grey' }} 
					titleStyle={{ color: '#FAFBFB', fontWeight: '500' }}
				>
					<Icon onClick={this.props.onDrawerCloseClicked} type='close' style={{ fontSize: '1.5em', color: '#FBFAFA' }} />
				</Header>
				<div className='form-container'>
					<CreateUpdateForm 
						handleSubmit={this.handleSubmit}
						data={this.props.data}
					/>
				</div>
				<div className='footer'>
					<Button loading={this.props.loading} form="partnerForm" key="submit" htmlType="submit"style={{ marginRight: 8 }} type="primary">
						Save
					</Button>
					<Button loading={this.props.loading} onClick={this.props.onDrawerCloseClicked}>
						Cancel
					</Button>
				</div>
			</Drawer>
		);
	}
}

const mapStateToProps = ({partner}) => {
	return {
		loading: partner.loading
	};
}

const mapDispatchToProps = {
	createPartner, updatePartner
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);