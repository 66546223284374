import React, { Component } from 'react';
import { Drawer, Button, Input, Icon, List, Checkbox, notification } from 'antd';
import { css } from 'emotion';
import Header from 'shared/components/Header';
import { API_OPS } from 'shared/constants/urls';
import { getAuthHeader } from 'shared/utils/api_client';
import { axios } from 'shared/utils/api_client';

const { Search } = Input;

const drawerStyle = css({
	'& .drawer-content': {
		display: 'flex', 
		height: '100%', 
		overflow: 'hidden'
	},
	'& .ant-drawer-body': {
		height: '100%',
		background: '#FFF',
		padding: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column'
	},
	'& .form-container': {
		flex: 1,
		overflow: 'auto',
		padding: '25px',
	},
	'& .footer': { 
		backgroundColor: '#FFF', 
		width: '100%', 
		borderTop: '1px solid #e8e8e8', 
		padding: '20px', 
		textAlign: 'left',
	},
	'& .loading': {
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center', 
		position: 'absolute', 
		top: '0', 
		bottom: '0', 
		left: '0', 
		right: '0', 
		backgroundColor: '#26272955' 
	},
	'& .container': {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	}
});

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      data: [],
      fetching: false
    }
    this.lastFetchId = 0;
  }
  
  onSave = () => {
    this.props.onSave(this.state.selected);
  }

  componentDidMount() {
    this.onSearch('')
  }
  
  onSearch = (value) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    axios.get(API_OPS, { params: { take: 99, q: value, type: ['Publication'] }, headers: { ...getAuthHeader() }})
    .then((response) => {
        if (fetchId !== this.lastFetchId) { // for fetch callback order
            return;
        }
        const data = response.data.data || [];
        // const filteredData = uniqBy(data.filter(estimate => estimate.type === 'Publication'), 'cost_estimate')
        this.setState({data: data, fetching: false});
    })
    .catch((error) => {
        notification['error']({ message: error.message });
    });
  }

  onItemCheckChanged = (e, item) => {
    const checked = e.target.checked;
    let newSelected;
    if (checked) {
      newSelected = [].concat(...this.state.selected, item)
    } else {
      newSelected = this.state.selected.filter(select => select.id !== item.id);
    }
    this.setState({ selected: newSelected })
  }

  getFilteredList = (selected, data) => {
    const filteredList = [].concat(...selected, ...data)
      .sort((a,b) => a.id - b.id)
      .filter((elem, index, arr) => {
        return index === arr.length - 1 || arr[index + 1] !== elem
      });
    return filteredList;
  }

  isItemChecked = (item, selected) => {
    return selected.findIndex(data => data.id === item.id) !== -1
  }

	render() {
    const title = 'Select Publication/s';
    const { selected, data } = this.state;
    const list = this.getFilteredList(selected, data);
		return (
			<Drawer
				width={720}
				placement="right"
				closable={false}
				maskClosable={false}
				destroyOnClose={true}
				visible={this.props.visible}
				className={drawerStyle}
      >
        <Header 
          title={title} 
          containerStyle={{ background: `linear-gradient(to left, #2c83cb, #57aff4)`, padding: '15px 20px', boxShadow: '1px 0px 2px grey' }} 
          titleStyle={{ color: '#FAFBFB', fontWeight: '500' }}
        >
          <Icon onClick={this.props.onDrawerCloseClicked} type='close' style={{ fontSize: '1.5em', color: '#FBFAFA' }} />
        </Header>
        <div className='form-container'>
          <Search
            placeholder="Search partner"
            onSearch={this.onSearch}
            style={{ width: 300 }}
          />
          <List
            dataSource={list}
            loading={this.state.fetching}
            renderItem={item => {
              const isChecked = this.isItemChecked(item, selected)
              return (
                <List.Item>
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flex: 0.3, justifyContent: 'center', alignItems: 'center' }}>
                      <Checkbox checked={isChecked} onChange={e => this.onItemCheckChanged(e, item)} />
                    </div>
                    <div style={{ display: 'flex', flex: 0.7, flexDirection: 'column', justifyContent: 'center', alignItems: 'space-between' }}>
                      <div><h3>{item.partner_name}</h3></div>
                      <div>Cost Estimate Number: {item.estimate_number}</div>
                      <div>Request Date: {item.op_date}</div>
                      <div>Publication Type: {item.job_title}</div>
                      <div>Case Number: {item.case_number}</div>
                      <div>Publication Fee: {item.op_amount}</div>
                    </div>
                  </div>
                </List.Item>
              )
            }}
          />
        </div>
        <div className='footer'>
          <Button style={{ marginRight: 8 }} type="primary" onClick={this.onSave}>
						Save
					</Button>
					<Button onClick={this.props.onDrawerCloseClicked}>
						Cancel
					</Button>
        </div>
			</Drawer>
		);
	}
}