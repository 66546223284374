import { TYPES } from '../actionCreators';

export default (state = null, action) => {
	switch(action.type) {
		case TYPES.GET:
		case TYPES.WO_ACTION:
			return action.payload;
		case TYPES.UPDATE:
		case TYPES.CREATE:
			const { work_order } = action.payload;
			return work_order;
		default:
			return state;
	}
}