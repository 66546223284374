export const OAUTH_CLIENT_ID = '87nu6pDay0WXxfKX';
export const OAUTH_CLIENT_SECRET = 'ZRMat8bnQu3DWwiFYcIZvoxtcsETPzmR';
// export const OAUTH_REDIRECT_URI = 'http://localhost:3002/#/login';
export const OAUTH_REDIRECT_URI = 'https://ppcd.npo.staging.acctechnology.ph/#/login';
// export const OAUTH_REDIRECT_URI = 'http://192.168.0.8:3003/#/login';
// export const OAUTH_BASE_URL = 'http://localhost:3000';
export const OAUTH_BASE_URL = 'https://users.npo.staging.acctechnology.ph';
// export const OAUTH_BASE_URL = 'http://192.168.0.8:3001'
// export const OAUTH_BASE_URL = 'http://localhost:3003';
export const OAUTH_URL = OAUTH_BASE_URL + '/#/oauth?redirect_uri=' 
                          + encodeURIComponent(OAUTH_REDIRECT_URI)  
                          + '&client_id=' + OAUTH_CLIENT_ID;
export const OAUTH_LOGOUT_URL = OAUTH_BASE_URL + '/#/logout'; 