import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Icon, Dropdown, Input, Menu, Radio, notification, Popover, Checkbox } from 'antd';
import Header from 'shared/components/Header';
import CutStockDrawer from './CutStockDrawer';
import WOReferenceForm from './WOReferenceForm';
import WOTimestamp from '../components/WOTimestamp';
import { getWorkOrders } from '../actionCreators';
import { work_order_types, woe_status_filter } from '../data';
import moment from 'moment';
import { get } from 'lodash'; 

const Search = Input.Search;
const RadioGroup = Radio.Group;

function disabledAdd (props) {
  const permissions = get(props, 'loggedInUser.user.permissions.ppcd');
  if (!Array.isArray(permissions)) {
      return true;
  }
  return !permissions.includes('ppcd_add_work_order');
}

function disableView (props) {
  const permissions = get(props, 'loggedInUser.user.permissions.ppcd');
  if (!Array.isArray(permissions)) {
      return true;
  }
  return !permissions.includes('ppcd_view_work_order');
}

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      woCreateDrawerData: '',
      csDrawerVisible: false,
      csDrawerData: '',
      hideColumns: []
    }
  }

  componentDidMount() {
    if (disableView(this.props)) {
      notification.warning({
        message: 'Uh Oh!',
                description: 'You do not have permission to view this module.',
      });
      return;
    }
    this.props.getWorkOrders({
      take: 20, 
      page: 1, 
      sort_key: 'woe_number', 
      sort_dir: 'descend',
      type: ['Standard']
    });
  }

  handleWOMenuClick = (event, workorder) => {
    event.domEvent.stopPropagation();
    switch(event.key) {
      case 'view_envelope':
        this.viewEnvelope(workorder);
        return;
      case 'view_cut_stock':
        this.viewCS(workorder);
        return;
      default:
        return;
    }
  }

  viewEnvelope = (workorder) => {
    this.props.history.push(`/workorder/${workorder.id}`);
  }

  viewCS = (workorder) => {
    this.setState({ csDrawerVisible: true, csDrawerData: workorder });
  }

  woMenu = (workorder) => {
    return (
      <Menu onClick={event => this.handleWOMenuClick(event, workorder)}>
        <Menu.Item key="view_envelope">View Work Order Envelope</Menu.Item>
        <Menu.Item key="view_cut_stock">View Cut Stock</Menu.Item>
      </Menu>
    )
  };

  columns = [{
    title: 'WOE Number',
    dataIndex: 'woe_number',
    key: 'woe_number',
    width: 130
  }, {
    title: 'Issue Date',
    dataIndex: 'woe_data.issue_date',
    key: 'issue_date',
    render: item => {
      return moment(item).format('YYYY-MM-DD')
    },
  }, {
    title: 'CS Number',
    dataIndex: 'cut_stock_number',
    key: "cut_stock_number",
    width: 120,
    // render: (item, record) => !record.cs_generated_date ? 'Generate' : item
  }, {
    title: 'CE Reference',
    dataIndex: 'estimate_numbers',
    key: "estimate_numbers",
    width: 150,
    render: items => {
      if (Array.isArray(items)) {
        return (
          <div>
            {
              items.map((item, index) => {
                if (item) {
                  return <div key={index.toString()}>{typeof item === 'string' ? item : item.estimate_number}</div>
                }
                return '';
              })
            }
          </div>
          )
      } else {
        return 'No CE Reference';
      }
    }
  }, {
    title:'Form / Job Title',
    dataIndex: 'job_title',
    key: "job_title",
    width: 200,
    render: (item, record) => {
      if (record.type.localeCompare('Publication') === 0) {
        return `Volume ${record.volume} Issue ${record.issue}`;
      } else {
        return item
      }
    }
  }, {
    title: 'Client',
    dataIndex: 'partner_name',
    key: "partner_name",
    render: (item, record) => {
      if (record.type.localeCompare('Publication') === 0) {
        return 'NPO';
      } else {
        return item
      }
    }
  }, {
    title:'Division',
    dataIndex: 'woe_current_division',
    key:'woe_current_division',
  }, {
    title:'Status',
    dataIndex: 'woe_status',
    key:'woe_status',
    filters: woe_status_filter
  }, {
    title:'',
    dataIndex: 'actions',
    key: 'actions',
    width: 40,
    // fixed: 'right', 
    render: (text, workorder) => {
        const menu = this.woMenu(workorder);
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button
              style={{ border: 'none', background: 'none', transform: 'rotate(90deg)'}} 
              icon="ellipsis"
              onClick={e => e.stopPropagation()}
            />
          </Dropdown> 
        )
      },
    }
  ];

  handleMenuClick = ({ key }) => {
    if (key === 'Copy Work Order') {
      this.setState({ woCopyModalVisible: true })
      return;
    }
    this.props.history.push(`/workorder/new?type=${key}`);
  }
  
  menu = (
    <Menu onClick={this.handleMenuClick}>
      {
        work_order_types.map(datum => <Menu.Item key={datum}>{datum}</Menu.Item>)
      }
    </Menu>
  );

  onTypeChanged = (e) => {
    const type = e.target.value;
    const { filters, ...rest } = this.props.meta;
    const current_types = get(this.props, 'meta.filters.type');
    if (Array.isArray(current_types) && current_types.includes(type)) {
      return;
    }
    this.props.getWorkOrders({
      ...filters,
      ...rest,
      type: [type]
    });
  }
  
  onSearch = (value) => {
    const { filters, ...rest } = this.props.meta;
    this.props.getWorkOrders({
      ...filters,
      ...rest,
      q: value
    });
  }
    
  filteredColumns = () => {
    return this.columns.filter(column => column.key === 'actions' || !this.state.hideColumns.includes(column.key))
  }

  toggleColumn = (column) => {
    const columns = [...this.state.hideColumns];
    const index = columns.indexOf(column);
    if (index >= 0) {
      columns.splice(index, 1);
    } else {
      columns.push(column);
    }
    this.setState({ hideColumns: columns })
  }

  columnsPopOver = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          this.columns.filter(column => column.key !== 'actions').map(column => {
            const checked = !this.state.hideColumns.includes(column.key);
            return (
              <div key={column.key}>
                <Checkbox
                  onChange={e => this.toggleColumn(column.key)}
                  checked={checked}
                >
                  { column.title }
                </Checkbox>
              </div>
            );
          })
        }
      </div>
    );
  }

  onChangeTable = (pagination, filter, sorter)=> {
    const { filters, ...rest } = this.props.meta;
    this.props.getWorkOrders({
      ...filters,
      ...rest,
      page: pagination.current,
      take: pagination.pageSize,
      ...filter,
      sort_dir: sorter.order,
      sort_key: sorter.columnKey
    });
  }

  render() {
    const filteredColumns = this.filteredColumns();
    return (
      <div style={{ margin: '20px 40px' }}>
        <Header 
          loading={this.props.loading} 
          titleComponent={
            <Search 
              placeholder='Search work orders'
              onSearch={this.onSearch} 
              style={{ width: 400 }} 
              disabled={disableView(this.props)}/>
          }
        >    
          <Popover
            title='Configure Columns'
            content={this.columnsPopOver()}
            trigger='click'
          >
            <Button
              shape='circle'
              size='large'
              icon='setting'
            />
          </Popover>
          <Dropdown overlay={this.menu} trigger={['click']} disabled = {disabledAdd(this.props)}>
            <Button type='primary'>
              <Icon type="plus" />New Work Order
            </Button>
          </Dropdown> 
        </Header>
        <div>
          <RadioGroup defaultValue="standard" buttonStyle="solid" onChange={this.onTypeChanged}>
            <Radio.Button style={{ margin: '5px', borderRadius: '50px', border: '0px solid white'  }} value="standard">Standard</Radio.Button>
            <Radio.Button style={{ margin: '5px', borderRadius: '50px', border: '0px solid white' }} value="accountable">Accountable</Radio.Button>
            <Radio.Button style={{ margin: '5px', borderRadius: '50px', border: '0px solid white'  }} value="specialized">Specialized</Radio.Button>
            <Radio.Button style={{ margin: '5px', borderRadius: '50px', border: '0px solid white' }} value="publication">Publication</Radio.Button>
          </RadioGroup>
        </div>
        <Table
          rowKey='id'
          columns={filteredColumns}
          dataSource={this.props.data}
          loading={this.props.loading}
          style={{margin: "10px"}}
          pagination={{ 
            defaultPageSize: 10, 
            showSizeChanger: true, 
            pageSizeOptions: ['10', '20', '50'], 
            total: get(this.props, 'meta.total', 0),
          }}
          size="middle"
          scroll={{ y: 360 }}
          onChange={this.onChangeTable}
          expandedRowRender={record => <WOTimestamp work_order_id={record.id} />}
        />
        <CutStockDrawer
          visible={this.state.csDrawerVisible}
          data={this.state.csDrawerData}
          onClose={() => this.setState({ csDrawerVisible: false, csDrawerData: '' })}
        />
        <WOReferenceForm 
          visible={this.state.woCopyModalVisible}
          onCancel={() => this.setState({ woCopyModalVisible: false })}
          onCreate={wo => {
            this.setState({ woCopyModalVisible: false });
            this.props.history.push(`/workorder/new/${wo.id}`)
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ loggedInUser, work_order }) => {
  return {
    ...work_order,
    loggedInUser
  };
}

const mapDispatchToProps = {
  getWorkOrders
}

export default connect(mapStateToProps, mapDispatchToProps)(List);