import React, { Component } from 'react';
import { Divider } from 'antd';
import accounting from 'accounting';
import { isEqual } from 'lodash';
import EditableTable from 'shared/components/EditableTable';
import DivTotal from 'shared/components/DivTotal';

export default class extends Component {
  state = {};

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.loadData();
    }
  }

  loadData = () => {
    const { value: { data, total, utilities_total }, readOnly } = this.props;
    if (readOnly) {
      this.setState({ value: { data, total, utilities_total } });
      return;
    }
    const newData = data.map((datum, index) => {
      return {
        ...datum,
        key: index,
      };
    });
    const newTotal = newData.reduce((a, b) => {
      const first = typeof a === 'number' ? a : parseFloat(a.amount, 10)
      const second = typeof b === 'number' ? b : parseFloat(b.amount, 10)
      return first + second
    }, 0);
    const newUtilitiesTotal = newTotal * 0.28;
    this.setState({ value: { data: newData, total: newTotal, utilities_total: newUtilitiesTotal } });
  }

  columns = [
    {
      title: 'Work Type',
      dataIndex: 'work_type',
      width: '35%',
      editable: !this.props.readOnly,
      required: true,
    }, {
      title: 'Qty',
      dataIndex: 'quantity',
      width: '15%',
      inputType: 'number',
      editable: !this.props.readOnly,
      required: true,
    }, {
      title: 'Unit',
      dataIndex: 'unit',
      inputType: 'unit',
      width: '15%',
      editable: !this.props.readOnly,
      required: true,
    }, {
      title: 'Total Amount',
      dataIndex: 'amount',
      inputType: 'float',
      width: '25%',
      editable: !this.props.readOnly,
      required: true,
      render: (text, record, index) => <span style={{ fontWeight: 'bold' }}>{accounting.formatMoney(text, '₱', 2, ',', '.')}</span>,
    }
  ];

  beforeRowSave = (row) => {
    
  }

  getRate = (values) => {
    const L = values.length || 0;
    const W = values.width || 0;
    const area = L * W;
    if (area > 150) {
      return 407.59;
    } else {
      return 203.80;
    }
  }

  instantiateItem = (item) => {
    item.rate = this.state.rate;
  }

  onChange = (value) => {
    const total = value.reduce((a, b) => {
      const first = typeof a === 'number' ? a : parseFloat(a.amount, 10)
      const second = typeof b === 'number' ? b : parseFloat(b.amount, 10)
      return first + second
    }, 0);
    const utilities_total = total * 0.28;
    // this.setState({ 
    //   value: { data: value, total, utilities_total }
    // });

    if (this.props.onChange) {
      this.props.onChange({ data: value, total, utilities_total });
    }
  }

  render() {
    const { value } = this.state;
    if (!value) {
      return null;
    }
    const { data, total, utilities_total } = value;
    return (
      <div style={{ margin: '20px' }}>
        <EditableTable
          value={data}
          columns={this.columns}
          beforeRowSave={this.beforeRowSave}
          onChange={this.onChange}
        />
        <DivTotal value={total} />
        <Divider />
        <DivTotal title='Total Utilities of Photo-Litography' value={utilities_total} />
      </div>
    );
  }
}