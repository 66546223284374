import React, { Component } from 'react';
import { Button, Input, Form } from 'antd';

const FormItem = Form.Item;

export const contactValidator = (rule, value, callback) => {
  if (!value || value.length <= 0) {
    callback('Required field. Atleast 1');
    return;
  }

  let error = false;
  value.map(val => {
    if (!!val.name && !val.email && !val.contact_number) {
      callback('Contact persons need an email or contact number');
      error = true;
    }
    if (!!val.email && !(/\S+@\S+\.\S+/).test(val.email)) {
      callback('Invalid email format');
      error = true;
    }
    return null;
  });

  if (error) {
    return;
  }

  callback();
}

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || [ { name: '', email: '', contact_number: '' } ]
    }
	}

  componentDidUpdate(prevProps, prevState) {
		if (this.props.value === prevProps.value) {
			return;
		}
		this.setState({ value: this.props.value || [ { name: '', email: '', contact_number: '' } ]})
  }

  addMoreItems = () => {
    const value = this.state.value;
    value.push({
      name: '', email: '', contact_number: ''
    });

    const onChange = this.props.onChange;
    if (onChange) {
      onChange(value);
    }

    this.setState({ value });
  }

  onInputChange = (key, val, index) => {
    const value = this.state.value;
    value[index][key] = val;

    const onChange = this.props.onChange;
    if (onChange) {
      onChange(value);
    }

    this.setState({ value });
  }

  render() {
    return (
      <div>
        {
          this.state.value.map((val, index) => {
            return (
              <div key={index.toString()}>
                <FormItem
                  label={`Name of Contact Person ${index + 1}`}
                  labelCol={{ sm: {span: 24}, md: {span: 7} }}
                  wrapperCol={{ sm: {span: 24} }}
                  style={{ margin: '0px' }}
                >
                  <Input value={val.name} onChange={e => this.onInputChange('name', e.target.value, index)}/>
                </FormItem>
                <FormItem
                  label='Email Address'
                  labelCol={{ sm: {span: 24}, md: {span: 4} }}
                  wrapperCol={{ sm: {span: 24} }}
                  style={{ margin: '0px' }}
                >
                  <Input value={val.email} onChange={e => this.onInputChange('email', e.target.value, index)}/>
                </FormItem>
                <FormItem
                  label='Contact Number'
                  labelCol={{ sm: {span: 24}, md: {span: 4} }}
                  wrapperCol={{ sm: {span: 24} }}
                  style={{ margin: '0px' }}
                >
                  <Input value={val.contact_number} onChange={e => this.onInputChange('contact_number', e.target.value, index)}/>
                </FormItem>
              </div>
            );
          })
        }
        <Button 
          ghost 
          type='primary' 
          icon='plus'
          style={{ border:'transparent', padding:'0px'}} 
          onClick={this.addMoreItems}>
          Add more item/s
        </Button> 
      </div>
    );
  }
}