import React, { Component } from 'react';
import { Form, Input, DatePicker, Col, InputNumber, Checkbox } from 'antd';
import { css } from 'emotion';
import { get, has } from 'lodash';
import moment from 'moment'; 

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formColStyle = css({
  margin: '25px',
	display: 'flex', 
	flexDirection: 'column',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
  '& .formcol': {
    display: 'flex',
    flexDirection: 'row',
  },
});

class PublicationFormPage1 extends Component {
  componentDidMount () {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.values && this.props.values) {
      this.loadData();
    }
  }

  loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
    }
    const { setFieldsValue } = form;
    setFieldsValue({
      volume: get(values, 'volume'),
      issue: get(values, 'issue'),
      woe_data: {
        issue_date: has(values, 'woe_data.issue_date') ? moment(get(values, 'woe_data.issue_date')) : undefined,
        note: get(values, 'woe_data.note'),
        quantity: get(values, 'woe_data.quantity'),
        supplemental_copy: get(values, 'woe_data.supplemental_copy', false),
        unit_price: get(values, 'woe_data.unit_price'),
        property_number: get(values, 'woe_data.property_number')
      },
      po_number: get(values, 'po_number'),
      po_date: has(values, 'po_date') ? moment(get(values, 'po_date')) : undefined,
    });
  }
  
  onContinuePressed = (saveAsDraft) => {
    const { onFormSave, values, moveToNext, form } = this.props;
    form.validateFields((err, val) => {
      if (saveAsDraft) {
        onFormSave({
          ...values,
          ...val,
          woe_data: {
            ...val.woe_data,
            issue_date: val.woe_data.issue_date.format('YYYY-MM-DD HH:mm:ss'),
          },
          po_date: val.po_date.format('YYYY-MM-DD HH:mm:ss'),
          estimate_numbers: [],
          job_title: `Volume ${get(val, 'volume')} Issue ${get(val, 'issue')}`,
          as_draft: true
        });
      } else if (!err) {
        const temp_woe_data = get(values, 'woe_data', {})
        moveToNext({
          ...val,
          woe_data: {
            ...temp_woe_data,
            ...val.woe_data,
            issue_date: val.woe_data.issue_date.format('YYYY-MM-DD HH:mm:ss'),
          },
          po_date: val.po_date.format('YYYY-MM-DD HH:mm:ss'),
          estimate_numbers: [],
          job_title: `Volume ${get(val, 'volume')} Issue ${get(val, 'issue')}`,
        });
      }
    });
	}

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={formColStyle}>
        <div className='form-group-title'>
          Publication Work Order Details
        </div>
        <Col sm={{ span: 24 }} md={{ span: 16 }}>
          <FormItem
            label='Volume Number'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('volume', {
              rules: [{
                required: true, message: 'Required field.',
              }],
            })(
              <Input />
            )}
          </FormItem>
        </Col>
        <div className='formcol'>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <FormItem
              label='Issue Number'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ margin: '10px' }}
            >
              {getFieldDecorator('issue', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                <Input />
              )}
            </FormItem>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <FormItem
              label='Issue Date'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ margin: '10px' }}
            >
              {getFieldDecorator('woe_data.issue_date', {
                rules: [{
                  required: true, message: 'Required field.',
                }],
              })(
                <DatePicker style={{ width: '100%' }} />
              )}
            </FormItem>
          </Col>
        </div>
        <Col sm={{ span: 24 }} md={{ span: 16 }}>
          <FormItem
            label='Purchase Order Number'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('po_number', {
                rules: [{
                    required: true, message: 'Required field.',
                }],
            })(
                <Input />
            )}
          </FormItem>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 16 }}>
          <FormItem
            label='Purchase Order Date'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('po_date', {
                rules: [{
                    required: true, message: 'Required field.',
                }],
            })(
                <DatePicker style={{ width: '100%' }} />
            )}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label='Note'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('woe_data.note', {
              rules: [{
                required: true, message: 'Required field.',
              }],
            })(
              <TextArea rows={3} />
            )}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            label='Quantity'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 8 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('woe_data.quantity', {
              rules: [{
                required: true, message: 'Required field.',
              }, {
                type: 'number', message: 'Invalid input'
              }],
            })(
              <InputNumber style={{ width: '100%' }} />
            )}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            wrapperCol={{ span: 24 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('woe_data.supplemental_copy', {
              rules: [{
                required: true, message: 'Required field.',
              }],
              valuePropName: 'checked',
              initialValue: false,
            })(
              <Checkbox>Supplemental Copy</Checkbox>
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label='Property Number'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('woe_data.property_number', {
                rules: [{
                  required: false, message: 'Required field.',
                }],
            })(
              <InputNumber
                style={{ width: '100%' }} 
                min={0}
                onFocus={e => e.target.select()} 
              />
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label='Unit Price'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('woe_data.unit_price', {
              rules: [{
                  required: false, message: 'Required field.',
              }],
            })(
              <InputNumber
                style={{ width: '100%' }} 
                step={0.01} 
                min={0.00}
                onFocus={e => e.target.select()} 
              />
            )}
          </FormItem>
        </Col>
      </div>
    );
  }
}

export default Form.create()(PublicationFormPage1);