import React, { Component } from 'react';
import { Form, Row, Col, DatePicker } from 'antd';
import WorkOrderSearch from '../components/WorkOrderSearch';
import CostEstimateBreakdown from '../components/CostEstimateBreakdown';
import ActualWorkTabs from '../components/ActualWorkTabs';
import ProductionCostSummary from '../components/ProductionCostSummary';
import { css } from 'emotion';
import { get, mapValues } from 'lodash';
import moment from 'moment';

const FormItem = Form.Item;

function getTotalAmount (divisions) {
  let total = 0;
  mapValues(divisions, division => {
    total += get(division, 'total', 0);
  });
  return total;
}

const container = css({
	height: '100%',
	overflowY: 'auto',
	padding: '10px',
	'& .top': {
		width: '100%',
		display: 'flex',
		flexDirection: 'row', 
		background: '#FFFFFF', 
		padding: '10px',
		boxShadow: '1px 0px 3px grey',
		'& .details-container': {
			flex: 1,
		},
		'& .summary-container': {
			flex: 1,
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'flex-end',
			padding: '10px'
		}
	},
	'& .bottom': {
		margin: '10px 0px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		'@media (min-width: 992px)': {
			flexDirection: 'row',
    }, 
		'& .estimates-breakdown': {
			width: '100%',
			margin: '0px 5px 0px 0px',
			'@media (min-width: 992px)': {
				width: '30%',
			}, 
		},
		'& .actual-work': {
			width: '100%',
			margin: '0px 0px 0px 5px',
			'@media (min-width: 992px)': {
				width: '70%',
			}, 
		}
	},
	'& .ant-tabs': {
    margin: '15px',
    width: '100%',
  },
  '& .ant-tabs-nav-scroll': {
    display: 'flex',
    justifyContent: 'center'
  },
  '& .ant-form-item-label': {
    textAlign: 'left',
    color: '#646970',
    fontSize: '15px',
    '& label': {
      color: '#646970',
      fontSize: '15px',
    }
  }
});

class CreateUpdateForm extends Component {
	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
		if ((!prevProps.pc && this.props.pc) ||
			(get(prevProps, 'pc.id', -1) !== get(this.props, 'pc.id', -1))) {
				this.loadData();
			}
	}

	loadData = () => {
		const { pc, form } = this.props;
		if (!pc) {
			return;
		}
		const { setFieldsValue } = form;
		setFieldsValue({
			work_order: pc.work_order,
			pc_date: moment(pc.pc_date),
		})
	}

	save = (release) => {
		const { form, onFormSave, pc } = this.props;
		form.validateFields((err, val) => {
			if (!err) {
				onFormSave({
					id: get(pc, 'id'),
					work_order_id: val.work_order.id,
					pc_date: val.pc_date.format('YYYY-MM-DD HH:mm:ss'),
					actual_cost: getTotalAmount(val.pc_data.division),
					pc_data: {
						division: val.pc_data.division
					},
					release
				})
			}
		});
	}

  render() {
    const { form, pc, mode } = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		const work_order = getFieldValue('work_order');
		return (
			<div className={container}>
				<div className='top'>
					<div className='details-container'>
						<Row gutter={8}>
							<Col xs={{ span: 24 }} md={{ span: 12 }}>
								<FormItem
									label='Work Order Number'
									labelCol={{ span: 24 }}
									wrapperCol={{ span: 24 }}
									style={{ margin: '10px' }}
								>
									{
										getFieldDecorator('work_order', {
											rules: [{
												required: true, message: 'Required field.'
											}]
										})(
											<WorkOrderSearch disabled={mode === 'view'} />
										)
									}
								</FormItem>
							</Col>
							<Col xs={{ span: 24 }} md={{ span: 8 }}>
								<FormItem
									label='Production Cost Date'
									labelCol={{ span: 24 }}
									wrapperCol={{ span: 24 }}
									style={{ margin: '10px' }}
								>
									{
										getFieldDecorator('pc_date', {
											rules: [
												{ required: true, message: 'Required field.' }
											],
										})(
											<DatePicker disabled={mode === 'view'} />
										)
									}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={8} >
							<Col xs={{ span: 24 }} >
								<FormItem
									label='Job Title'
									labelCol={{ span: 24 }}
									wrapperCol={{ span: 24 }}
									style={{ margin: '10px' }}
								>
									<div style={{ fontSize: '17px', fontWeight: '700' }}>
										{get(work_order, 'job_title', '')}
									</div>
								</FormItem>
							</Col>
						</Row>
					</div>
					<div className='summary-container'>
						<ProductionCostSummary
							work_order={work_order}
							form={form}
						/>
					</div>
				</div>
				<div className='bottom'>
					<div className='estimates-breakdown'>
						<CostEstimateBreakdown
							work_order={work_order}
						/>
					</div>
					<div className='actual-work'>
						<ActualWorkTabs
							form={form}
							work_order={work_order}
							values={pc}
							readOnly={mode === 'view'}
						/>
					</div>
				</div>
			</div>
		);
  }
}

export default Form.create()(CreateUpdateForm);