import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Button, Spin, notification } from 'antd';
import { get } from 'lodash';
import { css } from 'emotion';
import Header from 'shared/components/Header';
import EstimateView from './EstimateView';
import { getEstimate } from '../actionCreators';
import { getEstimateIdFromUrl } from '../selectors';

const parentStyle = css({
	flex: 1,
	display: 'flex',
	background: '#F6FCFF',
	overflow: 'hidden',
	'& .loading': {
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center', 
		position: 'absolute', 
		top: '0', 
		bottom: '0', 
		left: '0', 
		right: '0', 
		backgroundColor: '#26272955' 
	},
	'& .container': {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	}
});

function disableView (props) {
	const permissions = get(props, 'loggedInUser.user.permissions.ppcd');
	if (!Array.isArray(permissions)) {
			return true;
	}
	return !permissions.includes('ppcd_view_estimate');
}

class View extends Component {
	componentDidMount () {
		if (disableView(this.props)) {
			notification.warning({
				message: 'Uh Oh!',
				description: 'You do not have permission to view this module.',
			});
			this.props.history.replace('/estimate');
			return;
		}
		const currentDataId = get(this.props, 'estimate.id', -1);
		if (this.props.estimateId && currentDataId !== this.props.estimateId) {
			this.props.getEstimate(
				this.props.estimateId, 
				undefined, 
				this.getEstimateError);
		}
	} 

	getEstimateError = (error) => {
		notification.error({
			message: 'Uh Oh!',
			description: error.message,
		});
	}

	onClose = () => {
		this.props.history.replace('/estimate');
	}

	onEdit = () => {
		const id = get(this.props, 'estimate.id');
		if (id) {
			this.props.history.replace(`/estimate/edit/${id}`);
		}
	}

	render() {
		const { loading, estimate } = this.props;
		return (
			<div className={parentStyle}>
				<div className='container'>
					<Header 
						title={`Cost Estimate`} 
						containerStyle={{ background: '#FFFFFF', padding: '15px 20px', boxShadow: '10px 0px 5px grey' }} 
						titleStyle={{ color: '#2B3D44', fontWeight: '500' }}
					>
						{
							(!!estimate && !estimate.ql_number) && 
							<Button style={{ margin: '0px 10px' }} size='large' onClick={this.onEdit}>
								Edit
							</Button>
						}
						<Icon onClick={this.onClose} type='close' style={{ fontSize: '2em', color: '#797979' }} />
					</Header>
					{
						estimate && !disableView(this.props) &&
						<EstimateView 
							estimate={estimate} 
							onEdit={this.onEdit}
						/>
					}
				</div>
				{
					loading && 
						<div className='loading'>
							<Spin size='large' />
						</div>
				}
			</div>
		);
	}
}

const mapStateToProps = ({ loggedInUser , estimate }, ownProps) => {
	return {
		estimateId: getEstimateIdFromUrl(ownProps),
		estimate: estimate.estimate,
		loggedInUser
	};
}

const mapDispatchToProps = {
	getEstimate 
}


export default connect(mapStateToProps, mapDispatchToProps)(View);