import React, { Component } from 'react';
import accounting from 'accounting';

export default class extends Component {
    render() {
        const total = this.props.value || 0;
        const styleProp = this.props.style || {}
        const textStyle = this.props.textStyle || {}
        const title = this.props.title || 'TOTAL'
        return (
            <div style={Object.assign({ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '15px 0px' }, styleProp)}>
                { !this.props.hideTotal && <div style={{ fontWeight: 'bold', margin: '0px 15px 0px 0px' }}>{title}:</div> }
                <div style={Object.assign({ fontWeight: 'bold' }, textStyle)}>{accounting.formatMoney(total, '₱', 2, ',', '.')}</div>
            </div>
        );
    }
}