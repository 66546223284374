import React, { Component } from 'react';
import { css } from 'emotion';
import EstimateDetailsView from '../components/EstimateDetailsView';
import EstimateBreakdownView from '../components/EstimateBreakdonView';

const containerStyle = css({ 
  flex: '1 0 0%',
  display: 'flex',
  flexDirection: 'column',
  padding: '25px',
  overflow: 'scroll',
});

export default class extends Component {
  render() {
    const { estimate } = this.props;
    if (!estimate) {
      return null;
    }
    return (
      <div className={containerStyle}>
        <EstimateDetailsView estimate={estimate} />
        {
        <EstimateBreakdownView estimate={estimate} />
        }
      </div>
    );
  }
}