import React, { Component } from 'react';
import { Tabs, Progress, Button, Steps } from 'antd'; 
import { css } from 'emotion';
import { get, merge } from 'lodash';
import FormPage1 from './PublicationFormDetailsPage.1';
import FormPage3 from './PublicationFormSummaryPage.1';

const TabPane = Tabs.TabPane;
const Step = Steps.Step;

const parentContainer = css({
  flex: '1 1 100%',
  display: 'flex',
  overflowY: 'auto',
  overflowX: 'auto', 
  '& .tab-container': {
    flex: 1,
    display: 'flex', 
    flexDirection: 'row',
		margin: '10px',
    '& .navigation': {
      flex: '2 0 0px', 
      boxShadow: '1px 1px 5px #999999AA', 
      margin: '5px', 
      padding: '30px 10px',
      background: '#FFFFFF'
    },
    '& .form-parent': {
      flex: '8 0 0px', 
      alignSelf: 'flex-start',
      boxShadow: '1px 1px 5px #999999AA', 
      margin: '5px',
      background: '#FFFFFF',
      overflow: 'hidden',
      maxWidth: '831px'
    }
  }
});

const footer = css({
  background: '#FFFFFF',
  padding: '15px',
  '& .button-container': {
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center',
    '& > button': {
      margin: '5px 10px'
    }
  }
});

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 1,
      values: props.estimate || undefined,
    }
    this.componentRef = {};
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.estimate && this.props.estimate) {
      this.setState({ values: this.props.estimate });
    }
  }

  saveNewValues = (values) => {
    let stateVal = get(this.state, 'values', {});
    
    if (values) {
      merge(stateVal, values);
    }

    return stateVal;
  }

  moveToNext = (values) => {
    let currentPage = this.state.activeKey;
    if (currentPage < 2) {
      currentPage++;
    }
    
    const newValues = this.saveNewValues(values);
    this.setState({ activeKey: currentPage, values: newValues });
  }

  moveToPrevious = () => {
    let currentPage = this.state.activeKey;
    if (currentPage <= 1) {
      return false;
    }
    currentPage--;
    this.setState({ activeKey: currentPage });
    return true;
  }

  onContinuePressed = () => {
    const activeComponent = get(this.componentRef, this.state.activeKey, undefined);
    if (activeComponent && activeComponent.onContinuePressed) {
      activeComponent.onContinuePressed();
    }
  }

  saveComponentRef = (component, id) => {
    this.componentRef[id] = component;
  }

  render() {
    const { values } = this.state;
    const { onFormSave } = this.props;
    const { activeKey } = this.state;
    return (
      <React.Fragment>
        <div className={parentContainer}>
          <div className='tab-container'>
            <div className='navigation'>
              <Steps direction='vertical' current={activeKey - 1}>
                <Step title={`Publication Form Estimate Reference`} />
                <Step title={`Summary`} />
              </Steps>
            </div>
            <div className='form-parent'>
              <Tabs 
                renderTabBar={()=><div></div>} 
                activeKey={this.state.activeKey.toString()}
              >
                <TabPane key="1">
                  <FormPage1 
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 1)}
                    moveToNext={this.moveToNext}
                    values={values}
                  />
                </TabPane>
                <TabPane key="2" >
                  <FormPage3 
                    ref={ref => this.saveComponentRef(ref, 2)}
                    onFormSave={onFormSave}
                    values={values}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <Progress
          showInfo={false} 
          percent={(activeKey/2*100)} 
        />
        <div className={footer}>
          <div className='button-container'>
            <Button onClick={this.moveToPrevious}>
              Back
            </Button>
            <Button type='primary' onClick={this.onContinuePressed}>
              { 
                this.state.activeKey === 2 ? 'Save' : 'Continue'
              }
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}