import React from 'react';
import { Spin } from 'antd';

const Header = ({ title, titleComponent, children, containerStyle, titleStyle, loading }) => { 
    return (
        <div style={Object.assign({}, styles.container, containerStyle ? containerStyle : {})}>
            {
                !!title && <div style={Object.assign({}, styles.title_text, titleStyle ? titleStyle : {})}>{title}</div>
            }
            {
                !!titleComponent && <div style={styles.title_component}>{titleComponent}</div> // USAGE: <Header titleComponent={<SomeComponent {...props} />} />
            }
            <div style={styles.button_container}>
                {
                    loading 
                    ? <Spin size="large" />
                    : Array.isArray(children)
                    ? children.map((child, index) => !!child && <div key={index.toString()} style={styles.child}>{child}</div>)
                    : <div style={styles.child}>{children}</div>
                }
            </div>
        </div>
    )
};

export default Header;

const styles = {
    container: { 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title_text: {
        marginBottom: '0px',
        fontSize: '1.5em',
    },
    title_component: {
        minWidth: '80px',
    },
    button_container: {
        display: 'flex',
        alignItems: 'center'
    },
    child: {
       margin: '10px'
    },
};