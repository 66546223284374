import React, { Component } from 'react';
import { Table } from 'antd';
import { css } from 'emotion';
import { get, head } from 'lodash';
import accounting from 'accounting';

function getCostPerUnit (estimate, total_amount, value_address) {
  let val = get(estimate, value_address, '1');
  if (typeof val === 'number') {
    if (val === 0) val = 1;
    return parseFloat(total_amount / val);
  } else {
    let denom = parseFloat(val.replace(/,/g, ''));
    if (denom === 0) denom = 1;
    return parseFloat(total_amount / denom);
  }
}

const table = css({
  margin: '15px 0px',
  '& .title': {
    color: '#ACB0B3',
    fontSize: '0.9em'
  },
  '& .ant-table-thead': {
    backgroundColor: '#DEF4FE'
  }
});

export default class extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedRowKeys: props.value
    }
  }

  columns = [
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Cost Per Unit',
      dataIndex: 'cost_per_unit',
      key: 'cost_per_unit',
      render: (value, row) => `${accounting.formatMoney(value, '₱', 2, ',', '.')} / ${row.unit}`
    }
  ];

  onSelectedRowChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
    if (this.props.onChange) {
      const selected = head(selectedRows);
      this.props.onChange({
        value: selectedRowKeys,
        cost_per_unit: 
          selected 
            ? `${accounting.formatMoney(selected.cost_per_unit, '', 2, ',', '.')} / ${selected.unit}`
            : ''
      });
    }
  }

  render() {
    const { estimate, total_amount } = this.props;
    const dataSource = [
      { 
        quantity: `${get(estimate, 'data.quantity.value1')} ${get(estimate, 'data.quantity.unit1')}`,
        cost_per_unit: getCostPerUnit(estimate, total_amount, 'data.quantity.value1'), // parseFloat(total_amount) / parseFloat(get(estimate, , '1').replace(/,/g, '')),
        unit: get(estimate, 'data.quantity.unit1'),
        key: '1'
      },
      { 
        quantity: `${get(estimate, 'data.quantity.value2')} ${get(estimate, 'data.quantity.unit2')}`,
        cost_per_unit: getCostPerUnit(estimate, total_amount, 'data.quantity.value2'), //parseFloat(total_amount) / parseFloat(get(estimate, 'data.quantity.value2', '1').replace(/,/g, '')),
        unit: get(estimate, 'data.quantity.unit2'),
        key: '2'
      },
      { 
        quantity: `${get(estimate, 'data.quantity.value3')} ${get(estimate, 'data.quantity.unit3')}`,
        cost_per_unit: getCostPerUnit(estimate, total_amount, 'data.quantity.value3'), // parseFloat(total_amount) / parseFloat(get(estimate, 'data.quantity.value3', '1').replace(/,/g, '')),
        unit: get(estimate, 'data.quantity.unit3'),
        key: '3'
      },
    ]
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectedRowChange,
      type: 'radio'
    }

    return (
      <div className={table}>
        <Table 
          dataSource={dataSource}
          columns={this.columns}
          pagination={false}
          rowSelection={rowSelection}
        />
      </div>
    );
  }
}