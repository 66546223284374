import { TYPES } from '../actionCreators';

export default (state = null, action) => {
	switch(action.type) {
		case TYPES.GET:
			return action.payload;
		case TYPES.UPDATE:
		case TYPES.CREATE:
			const { estimate } = action.payload;
			return estimate;
		case TYPES.QUOTATION:
			const { quotation } = action.payload;
			return quotation;
		default:
			return state;
	}
}