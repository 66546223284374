import { combineReducers } from 'redux';

import loading from './loadingReducer';
import data from './dataReducer';
import meta from './metaReducer';
import error from './errorReducer';
import productionCostReducer from './productionCostReducer';

export default combineReducers({
  loading, data, error, meta,
  production_cost: productionCostReducer
});