import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Spin, Icon, notification } from 'antd';
import { get } from 'lodash';
import CreateQLForm from './CreateQLForm';
import { createQuotationLetter, downloadQuotation, getEstimate } from '../actionCreators';
import { getEstimateIdFromUrl } from '../selectors';
import Header from 'shared/components/Header';
import { css } from 'emotion';

const drawerStyle = css({
	'& .drawer-content': {
		display: 'flex', 
		height: '100%', 
		overflow: 'hidden'
	},
	'& .ant-drawer-body': {
		height: '100%',
		background: '#FFF',
		padding: 0,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column'
	},
	'& .form-container': {
		flex: 1,
		overflow: 'auto',
		padding: '25px',
	},
	'& .footer': { 
		backgroundColor: '#FFF', 
		width: '100%', 
		borderTop: '1px solid #e8e8e8', 
		padding: '20px', 
		textAlign: 'left',
	},
	'& .loading': {
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center', 
		position: 'absolute', 
		top: '0', 
		bottom: '0', 
		left: '0', 
		right: '0', 
		backgroundColor: '#26272955' 
	},
	'& .container': {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	}
});

function disableView (props) {
	const permissions = get(props, 'loggedInUser.user.permissions.ppcd');
	if (!Array.isArray(permissions)) {
			return true;
	}
	return !permissions.includes('ppcd_view_estimate');
}

class CreateUpdate extends Component {
	constructor() {
		super();
		this.state = {
			forceReload: false
		}
	}

	componentDidMount () {
		if (disableView(this.props)) {
			notification.warning({
				message: 'Uh Oh!',
				description: 'You do not have permission to view this module.',
			});
			this.props.history.replace('/estimate');
			return;
		}
		const currentDataId = get(this.props, 'estimate.id', -1);
		if (this.props.estimateId && currentDataId !== this.props.estimateId) {
			this.props.getEstimate(
				this.props.estimateId, 
				undefined, 
				this.errorCallback);
		}
	} 

	handleSubmit = (values) => {
		this.props.createQuotationLetter(
			values, 
			() => { this.setState({ forceReload: true }) }, 
			this.errorCallback
		);
	}
	
	errorCallback = (err) => {
			notification.error({
					message: 'Uh Oh!',
					description: err.message,
			});
	}

	onClose = () => {
		this.props.history.push({
			pathname: '/estimate', 
			state: { reload: this.state.forceReload }
		});
	}

	renderActions = () => {
		const { loading, estimate } = this.props;
		if (!estimate) {
			return null;
		}

		return (
			!estimate.ql_number
			? <Button loading={loading} form="qlForm" key="submit" htmlType="submit" style={{ marginRight: 8 }} type="primary">
				Generate Letter
			</Button>
			: <Button loading={loading} style={{ marginRight: 8 }} type="primary" onClick={() => this.props.downloadQuotation(estimate.id)}>
				Download
			</Button>
		);
	}

	render() {
		const { loading, estimate } = this.props;
		return (
			<Drawer
				width={520}
				placement="right"
				closable={false}
				maskClosable={false}
				destroyOnClose={true}
				visible={true}
				className={drawerStyle}
			>
				<Header 
					title={`Quotation Letter`} 
					containerStyle={{ background: `linear-gradient(to left, #2c83cb, #57aff4)`, padding: '15px 20px', boxShadow: '1px 0px 2px grey' }} 
					titleStyle={{ color: '#FAFBFB', fontWeight: '500' }}
				>
					<Icon onClick={this.onClose} type='close' style={{ fontSize: '1.5em', color: '#FBFAFA' }} />
				</Header>
				<div className='form-container'>
				{
					estimate &&
					<CreateQLForm 
						handleSubmit={this.handleSubmit}
						estimate={estimate}
					/>
				}
				</div>
				<div className='footer'>
					{
						this.renderActions()
					}
					<Button loading={this.props.loading} onClick={this.onClose}>
						Cancel
					</Button>
				</div>
				{
					loading &&  <div className='loading'><Spin size='large' /></div>
				}
			</Drawer>
		);
	}
}

const mapStateToProps = ({ loggedInUser, estimate }, ownProps) => {
	return {
		loading: estimate.loading,
		estimateId: getEstimateIdFromUrl(ownProps),
		estimate: estimate.estimate,
		loggedInUser
	};
}

const mapDispatchToProps = {
	createQuotationLetter, downloadQuotation, getEstimate
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);