import React, { Component } from 'react';
import { Button, DatePicker, Form, Col, Row, notification, Select, Cascader, Input } from 'antd';
import { axios } from 'shared/utils/api_client';
import { publication_types } from '../../../estimate/data';
import { getAuthHeader } from 'shared/utils/api_client';

import PublicationTable from './table';
import { columns } from './table';
import IssuedPdf from './pdf';
import { NavLink } from 'react-router-dom';
import PartnerSearchBar from '../PartnerSearchBar';
import philippines from 'shared/constants/philippines';
import { keys, values, get } from 'lodash';
const FormItem = Form.Item;
const Option = Select.Option;


export default class MyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: []
        }
    }

    loadData = (params, is_download) => {
        this.setState({ loading: true })
        axios.get('/report/ppcd/publication', {
            params: params,
            headers: { ...getAuthHeader() }
        })
            .then((response) => {
                if (response.data.data.length === 0) {
                    notification['error']({ message: 'No match found' });
                }
                if (is_download) {
                    IssuedPdf({ ...params, title: 'Publication Report' }, response.data.data, columns);
                    this.setState({ loading: false });
                }
                else {
                    this.setState({ data: response.data.data, loading: false });
                }
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    componentDidMount() {

    }

    handleClick = () => {
        this.doIt(false);
    }

    download = () => {
        this.doIt(true);
    }

    doIt = (is_download) => {
        this.formRef.props.form.validateFields((err, values) => {
            if (!err) {
                this.loadData({
                    ...values,
                    start_date: values.start_date.format('YYYY-MM-DD'),
                    end_date: values.end_date.format('YYYY-MM-DD')
                }, is_download);
            }
            else {
                notification['error']({ message: 'Please input required values' });
            }
        });
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <Row><h1>Publication Report</h1></Row>
                <Row>
                    <Col span={12}>
                        <WrappedForm
                            wrappedComponentRef={(formRef) => { this.formRef = formRef; }} />
                    </Col>
                    <Col span={12}>
                        <p style={{ textAlign: 'right' }}>
                            <Button ><NavLink to="/report">Back to reports</NavLink> </Button> &nbsp;
                        <Button type="primary" onClick={this.handleClick}>Generate</Button> &nbsp;
                        <Button type="primary" onClick={this.download}>Download</Button>
                        </p>
                    </Col>
                </Row>
                <Row style={{ borderBottom: '1px solid #ccc' }}>
                </Row>
                {
                    this.state.data.length > 0 &&
                    <PublicationTable
                        data={this.state.data}
                        loading={this.state.loading}
                    />
                }
            </div>
        );
    }
}

const WrappedForm = Form.create()(
    class extends Component {
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item >

                                {
                                    getFieldDecorator('start_date', {
                                        rules: [{ required: true, message: 'Start Date' }]
                                    })(
                                        <DatePicker placeholder="Start Date*" />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item >

                                {
                                    getFieldDecorator('end_date', {
                                        rules: [{ required: true, message: 'End Date' }]
                                    })(
                                        <DatePicker placeholder="End Date*" />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <FormItem>
                                {getFieldDecorator('region', {
                                    rules: [{
                                        required: false, message: 'Region.',
                                    }],
                                })(
                                    this.showRegionSelect()
                                )}
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem>
                                {getFieldDecorator('province', {
                                    rules: [{
                                        required: false, message: 'Province.',
                                    }],
                                })(
                                    this.showProvinceSelect()
                                )}
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem>
                                {getFieldDecorator('municipality', {
                                    rules: [{
                                        required: false, message: 'Municipality',
                                    }],
                                })(
                                    this.showMunicipalitySelect()
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item >

                                {
                                    getFieldDecorator('publication_type', {
                                        rules: [{ required: false, message: 'Publication Type' }]
                                    })(
                                        <Cascader style={{ width: '100%' }} options={publication_types} placeholder="Publication Type" />

                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item >

                                {
                                    getFieldDecorator('partner_id', {
                                        rules: [{ required: false, message: 'Partner' }]
                                    })(
                                        <PartnerSearchBar />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item >

                                {
                                    getFieldDecorator('volume', {
                                        rules: [{ required: false, message: 'Volume' }]
                                    })(
                                        <Input placeholder="Volume" />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item >

                                {
                                    getFieldDecorator('issue', {
                                        rules: [{ required: false, message: 'Issue' }]
                                    })(
                                        <Input placeholder="Issue" />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            );
        }
        showRegionSelect = () => {
            const regions = values(philippines);
            return (
                <Select
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Region"
                >
                    {
                        regions.map(region => <Option key={region.region_name} value={region.region_name}>{region.region_name}</Option>)
                    }
                </Select>
            )
        }

        showProvinceSelect = () => {
            const { getFieldValue } = this.props.form;
            const selectedRegion = getFieldValue('region');
            const region = get(philippines, `[${selectedRegion}]`)
            let children = [];
            if (region && region.province_list) {
                children = keys(region.province_list);
            }

            return (
                <Select
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Province"
                >
                    {
                        children.map(province => <Option key={province} value={province}>{province}</Option>)
                    }
                </Select>
            );
        }

        showMunicipalitySelect = () => {
            const { getFieldValue } = this.props.form;
            const selectedRegion = getFieldValue('region');
            const selectedProvince = getFieldValue('province');
            const province = get(philippines, `[${selectedRegion}].province_list[${selectedProvince}]`)
            let children = [];
            if (province && province.municipality_list) {
                children = keys(province.municipality_list);
            }

            return (
                <Select
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    placeholder="Municipality"
                >
                    {
                        children.map(municipality => <Option key={municipality} value={municipality}>{municipality}</Option>)
                    }
                </Select>
            );
        }

    }

);