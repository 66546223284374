export const work_order_types = [
    'Standard',
    'Accountable',
    'Specialized',
    'Publication',
    'Copy Work Order'
];

export const divisions = [
    'Composing',
    'Photo-Litography',
    'Press',
    'Finishing',
    'Sales and Marketing',
];

export const woe_division_filter = [
    { text: 'PPCD', value: 'PPCD'},
    { text: 'COMPOSING', value: 'COMPOSING'},
    { text: 'PHOTOLITOGRAPHY', value: 'PHOTOLITOGRAPHY'},
    { text: 'PRESS', value: 'PRESS'},
    { text: 'FINISHING', value: 'FINISHING'},
    { text: 'SALES', value: 'SALES'},
    { text: 'PRODUCTION_COST', value: 'PRODUCTION_COST'},
];

export const woe_status_filter = [
    { text: 'DRAFT', value: 'DRAFT'},
    { text: 'WAITING', value: 'WAITING'},
    { text: 'OPEN', value: 'OPEN'},
    { text: 'COMPLETED', value: 'COMPLETED'},
    { text: 'READY', value: 'READY'}
]