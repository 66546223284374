import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  Table, 
  Button, 
  Input, 
  Icon, 
  notification, 
  Popover, 
  Checkbox, 
  // Radio 
} from 'antd';
import { Header } from 'shared/components/acc-components';
import { types } from '../data';
import { getPCs } from '../actionCreators';
import { get, isArray } from 'lodash';

// const RadioGroup = Radio.Group;

const { Search } = Input;
const filterTypes = types.map(type => ({ text: type, value: type }));

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideColumns: []
    }
    this.DEFAULT_LIST_PARAMS = {
      take: 20, 
      page: 1, 
      sort_key: 'pc_number', 
      sort_dir: 'descend',
    }
  }

  columns = [{
    title: 'PC Number',
    dataIndex: 'pc_number',
    key: 'pc_number'
  }, {
    title: 'PC Date',
    dataIndex: 'pc_date',
    key: 'pc_date'
  }, {
    title: 'Job Title',
    dataIndex: 'job_title',
    key: 'job_title'
  }, {
    title: 'Cost Estimate/s',
    dataIndex: 'estimate_numbers',
    key: 'ce_numbers',
    render: value => {
      if (!value) {
        return '';
      }
      const estimates = JSON.parse(value);
      if (isArray(estimates)) {
        return estimates.map(ce => <div>{ce}</div>)
      } else {
        return '';
      }
    }
  }, {
    title: 'WOE Number',
    dataIndex: 'woe_number',
    key: 'woe_number'
  }, {
    title:'Request Type',
    dataIndex: 'type',
    key: 'type',
    filters: filterTypes
  }, {
    title:'Client',
    dataIndex: 'partner_name',
    key: 'partner_name'
  }, {
    title:'Status',
    dataIndex: 'pc_status',
    key: 'pc_status',
    filters: [
        { text: 'DRAFT', value: 'DRAFT'},
        { text: 'RELEASED', value: 'RELEASED'}
    ],
    // onFilter: (value, record) => record['status'].indexOf(value) === 0, 
  }, {
    title:'',
    dataIndex: 'actions',
    key: 'actions',
    render: (text, pc) => {
        if (pc.pc_status === 'RELEASED') {
          return null;
        }
        return (
          <div>
            <Button 
              style={{border: 'none', background: 'none'}} 
              icon="edit"
              onClick={(e) => this.onEditPC(e, pc)}
              className='editDeleteButtons'
            />
          </div>
        )
      },
    }
  ];

  componentDidMount() {
    if (this.disableView()) {
      notification.warning({
        message: 'Uh Oh!',
                description: 'You do not have permission to view this module.',
      });
      return;
    }

    this.props.getPCs({
        ...this.DEFAULT_LIST_PARAMS,
    });
  }

  onNewClicked = () => {
    this.props.history.push(`/productioncost/new`)
  }

  onSearch = (value) => {
    this.props.getPCs({
      ...this.DEFAULT_LIST_PARAMS,
      q: value
    });
  }

  onEditPC = (e, pc) => {
    e.stopPropagation();
    this.props.history.push(`/productioncost/edit/${pc.id}`);
  }
  
  disableAdd = () => {
    const permissions = get(this.props, 'loggedInUser.user.permissions.ppcd');
    if (!Array.isArray(permissions)) {
        return true;
    }
    return !permissions.includes('ppcd_add_production');
  }

  disableView = () => {
    const permissions = get(this.props, 'loggedInUser.user.permissions.ppcd');
    if (!Array.isArray(permissions)) {
        return true;
    }
    return !permissions.includes('ppcd_view_production');
  }

  filteredColumns = () => {
    return this.columns.filter(column => column.key === 'actions' || !this.state.hideColumns.includes(column.key))
  }

  toggleColumn = (column) => {
    const columns = [...this.state.hideColumns];
    const index = columns.indexOf(column);
    if (index >= 0) {
      columns.splice(index, 1);
    } else {
      columns.push(column);
    }
    this.setState({ hideColumns: columns })
  }

  columnsPopOver = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          this.columns.filter(column => column.key !== 'actions').map(column => {
            const checked = !this.state.hideColumns.includes(column.key);
            return (
              <div key={column.key}>
                <Checkbox
                  onChange={e => this.toggleColumn(column.key)}
                  checked={checked}
                >
                  { column.title }
                </Checkbox>
              </div>
            );
          })
        }
      </div>
    );
  }
  
  onChangeTable = (pagination, filters, sorter)=> {
    this.props.getPCs({
      ...this.props.meta,
      page: pagination.current,
      take: pagination.pageSize,
      ...filters,
      sort_dir: sorter.order,
      sort_key: sorter.columnKey
    });
  }

  view = (pc) => {
    this.props.history.push(`/productioncost/view/${pc.id}`);
  }

  render() {
    const filteredColumns = this.filteredColumns();
    return (
      <div style={{ margin: '20px 40px' }} >
        <Header 
          loading={this.props.loading}
          titleComponent={
            <Search 
              placeholder='Search productions costs'
              onSearch={value => this.onSearch(value)}
              style={{ width: 400 }} disabled ={this.disableView()}/> }
              
        > 
          <Popover
            title='Configure Columns'
            content={this.columnsPopOver()}
            trigger='click'
          >
            <Button
              shape='circle'
              size='large'
              icon='setting'
            />
          </Popover>
          <Button 
            type='primary' 
            onClick={this.onNewClicked} 
            disabled={this.disableAdd()}
          >
            <Icon type="plus" />New Production Cost
          </Button>
        </Header>
        <Table
          rowKey='id'
          columns={filteredColumns}
          dataSource={this.props.data}
          loading={this.props.loading}
          style={{margin: "10px"}}
          pagination={{ 
            defaultPageSize: 10, 
            showSizeChanger: true, 
            pageSizeOptions: ['10', '20', '50'] , 
            total: get(this.props, 'meta.total', 0)
          }}
          size="middle"
          scroll={{ y: 360 }}
          onChange ={this.onChangeTable}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => this.view(record),  
            };
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({loggedInUser, production_cost }) => {
  return {
    ...production_cost,
    loggedInUser
  };
}

const mapDispatchToProps = {
  getPCs
}

export default connect(mapStateToProps, mapDispatchToProps)(List);