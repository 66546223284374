import React, { Component } from 'react';
import { Form, Col, InputNumber, Divider } from 'antd';
import { get } from 'lodash'
import { FormItemDisplayData, FormItemDisplayDimension } from 'shared/components/acc-components';
import DivTotal from 'shared/components/DivTotal';
import UnitBreakdown from '../components/UnitBreakdown';
import { css } from 'emotion';

const FormItem = Form.Item;

const formParent = css({
  '& .title':{
    color: '#62676A',
    fontWeight: '600',
    fontSize: '1em',
    margin: '5px 0px'
  },
  '& .subtitle':{
    color: '#8C8C8C',
    fontSize: '0.9em',
    margin: '5px 0px'
  },
  '& .ql-form-item': {
    margin: '10px 0p',
    '& .ant-form-item-label': {
      textAlign: 'left',
      lineHeight: '18px',
      '& label': {
          color: '#ACB0B3',
      },
    },
    '& .ant-form-item-control': {
      lineHeight: '20px'
    },
  },
  '& .ql-input-number': {   
    width: '100%',
    '& .ant-input-number-input': {
      textAlign: 'end'
    }
  }
});

class CreateQLForm extends Component {
    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (get(prevProps, 'estimate.id', -1) !== get(this.props, 'estimate.id', -1)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { estimate, form } = this.props;
        const { setFieldsValue } = form;
        setFieldsValue({
            estimate_id: estimate.id,
            total_amount: estimate.total_amount
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleSubmit(values);
            }
        });
    }

    render() {
        const { form, estimate } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form id='qlForm' onSubmit={this.handleSubmit} className={formParent}>
                <div className='title'>
                    { get(estimate, 'job_title') }
                </div>
                <div className='subtitle'>
                    Cost Estimate Number: { get(estimate, 'estimate_number') }
                </div>
                <Divider dashed style={{'backgroundColor':'#C7D3DC'}} />
                <Col span={0}> { /* hidden fields START */}
                    <FormItem>
                        {getFieldDecorator('estimate_id')(
                            <FormItemDisplayData />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('data.cost_per_unit')(
                            <FormItemDisplayData />
                        )}
                    </FormItem>
                </Col> { /* hidden fields END */}
                <FormItem
                    label='Client'
                    labelCol={{ sm: {span: 24} }}
                    wrapperCol={{ sm: {span: 24} }}
                    className='ql-form-item'
                >
                    <FormItemDisplayData value={get(estimate, 'partner_name')} />
                </FormItem>
                <FormItem
                    label='Size'
                    labelCol={{ sm: {span: 24} }}
                    wrapperCol={{ sm: {span: 24} }}
                    className='ql-form-item'
                >
                    <FormItemDisplayDimension value={get(estimate, 'data.size')} />
                </FormItem>
                <FormItem
                    label='Process'
                    labelCol={{ sm: {span: 24} }}
                    wrapperCol={{ sm: {span: 24} }}
                    className='ql-form-item'
                >
                    <FormItemDisplayData value={get(estimate, 'data.process')} />
                </FormItem>
                <FormItem
                    label='Packaging'
                    labelCol={{ sm: {span: 24} }}
                    wrapperCol={{ sm: {span: 24} }}
                    className='ql-form-item'
                >
                    <FormItemDisplayData value={get(estimate, 'data.packaging')} />
                </FormItem>
                <FormItem
                    label='Notes'
                    labelCol={{ sm: {span: 24} }}
                    wrapperCol={{ sm: {span: 24} }}
                    className='ql-form-item'
                >
                    <FormItemDisplayData value={get(estimate, 'estimate_description')} />
                </FormItem>
                <FormItem
                    label='Cost Per Unit'
                    labelCol={{ sm: {span: 24} }}
                    wrapperCol={{ sm: {span: 24} }}
                    className='ql-form-item'
                >
                {
                    !estimate.ql_number ?
                    getFieldDecorator('data.selected_unit', {
                        rules: [
                            { required: true, message: 'Please choose.' }
                        ],
                        getValueFromEvent: e => e.value
                    })(
                        <UnitBreakdown
                            estimate={estimate}
                            total_amount={form.getFieldValue('total_amount') || 0}
                            onChange={value => form.setFieldsValue({ 'data.cost_per_unit': value.cost_per_unit })}
                        /> 
                    )
                    :
                    <FormItemDisplayData value={get(estimate, 'data.cost_per_unit')} />
                }
                </FormItem>
                <FormItem
                    label='Estimate'
                    labelCol={{ sm: {span: 16} }}
                    wrapperCol={{ sm: {span: 8} }}
                    style={{ margin: '0px' }}
                >
                    <DivTotal 
                        style={{ margin: '0px' }} 
                        textStyle={{ fontSize: '1.3em' }} 
                        value={get(estimate, 'total_estimate')}
                        hideTotal 
                    />
                </FormItem>
                {
                // <FormItem
                //     label='Mark Up'
                //     labelCol={{ sm: {span: 16} }}
                //     wrapperCol={{ sm: {span: 8} }}
                //     style={{ margin: '0px' }}
                // >
                //     <div
                //         style={{ 
                //             display: 'flex', 
                //             flexDirection: 'row', 
                //             justifyContent: 'flex-end', 
                //             margin: '0px', 
                //             fontSize: '1em', 
                //             fontWeight: 'bold' }}
                //     >
                //         {get(estimate, 'markup')}%
                //     </div>
                // </FormItem>
                }
                <FormItem
                    label='Total Amount'
                    labelCol={{ sm: {span: 16} }}
                    wrapperCol={{ sm: {span: 8} }}
                    style={{ margin: '0px' }}
                >
                    {getFieldDecorator('total_amount')(
                        !estimate.ql_number ?
                        <InputNumber 
                            className='ql-input-number'
                            step={0.01} 
                            formatter={(value) => value.replace(/^(\d+.?\d{0,2})\d*$/, "$1")}
                            onFocus={e => e.target.select()} 
                        />
                        : <DivTotal style={{ margin: '0px' }} textStyle={{ fontSize: '1.5em' }} hideTotal />
                    )}
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(CreateQLForm)