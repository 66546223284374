import React, { Component } from 'react';
import { Tabs, Progress, Button, Steps } from 'antd'; 
import { get, mergeWith, isArray } from 'lodash';
import { css } from 'emotion';
import LoadCEReference from './LoadCEReference';
import FormPage2 from './StandardFormPage2';
import FormPage3 from './StandardFormPage3';
import FormPage4 from './StandardFormPage4';
import FormPage5 from './StandardFormPage5';

const { Step } = Steps;

function mergeCustomizer (objValue, srcValue, key, object, source, stack) {
  if (isArray(objValue)) {
    return srcValue;
  }
}

const parentContainer = css({
  flex: '1 1 100%',
  overflow: 'scroll',
  '& .tab-container': {
    flex: 1,
    display: 'flex', 
    flexDirection: 'row',
    margin: '10px',
    overflowX: 'auto',
    '& .navigation': {
      flex: '1', 
      boxShadow: '1px 1px 5px #999999AA', 
      margin: '5px', 
      padding: '30px 10px',
      background: '#FFFFFF'
    },
    '& .form-parent': {
      flex: '4', 
      boxShadow: '1px 1px 5px #999999AA', 
      margin: '5px',
      background: '#FFFFFF',
      overflow: 'hidden',
    }
  }
});

const footer = css({
  background: '#FFFFFF',
  padding: '15px',
  '& .button-container': {
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center',
    '& > button': {
      margin: '5px 10px'
    }
  }
});

const TabPane = Tabs.TabPane;

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 1,
      values: props.work_order ? {
        type: get(this, 'props.work_order.type'),
        estimates: get(this, 'props.work_order.estimates'),
        estimate_numbers: get(this, 'props.work_order.estimates', []).map(estimate => estimate.estimate_number),
        partner_id: get(this, 'props.work_order.estimates[0].data.partner.id', ''),
        job_title: get(this, 'props.work_order.estimates[0].job_title', ''),
        po_number: get(this, 'props.work_order.po_number'),
        po_date: get(this, 'props.work_order.po_date'),
        woe_data: get(this, 'props.work_order.woe_data'),
        cut_stock_data: get(this, 'props.work_order.cut_stock_data'),
      } : undefined
    }
    this.componentRef = {};
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.work_order && this.props.work_order) {
      this.setState({ values: {
        type: get(this, 'props.work_order.type'),
        estimates: get(this, 'props.work_order.estimates'),
        estimate_numbers: get(this, 'props.work_order.estimates', []).map(estimate => estimate.estimate_number),
        partner_id: get(this, 'props.work_order.estimates[0].data.partner.id', ''),
        job_title: get(this, 'props.work_order.estimates[0].job_title', ''),
        po_number: get(this, 'props.work_order.po_number'),
        po_date: get(this, 'props.work_order.po_date'),
        woe_data: get(this, 'props.work_order.woe_data'),
        cut_stock_data: get(this, 'props.work_order.cut_stock_data'),
      }});
    }
  }

  saveNewValues = (values) => {
    const stateVal = get(this.state, 'values', {});
    if (values) {
      mergeWith(stateVal, values, mergeCustomizer);
    }
    return stateVal;
  }

  moveToNext = (values) => {
    let currentPage = this.state.activeKey;
    if (currentPage < 5) {
      currentPage++;
    }
    const newValues = this.saveNewValues(values);
    this.setState({ activeKey: currentPage, values: newValues });
  }

  moveToPrevious = () => {
    let currentPage = this.state.activeKey;
    if (currentPage <= 1) {
      return false;
    }
    currentPage--;
    this.setState({ activeKey: currentPage });
    return true;
  }

  onContinuePressed = (as_draft) => {
    const activeComponent = get(this.componentRef, this.state.activeKey, undefined);
    if (activeComponent && activeComponent.onContinuePressed) {
      activeComponent.onContinuePressed(typeof as_draft === 'boolean' ? as_draft : false);
    }
  }

  saveComponentRef = (component, id) => {
    this.componentRef[id] = component;
  }

  render() {
    const { values } = this.state;
    const { work_order, type, onFormSave } = this.props;
    const { activeKey } = this.state;
    return (
      <React.Fragment>
        <div className={parentContainer}>
          <div className='tab-container'>
            <div className='navigation'>
              <Steps direction='vertical' current={activeKey - 1}>
                <Step title={`Work Order Reference`} />
                <Step title={`Reference Details`} />
                <Step title={`Work Order Details`} />
                <Step title={`Description & Instruction`} />
                <Step title={`Cut Stock`} />
              </Steps>
            </div>
            <div className='form-parent'>
              <Tabs 
                renderTabBar={()=><div></div>} 
                activeKey={this.state.activeKey.toString()}
              >
                <TabPane key="1">
                  <LoadCEReference 
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 1)}
                    moveToNext={this.moveToNext}
                    values={values} 
                    type={type}
                  />
                </TabPane>
                <TabPane key="2" >
                  <FormPage2 
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 2)}
                    moveToNext={this.moveToNext}
                    onFormSave={onFormSave}
                    values={values} 
                    type={type}
                  />
                </TabPane>
                <TabPane key="3" >
                  <FormPage3 
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 3)}
                    moveToNext={this.moveToNext}
                    onFormSave={onFormSave}
                    values={values} 
                    type={type}
                  />
                </TabPane>
                <TabPane key="4" >
                  <FormPage4
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 4)}
                    moveToNext={this.moveToNext}
                    onFormSave={onFormSave}
                    values={values} 
                    type={type}
                  />
                </TabPane>
                <TabPane key="5" >
                  <FormPage5 
                    wrappedComponentRef={ref => this.saveComponentRef(ref, 5)}
                    moveToNext={this.moveToNext}
                    onFormSave={onFormSave}
                    values={values} 
                    type={type}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <Progress
          showInfo={false} 
          percent={(activeKey/5*100)} 
        />
        <div className={footer}>
          <div className='button-container'>
            <Button onClick={this.moveToPrevious}>
              Back
            </Button>
            {
              activeKey !== 1 && get(work_order, 'woe_status', 'DRAFT') === 'DRAFT' &&
              <Button onClick={() => this.onContinuePressed(true)}>
                Save as Draft
              </Button>
            }
            <Button type='primary' onClick={this.onContinuePressed}>
              { 
                this.state.activeKey === 5 ? 'Save' : 'Continue'
              }
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}