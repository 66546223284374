import React, { Component } from 'react';
import { Form } from 'antd';
import { get } from 'lodash';
import { css } from 'emotion';
import EstimateSearch from 'modules/work_order/components/EstimateSearch';

const FormItem = Form.Item;

const container = css({
  display: 'flex', 
  flex: '1 1 0%', 
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '25px', 
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
  '& .form-item': {
    width: '100%',
    '@media (min-width: 720px)': {
      width: '80%'
    },
    '@media (min-width: 992px)': {
      width: '60%'
    },
  }
});

class LoadCEReference extends Component {
  componentDidMount () {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.values && this.props.values) {
      this.loadData();
    }
  }

  loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
    }
    const { setFieldsValue } = form;
    setFieldsValue({
      estimates: values.estimates,
    });
  }

  onContinuePressed = () => {
    const { values, moveToNext, form } = this.props;
    form.validateFields((err, val) => {
      if (!err) {
        moveToNext({
          ...values,
          estimates: val.estimates,
          estimate_numbers: val.estimates.map(estimate => estimate.estimate_number),
          partner_id: get(val, 'estimates[0].data.partner.id', ''),
          job_title: get(val, 'estimates[0].job_title', ''),
          woe_data: {
            deliver_to: get(val, 'estimates[0].id') === get(values, 'estimates[0].id') 
              && !!get(values, 'woe_data.deliver_to', '') 
              ? get(values, 'woe_data.deliver_to', '')
              : get(val, 'estimates[0].data.partner.delivery_address', ''),
            quantity: get(val, 'estimates[0].data.quantity', ''),
          }
        });
      }
    });
	}

  render() {
    const { type, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={container} >
        <div className='form-group-title' >
          Cost Estimate Reference
        </div>
        <div className='form-item'>
          <FormItem
            label='Cost Estimate Reference'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ margin: '10px' }}
          >
            {getFieldDecorator('estimates[0]', {
              rules: [
                { required: true, message: 'Cost Estimate Required' }
              ]
            })(
              <EstimateSearch type={type} />
            )}
          </FormItem>
        </div>
      </div>
    );
  }
}

export default Form.create()(LoadCEReference);