import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import PartialReleases from './PartialReleases';

export function getColumns (isTypeAll = false) {
    if (!isTypeAll) {
        return columns.filter(column => column.dataIndex !== 'type');
    } else {
        return columns;
    }
}

export const columns = [
    {
        title: 'WOE Date',
        dataIndex: 'created_at',
        render: (value) => {
            return moment(value).format('YYYY-MM-DD')
        }
    },
    {
        title: 'WOE Number',
        dataIndex: 'woe_number',
    },
    {
        title: 'Step',
        dataIndex: 'step',
    },
    {
        title: 'Date Received',
        dataIndex: 'received_date',
        render: (value) => {
            if (value == null) {
                return 'Waiting'
            }
            else {
                return value;
            }
        }
    },
    {
        title: 'Date Released',
        dataIndex: 'released_date',
        render: (value) => {
            if (value == null) {
                return 'Waiting'
            }
            else {
                return value;
            }
        }
    },
    {
        title: 'Time Difference',
        dataIndex: 'updated_at',
        render: (value, row) => {
            if (row.released_date === null) {
                return '-';
            }
            var A = moment(row.received_date);
            var B = moment(row.released_date);
            return B.diff(A, 'days') + ' days';
        }
    },        {
        title: 'Type',
        dataIndex: 'type',
    },
    {
        title: 'Client',
        dataIndex: 'partner_name',
    },
    {
        title: 'Job Title',
        dataIndex: 'job_title',
    },
];

class List extends Component {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <Table
                    columns={getColumns(this.props.isTypeAll)}
                    dataSource={this.props.data}
                    loading={this.props.loading}
                    rowKey='released_date'
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50']
                    }}
                    style={{ marginTop: "10px" }}
                    expandedRowRender={record => <PartialReleases work_order_id={record.id} />}
                />
            </div>
        );
    }
}

export default List;