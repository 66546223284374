import React from 'react';
import { 
  Modal,
  Form,
  Input
} from 'antd';

export default Form.create()(
  class extends React.Component {
    render() {
      const { visible, loading, onCancel, onReturn, form } = this.props;
      const { getFieldDecorator, validateFieldsAndScroll } = form;
      return (
        <Modal
          visible={visible}
          confirmLoading={loading}
          title="Confirm Return to PPCD"
          okText="Return"
          onCancel={onCancel}
          onOk={() => 
            validateFieldsAndScroll((error, values) => {
              if (!error) {
                onReturn(values);
              }
            })
          }
        >
          <Form>
            <Form.Item label="Remarks">
              {
                getFieldDecorator('notes', {
                  rules: [{ required: true, message: 'Please input remarks' }],
                })(
                  <Input.TextArea rows={4} />
                )
              }
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);