import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, Form, Table, notification } from 'antd';
import { downloadCutStockPdf } from '../actionCreators';

const FormItem = Form.Item;

class CreateUpdate extends Component {
	handleSubmit = (values) => {
		
	}

	successCallback = () => {
		this.props.successCallback();
		this.props.onClose();
	}
	
	errorCallback = (err) => {
			notification.error({
					message: 'Uh Oh!',
					description: err.message,
			});
  }
  
  columns = [
    {
      title: 'Rolls',
      dataIndex: 'rolls',
      // width: '10%'
    }, {
      title: 'Reams',
      dataIndex: 'reams',
      // width: '10%'
    }, {
      title: 'Sheets',
      dataIndex: 'sheets',
      // width: '10%'
    }, {
      title: 'Kind',
      dataIndex: 'kind',
      // width: '30%'
    }, {
      title: 'Size',
      dataIndex: 'size',
      // width: '10%'
    }, {
      title: 'Weight',
      dataIndex: 'weight',
      // width: '10%'
    }, {
      title: 'Over',
      dataIndex: 'over',
      // width: '10%',
      render: value => value ? 'Yes' : 'No'
    }
  ];

  renderDivisionCS = (division, cs) => {
    if (!cs) {
      return null;
    }
    const tableData = cs.filter(stock => stock.division.localeCompare(division) === 0)
    if (!tableData || tableData.length <= 0) {
      return null;
    }
    return (
      <div style={{ margin: '10px 0px' }}>
        <h4>{division} Division</h4>
        <Table
          columns={this.columns}
          dataSource={tableData}
          loading={this.props.loading}
          pagination={false}
          style={{marginTop: "10px"}}
        />
      </div>
    );
  }

  download = () => {
    this.props.downloadCutStockPdf(this.props.data, this.errorCallback)
  }

	render() {
		return (
			<Drawer
				title={<h1 style={{fontSize: '36px', color: "gray", padding: "10px", fontWeight: "100"}}> Cut Stock </h1>}
				width={720}
				placement="right"
				onClose={this.props.onClose}
				maskClosable={false}
				destroyOnClose={true}
				visible={this.props.visible}
				style={{ height: '100%', paddingBottom: 80 }} //'calc(100% - 82px)'
      >
        <div style={{ height: '100%' }}>
          {
            !!this.props.data &&
            <div style={{ flex: '1', overflowY: 'scroll' }}>
              <FormItem
                label='Client'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div>{this.props.data.type.localeCompare('Publication') === 0 ? 'NPO' : this.props.data.partner_name}</div>
              </FormItem>
              <FormItem
                label='Job Title'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div>{this.props.data.type.localeCompare('Publication') === 0 ? `Volume ${this.props.data.volume} Issue ${this.props.data.issue}` : this.props.data.job_title}</div>
              </FormItem>
              {
                this.renderDivisionCS('Composing', this.props.data.cut_stock_data.data)
              }
              {
                this.renderDivisionCS('Photo-Litography', this.props.data.cut_stock_data.data)
              }
              {
                this.renderDivisionCS('Press', this.props.data.cut_stock_data.data)
              }
              {
                this.renderDivisionCS('Finishing', this.props.data.cut_stock_data.data)
              }
              {
                this.renderDivisionCS('Sales and Marketing', this.props.data.cut_stock_data.data)
              }
            </div>
          }
          <div style={{ alignSelf: 'flex-end', backgroundColor: '#FFF', width: '100%', borderTop: '1px solid #e8e8e8', padding: '20px', textAlign: 'left', }}>
            <Button loading={this.props.loading} onClick={this.download}>
              Download
            </Button>
            <Button loading={this.props.loading} onClick={this.props.onClose}>
              Close
            </Button>
          </div>
        </div>
			</Drawer>
		);
	}
}

const mapStateToProps = ({ work_order }) => {
	return {
		loading: work_order.loading
	};
}

const mapDispatchToProps = {
	downloadCutStockPdf
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);