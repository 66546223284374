import React, { Component } from 'react';
import { Input, Form, Button } from 'antd';
const FormItem = Form.Item;

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: ''
    }
  }

  onChange = (e) => {
    this.setState({searchTerm: e.target.value});
  }

  submit = (e) => {
    e.preventDefault();
    this.props.onSearch(this.state.searchTerm);
  }

  render() {
    return <div>
      <Form layout="inline" onSubmit={this.submit}>
        <FormItem>
          <Input value={this.state.searchTerm} onChange={this.onChange.bind(this)}/>
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit"> Search </Button>
          </FormItem>
      </Form>
    </div>
  }
}

export default SearchBar;