import React, { Component } from 'react';
import { 
  Form, 
  Input, 
  DatePicker, 
  Row, 
  Col, 
  Cascader,
  Divider
} from 'antd';
import { css } from 'emotion';
import PartnerSearch from './PartnerSearchBar';
import FilesUpload from 'shared/components/FilesUpload';
import UnitSearchBox from 'shared/components/UnitSearchBox';
import moment from 'moment';
import { API_FILE_UPLOAD } from 'shared/constants/urls';
import { standard_form_types } from '../data';

const { TextArea } = Input;
const FormItem = Form.Item;

const wrapper = css({
  margin: '10px 15px',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
  '& .formcol': {
    margin: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
})

class StandardFormDetailsPage extends Component {
	componentDidMount() {
    this.loadData();
	}

	componentDidUpdate(prevProps) {
    if ((!prevProps.values && this.props.values) ||
      (prevProps.values && this.props.values && prevProps.values.id !== this.props.values.id)) {
      this.loadData();
    }
	}

	loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
    }
    const { setFieldsValue } = form;
    setFieldsValue({
        rfq_number: values.rfq_number,
        request_date: moment(values.request_date),
        estimate_description: values.estimate_description,
        data: {
          form_requested: values.data.form_requested,
          partner: values.data.partner,
          documents: values.data.documents,
          quantity: values.data.quantity,
          serial_number: values.data.serial_number
        },
    });
	}
    
  onContinuePressed = () => {
    const { moveToNext, type, form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        moveToNext({
          ...values,
          request_date: values.request_date.format('YYYY-MM-DD HH:mm:ss'),
          partner_id: values.data.partner.id,
          job_title: type === 'Specialized' ? values.data.form_requested : values.data.form_requested.join(' ')
        });
      }
    });
  }

  render() {
    const { type, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={wrapper}>
        <div className='form-group-title' >
          { type } Form Estimate Reference
        </div>
        <div className='formcol' >
          <div>
            <Col sm={{ span: 24 }} md={{ span: 16 }}>
              <FormItem
                label='Request for Quotation Number'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('rfq_number', {
                  rules: [{
                    required: true, message: 'Required field.',
                  }],
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 8 }}>
              <FormItem
                label='Request Date'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('request_date', {
                  rules: [{
                    required: true, message: 'Required field.',
                  }],
                })(
                  <DatePicker style={{ width: '100%' }} />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 24 }}>
              <FormItem
                label='Attach Document'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('data.documents', {
                  rules: [{
                    required: true, message: 'Required field.',
                  }],
                })(
                  <FilesUpload 
                    upload_url={API_FILE_UPLOAD}
                  />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 24 }}>
              <FormItem
                label='Client'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('data.partner', {
                  rules: [{
                    required: true, message: 'Required field.',
                  }],
                })(
                  <PartnerSearch />
                )}
              </FormItem>
            </Col>
          </div>
        </div>
        <Divider dashed />
        <div className='form-group-title' >
          Form Details
        </div>
        <div className='formcol'>
          <div>
            <Row gutter={8}>
              {
                type === 'Specialized' &&
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <FormItem
                    label='Job Title / Form Requested'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ margin: '10px' }}
                  >
                    {getFieldDecorator('data.form_requested', {
                      rules: [{
                        required: true, message: 'Required field.',
                      }],
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              }
              {
                (type === 'Standard' || type === 'Accountable') &&
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <FormItem
                    label='Job Title / Form Requested'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ margin: '10px' }}
                  >
                    {getFieldDecorator('data.form_requested', {
                      rules: [{
                        required: true, message: 'Required field.',
                      }]
                    })(
                      <Cascader style={{ width: '100%' }} options={standard_form_types} />
                    )}
                  </FormItem>
                </Col>
              }
            </Row>
            <FormItem
                required
                label='Quantity'
                labelCol={{ span: 24 }}
                style={{ margin: '10px' }}
            />
            <Row gutter={8}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormItem
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.quantity.value1', {
                    rules: [{
                      required: true, 
                      pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
                      message: 'Invalid format.',
                    }],
                    initialValue: 0
                  })(
                    <Input style={{ width: '100%' }} onFocus={event => event.target.select()} />
                  )}
                </FormItem>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormItem
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.quantity.unit1', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }],
                  })(
                    <UnitSearchBox />
                  )}
                </FormItem>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormItem
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.quantity.value2', {
                    rules: [{
                      required: true, 
                      pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
                      message: 'Invalid format.',
                    }],
                    initialValue: 0
                  })(
                    <Input style={{ width: '100%' }} onFocus={event => event.target.select()} />
                  )}
                </FormItem>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormItem
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.quantity.unit2', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }],
                  })(
                    <UnitSearchBox />
                  )}
                </FormItem>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormItem
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.quantity.value3', {
                    rules: [{
                      required: true,
                      pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
                      message: 'Invalid format.',
                    }],
                    initialValue: 0
                  })(
                    <Input style={{ width: '100%' }} onFocus={event => event.target.select()} />
                  )}
                </FormItem>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormItem
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: '10px' }}
                >
                  {getFieldDecorator('data.quantity.unit3', {
                    rules: [{
                      required: true, message: 'Required field.',
                    }],
                  })(
                    <UnitSearchBox />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }}>
              <FormItem
                label='Serial Number'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('data.serial_number', {
                  rules: [{
                    required: true, message: 'Required field.',
                  }],
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 24 }}>
              <FormItem
                label='Notes'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: '10px' }}
              >
                {getFieldDecorator('estimate_description', {
                  rules: [{
                    required: true, message: 'Required field.',
                  }],
                })(
                  <TextArea rows={3} />
                )}
              </FormItem>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(StandardFormDetailsPage);