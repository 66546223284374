import React, { Component } from 'react';
import { css } from 'emotion';
import { get, mapValues } from 'lodash';
import accounting from 'accounting';

const container = css({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '520px',
  '& .title': {
    fontSize: '24px',
    fontWeight: 'bold', 
    color: '#2B3D44',
    textAlign: 'center',
    margin: '10px',
  },
  '& .item': {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    margin: '5px',
    '& .label': {
      flex: '1',
      color: '#9AA0A5', 
      fontSize: '1em',
      textAlign: 'end',
      '& .label-bold': {
        fontWeight: 'bold'
      }
    },
    '& .value': {
      flex: '1',
      color: '#646970', 
      fontSize: '1.2em',
      textAlign: 'end', 
      '& .value-bold': {
        fontWeight: 'bold'
      }
    },
  }
});

function getTotalAmount (divisions) {
  let total = 0;
  mapValues(divisions, division => {
    total += get(division, 'total', 0);
  });
  return total;
}

function getTotalUtilities (divisions) {
  let total = 0;
  mapValues(divisions, division => {
    total += get(division, 'utilities_total', 0);
  });
  return total;
}

function getTotalEarnings (estimate_total, actual_total, total_utilities) {
  return estimate_total - actual_total;//(actual_total + total_utilities);
}

export default class ProductionCostSummary extends Component {
  render() {
    const { work_order, form } = this.props;
    if (!work_order) {
      return null;
    }
    const { getFieldsValue } = form;
    const divisions = get(getFieldsValue(), 'pc_data.division', {});
    const totalAmount = getTotalAmount(divisions);
    const totalUtilities = getTotalUtilities(divisions);
    const totalEstimate = get(work_order, 'estimates[0].total_estimate', 0);
    const totalEarnings = getTotalEarnings(totalEstimate, totalAmount, totalUtilities);
    return (
      <div className={container}>
        <div className='title'>
          Production Cost Summary
        </div>
        <div className='item'>
          <div className='label'>
            Cost Estimate Total:
          </div>
          <div className='value'>
            {accounting.formatMoney(totalEstimate, '₱', 2, ',', '.')}
          </div>
        </div>
        <div className='item'>
          <div className='label'>
            Actual Cost:
          </div>
          <div className='value'>
            {accounting.formatMoney(totalAmount, '₱', 2, ',', '.')}
          </div>
        </div>
        <div className='item'>
          <div className='label'>
            Total Utilities:
          </div>
          <div className='value'>
            {accounting.formatMoney(totalUtilities, '₱', 2, ',', '.')}
          </div>
        </div>
        <div className='item'>
          <div className='label'>
            <span className='label-bold'>
              Total Earnings:
            </span>
          </div>
          <div className='value'>
            <span className='value-bold'>
              {accounting.formatMoney(totalEarnings, '₱', 2, ',', '.')}
            </span>
          </div>
        </div>
      </div>
    );
  }
}