import axios from 'axios';
import BASE_URL from 'shared/constants/base_url';

const TIMEOUT = 10000;
const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT
});

export const getAuthHeader = function() {
  return { Authorization: 'Bearer ' + sessionStorage.getItem('token') };
}

const API_CLIENT = {
  // getFileUploadUrl: function(case_id) {
  //   return BASE_URL + `/case/${case_id}/file`;
  // },
  login: function(payload) {
    return instance.post('/login', payload);
  },
}


export { instance as axios };


export default API_CLIENT;