import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './containers/List';
import Create from './containers/Create';

class Partners extends Component {
    render() {
		return (
			<Switch>
				<Route exact path="/productioncost/new" component={Create} />
				<Route path="/productioncost/new/:id" component={Create} />
				<Route path="/productioncost/edit/:id" component={Create} />
				<Route path="/productioncost/view/:id" component={Create} />
				<Route exact path="/productioncost" component={List} />
			</Switch>
		);
  }
}

export default Partners;