import React, { Component } from 'react';
import accounting from 'accounting';
import { isEqual } from 'lodash';
import EditableTable from 'shared/components/EditableTable';
import DivTotal from 'shared/components/DivTotal';

export default class extends Component {
  state = {};

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.value, this.props.value) || 
      !isEqual(prevProps.works, this.props.works)) {
      this.loadData();
    }
  }

  loadData = () => {
    const { value: { data, total }, works, readOnly } = this.props;
    if (readOnly) {
      this.setState({ value: { data, total } });
      return;
    }
    const defaultRate = this.getRate(works);
    const newData = data.map((datum, index) => {
      const rate = datum.rate || defaultRate;
      return {
        ...datum,
        key: index,
        amount: datum.amount || datum.quantity * rate,
        rate: rate
      };
    });
    const newTotal = newData.reduce((a, b) => {
      const first = typeof a === 'number' ? a : parseFloat(a.amount, 10)
      const second = typeof b === 'number' ? b : parseFloat(b.amount, 10)
      return first + second
    }, 0);
    this.setState({ value: { data: newData, total: newTotal }, rate: defaultRate });
  }

  getColumns = () => {
    return [
      {
        title: 'Work Type',
        dataIndex: 'work_type',
        width: '35%',
        editable: !this.props.readOnly,
        required: true,
      }, {
        title: 'Qty',
        dataIndex: 'quantity',
        width: '10%',
        inputType: 'number',
        editable: !this.props.readOnly,
        required: true,
        onUpdate: (value, form) => {
          const { getFieldValue, setFieldsValue } = form;
          const rate = getFieldValue('rate') || 0;
          setFieldsValue({
            amount: value * rate
          });
        }
      }, {
        title: 'Unit',
        dataIndex: 'unit',
        inputType: 'unit',
        width: '15%',
        editable: !this.props.readOnly,
        required: true,
      }, {
        title: 'Rate',
        dataIndex: 'rate',
        inputType: 'float',
        width: '10%',
        defaultValue: this.state.rate,
        editable: !this.props.readOnly,
        onUpdate: (value, form) => {
          const { getFieldValue, setFieldsValue } = form;
          const quantity = getFieldValue('quantity') || 0;
          setFieldsValue({
            amount: value * quantity
          });
        }
      }, {
        title: 'Total Amount',
        dataIndex: 'amount',
        inputType: 'float',
        width: '20%',
        editable: !this.props.readOnly,
        disabled: () => false,
        render: (text, record, index) => <span style={{ fontWeight: 'bold' }}>{accounting.formatMoney(text, '₱', 2, ',', '.')}</span>,
      }
    ];
  }

  // beforeRowSave = (row) => {
  //   row.amount = row.quantity * this.state.rate;
  // }

  getRate = (values) => {
    const L = values.length || 0;
    const W = values.width || 0;
    const area = L * W;
    if (area > 150) {
      return 1056.72;
    } else {
      return 528.36;
    }
  }

  // instantiateItem = (item) => {
  //   item.rate = this.state.rate;
  // }

  onChange = (value) => {
    const total = value.reduce((a, b) => {
      const first = typeof a === 'number' ? a : parseFloat(a.amount, 10)
      const second = typeof b === 'number' ? b : parseFloat(b.amount, 10)
      return first + second
    }, 0);
    // this.setState({ 
    //   value: { data: value, total }
    // });

    if (this.props.onChange) {
      this.props.onChange({ data: value, total });
    }
  }

  render() {
    const { value } = this.state;
    if (!value) {
      return null;
    }
    const { data, total } = value;
    return (
      <div style={{ margin: '20px' }}>
        <EditableTable
          value={data}
          columns={this.getColumns()}
          // beforeRowSave={this.beforeRowSave}
          onChange={this.onChange}
        />
        <DivTotal value={total} />
      </div>
    );
  }
}