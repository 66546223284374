import React, { Component } from 'react';
import { Table } from 'antd';

export const columns = [
    {
        title: 'Request Date',
        dataIndex: 'request_date',
    },
    {
        title: 'Case Number',
        dataIndex: 'case_number',
    },
    {
        title: 'Publication Type',
        dataIndex: 'publication_type',
    },
    {
        title: 'Client',
        dataIndex: 'partner_name',
    },
    {
        title: 'Official Gazzete',
        dataIndex: 'volume',
        render: (item, record) => {
            if (record.volume) {
                return <span>Volume {record.volume} Issue {record.issue}</span>
            }
            else {
                return <span></span>
            }
        }
    },
    {
        title: 'Status',
        dataIndex: 'op_status',
    },
    {
        title: 'OR Number',
        dataIndex: 'receipt_number',
    },
    {
        title: 'OR Date',
        dataIndex: 'receipt_date',
    },
];


class List extends Component {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <Table
                    columns={columns}
                    dataSource={this.props.data}
                    loading={this.props.loading}
                    rowKey='id'
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50']
                    }}
                    style={{ marginTop: "10px" }}
                />
            </div>
        );
    }
}

export default List;