import { TYPES } from '../actionCreators';

export default (state = false, action) => {
	if (action.type === TYPES.REQUEST) {
		return true;
	} else if (Object.values(TYPES).includes(action.type)) {
		return false;
	} 

	return state;
}