import React, { Component } from 'react';
import { Table } from 'antd';

export const columns = [
    {
        title: 'Client',
        dataIndex: 'partner_name',
    },
    {
        title: 'RFQ Date',
        dataIndex: 'rfq_date',
    },
    {
        title: 'WOE Number',
        dataIndex: 'woe_number',
    },
    {
        title: 'WO Date',
        dataIndex: 'created_at',
    },
    {
      title: 'WO Status',
      dataIndex: 'woe_current_division',
    },
    {
        title: 'Job Title',
        dataIndex: 'job_title',
    },
];


class List extends Component {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <Table
                    columns={columns}
                    dataSource={this.props.data}
                    loading={this.props.loading}
                    rowKey='id'
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50']
                    }}
                    style={{ marginTop: "10px" }}
                />
            </div>
        );
    }
}

export default List;