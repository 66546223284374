import React, { Component } from 'react';
import { Button, DatePicker, Form, Col, Row, notification, Select } from 'antd';
import { axios } from 'shared/utils/api_client';
import { getAuthHeader } from 'shared/utils/api_client';
import { get } from 'lodash';
import TimelineTable from './table';
import { getColumns } from './table';
import TimelinePdf from './pdf';
import { NavLink } from 'react-router-dom';
import { division_options, estimate_types } from '../../data';


export default class MyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            isTypeAll: false,
        }
    }

    loadData = (params, is_download) => {
        this.setState({loading: true})
        axios.get('/work_orders_progress', {
            params: params,
            sort_key: 'action_date',
            headers: { ...getAuthHeader() }
        })
            .then((response) => {
                // console.log(response.data);
                var result = response.data.data;
                const isTypeAll = !get(response, 'data.filters.type', null);
                var data = create_timeline_from_progress(result);
                if (data.length === 0) {
                    notification['error']({ message: 'No match found' });
                }
                if (is_download) {
                    const columns = getColumns(isTypeAll);
                    TimelinePdf({ ...params, title: 'Work Order Timeline Report'}, data, columns);
                    this.setState({loading: false});
                }
                else {
                    this.setState({data: data, loading: false, isTypeAll});
                }

            })
            .catch((error) => {
                this.setState({loading: false})
            })
    }

    componentDidMount() {

    }

    handleClick = () => {
        this.doIt(false);
    }

    download = () => {
        this.doIt(true);
    }

    doIt = (is_download) => {
        this.formRef.props.form.validateFields((err, values) => {
            if (!err) {
                this.loadData({
                    type: values.type === 'all' ? [] : [values.type], 
                    division: values.woe_current_division === 'all' ? [] : [values.woe_current_division],
                    start_date: values.start_date.format('YYYY-MM-DD'),
                    end_date: values.end_date.format('YYYY-MM-DD')
                }, is_download);
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <Row><h1>Work Order Timeline Report</h1></Row>
                <Row>
                    <Col span={12}>
                        <WrappedForm
                            wrappedComponentRef={(formRef) => { this.formRef = formRef; }} />
                    </Col>
                    <Col span={12}>
                        <p style={{textAlign: 'right'}}>
                        <Button ><NavLink to="/report">Back to reports</NavLink> </Button> &nbsp;
                        <Button type="primary" onClick={this.handleClick}>Generate</Button> &nbsp;
                        <Button type="primary" onClick={this.download}>Download</Button>
                        </p>
                    </Col>
                </Row>
                <Row style={{borderBottom: '1px solid #ccc'}}>
                    
                </Row>
                {
                    this.state.data.length > 0 &&
                    <TimelineTable
                        data={this.state.data}
                        loading={this.state.loading}
                        isTypeAll={this.state.isTypeAll}
                    />
                }
                
            </div>
        );
    }
}

export function create_timeline_from_progress(result) {
    var temp = [];
    var data = [];
    // parse result
    for (let i=0; i<result.length; i++) {
        let item = result[i];
        if (item.action === 'Create' || item.action === 'Receive') {
            // create new temp entry
            temp.push({
                ...item,
                received_date: item.action_date,
                released_date: null 
            });
        }   
        else { // Release / Release to / Return to PPCD
            // find match in temp
            for (let j=0; j<temp.length; j++) {
                if (temp[j].step === item.step && temp[j].id === item.id) {
                    temp[j] = {...temp[j], released_date: item.action_date}
                    data.push(temp[j]); // add to data
                    temp.splice(j,1); // remove from temp
                    break;
                }
            }
        }
    }
    // add all temp which are unreleased
    for (let j=0; j<temp.length; j++) {
        data.push(temp[j]);
    }
    return data;
}

const WrappedForm = Form.create()(
    class extends Component {
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;

            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('start_date', {
                                        rules: [{ required: true, message: 'Start Date' }]
                                    })(
                                        <DatePicker placeholder="Start Date"/>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('end_date', {
                                        rules: [{ required: true, message: 'End Date' }]
                                    })(
                                        <DatePicker placeholder="End Date"/>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('woe_current_division', {
                                        rules: [{ required: true, message: 'Division' }]
                                    })(
                                        <Select placeholder="Select Division">
                                            <Select.Option key='all'>ALL</Select.Option>
                                        {
                                            division_options.map((item) => {
                                                return <Select.Option key={item}>{item}</Select.Option>
                                            })
                                        }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('type', {
                                        rules: [{ required: true, message: 'Type' }]
                                    })(
                                        <Select placeholder="Select Type">
                                            <Select.Option key='all'>ALL</Select.Option>
                                        {
                                            estimate_types.map((item) => {
                                                return <Select.Option key={item}>{item}</Select.Option>
                                            })
                                        }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>

                    </Row>

                </Form>
            );
        }
    }
);