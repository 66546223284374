import React, { Component } from 'react';
import { 
  Tabs, 
  Progress, 
  Button,
  Steps 
} from 'antd'; 
import { get, cloneDeep, mergeWith, isArray } from 'lodash';
import { css } from 'emotion';
import FormPageDetails from './StandardFormDetailsPage.1';
import FormPageInstructions from './StandardFormInstructionsPage.1';
import FormPageSummaryPage from './StandardFormSummaryPage.1';

const TabPane = Tabs.TabPane;
const Step = Steps.Step;

function mergeCustomizer (objValue, srcValue, key, object, source, stack) {
  if (isArray(objValue)) {
    return srcValue;
  }
}

const parentContainer = css({
  flex: '1 1 100%',
  overflow: 'scroll',
  '& .tab-container': {
    flex: 1,
    display: 'flex', 
    flexDirection: 'row',
    margin: '10px',
    '& .navigation': {
      flex: '1', 
      boxShadow: '1px 1px 5px #999999AA', 
      margin: '5px', 
      padding: '30px 10px',
      background: '#FFFFFF'
    },
    '& .form-parent': {
      flex: '4', 
      boxShadow: '1px 1px 5px #999999AA', 
      margin: '5px',
      background: '#FFFFFF',
      overflow: 'hidden',
    }
  }
});

const footer = css({
  background: '#FFFFFF',
  padding: '15px',
  '& .button-container': {
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center',
    '& > button': {
      margin: '5px 10px'
    }
  }
});

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 1,
      values: props.estimate ? cloneDeep(props.estimate) : undefined
    }
    this.componentRef = {};
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.estimate && this.props.estimate) {
      this.setState({ values: cloneDeep(this.props.estimate) });
    }
  }

  saveNewValues = (values) => {
    const stateVal = get(this.state, 'values', {});
    if (values) {
      mergeWith(stateVal, values, mergeCustomizer);
    }
    return stateVal;
  }

  moveToNext = (values) => {
    let currentPage = this.state.activeKey;
    if (currentPage < 3) {
      currentPage++;
    }
    const newValues = this.saveNewValues(values);
    this.setState({ activeKey: currentPage, values: newValues });
  }

  moveToPrevious = () => {
    let currentPage = this.state.activeKey;
    if (currentPage <= 1) {
      return false;
    }
    currentPage--;
    this.setState({ activeKey: currentPage });
    return true;
  }

  onContinuePressed = () => {
    const activeComponent = get(this.componentRef, this.state.activeKey, undefined);
    if (activeComponent && activeComponent.onContinuePressed) {
      activeComponent.onContinuePressed();
    }
  }

  saveComponentRef = (component, id) => {
    this.componentRef[id] = component;
  }

  render() {
    const { values } = this.state;
    const { onFormSave, type } = this.props;
    const { activeKey } = this.state;
    return (
      <React.Fragment>
        <div className={parentContainer}>
          <div className='tab-container'>
            <div className='navigation'>
              <Steps direction='vertical' current={activeKey - 1}>
                <Step title={`${type} Form Estimate Reference`} />
                <Step title={`${type} Form Cost Estimate`} />
                <Step title={`Summary`} />
              </Steps>
            </div>
            <div className='form-parent'>
              <div>
                <Tabs 
                  renderTabBar={()=><div></div>} 
                  activeKey={this.state.activeKey.toString()}
                >
                  <TabPane key="1">
                    <FormPageDetails
                      wrappedComponentRef={ref => this.saveComponentRef(ref, 1)}
                      moveToNext={this.moveToNext}
                      values={values} 
                      type={type}
                    />
                  </TabPane>
                  <TabPane key="2" >
                    <FormPageInstructions
                      wrappedComponentRef={ref => this.saveComponentRef(ref, 2)}
                      moveToNext={this.moveToNext}
                      values={values} 
                      type={type}
                    />
                  </TabPane>
                  <TabPane key="3" >
                    <FormPageSummaryPage
                      wrappedComponentRef={ref => this.saveComponentRef(ref, 3)}
                      values={values} 
                      type={type}
                      onFormSave={onFormSave}
                      moveToNext={this.moveToNext}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <Progress
          showInfo={false} 
          percent={(activeKey/3*100)} 
        />
        <div className={footer}>
          <div className='button-container'>
            <Button onClick={this.moveToPrevious}>
              Back
            </Button>
            <Button type='primary' onClick={this.onContinuePressed}>
              { 
                this.state.activeKey === 3 ? 'Save' : 'Continue'
              }
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}