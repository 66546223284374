import React, { Component } from 'react';

export default class extends Component {
    render() {
        if (!this.props.value) {
            return null;
        }
        return (
            <div style={{ fontWeight: '500', fontSize: '1.3em', color: '#979797' }}>
            {`${this.props.value.value1} ${this.props.value.unit1}, 
            ${this.props.value.value2} ${this.props.value.unit2},
            ${this.props.value.value3} ${this.props.value.unit3}`}
            </div>
        );
    }
}