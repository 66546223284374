import { axios } from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';
import {
  API_PC, 
  API_PCS
} from 'shared/constants/urls';
import { getAuthHeader } from 'shared/utils/api_client';

export const TYPES = {
	GET_LIST: 'GET_PCS',
	GET: 'GET_PC',
	CREATE: 'CREATE_PC',
	UPDATE: 'UPDATE_PC',
	REQUEST: 'REQUEST_PC',
	ERROR: 'ERROR_PC'
}

export const getPCs = (params) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });
		axios.get(API_PCS, {
			headers: { ...getAuthHeader() },
			params: { ...params }
		})
		.then(response => dispatch({ type: TYPES.GET_LIST, payload: response.data }))
		.catch(error => dispatch({ type: TYPES.ERROR, payload: getError(error)}))
	}
}

export const getPC = (id, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.get(`${API_PC}/${id}`, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
			if (successCallback) {
				successCallback(response.data);
			} 
			dispatch({ type: TYPES.GET, payload: response.data})
		})
		.catch(error => { 
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}

export const createPC = (pc, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.post(`${API_PC}`, pc, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
			if (successCallback) {
				successCallback(response.data.production_cost);
			} 
			dispatch({ type: TYPES.CREATE, payload: response.data})
		})
		.catch(error => { 
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}

export const updatePC = (pc, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.put(`${API_PC}/${pc.id}`, pc, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
			if (successCallback) {
				successCallback(response.data.estimate);
			} 
			dispatch({ type: TYPES.UPDATE, payload: response.data})
		})
		.catch(error => { 
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}
