import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

const _format = (data) => {
    return data.map(item => {
        var A = moment(item.received_date);
        var B = moment(item.released_date);
        var time_difference =  item.released_date ? B.diff(A, 'days') + ' days' : '-';

        return ([
            { text: moment(item.created_at).format('YYYY-MM-DD') },
            { text: item.woe_number },
            { text: item.step },
            { text: item.received_date ? item.received_date : 'Waiting' },
            { text: item.released_date ? item.released_date : 'Waiting' },
            { text: time_difference },
            { text: item.type },
            { text: item.partner_name },
            { text: item.job_title },
        ]);
    });
}

const _formatHeader = (data) => {
    return data.map(item => {
        return ({ text: item.title, style: 'tableHeader' });
    });
}

export default (params, data, columns, filename='report.pdf') => {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    const formattedData = _format(data);
    const formattedHeader = _formatHeader(columns)

    const documentDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
            { text: params.title, fontSize: 16 },
            { text: 'Division: ' + params.division, fontSize: 12 },
            { text: 'Type: ' + params.type, fontSize: 12 },
            { text: 'Date: ' + params.start_date + ' - ' + params.end_date, fontSize: 12 },
            { text: 'Generated: ' + (new Date()).toDateString(), fontSize: 10 },
            { text: ' ', fontSize: 10 },
            {
                table: {
                    widths: [
                        '*',
                        '*',
                        '*',
                        '*',
                        '*',
                        '*',
                        '*',
                        'auto',
                        'auto'
                    ],
                    headerRows: 1,
                    body: [
                        formattedHeader,
                        ...formattedData
                    ]
                }
            }
        ],
        defaultStyle: {
            fontSize: 10
        },
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            }
        },
    };

    pdfMake.createPdf(documentDefinition).download(filename);
}
