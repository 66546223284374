import queryString from 'query-string';
import { toNumber } from 'lodash';

export const getWorkOrderIdFromUrl = (props) => {
  if (!props.match || !props.match.params || !props.match.params.id) {
    return undefined;
  }
  return toNumber(props.match.params.id) || undefined;
}

export const getModeFromProps = (props) => {
  if (!props.location || 
      !props.location.pathname) {
          return 'new';
  }

  if (props.location.pathname.includes('edit')) {
      return 'edit';
  }

  if (!props.match || !props.match.params || !props.match.params.id) {
      return 'new';
  }

  return 'copy';
}

export const getType = (woId, wo, props) => {
  if (woId && wo && wo.type) {
    return wo.type;
  }

  const params = queryString.parse(props.location.search);
  return params.type;
}