export const types = [
    'Standard',
    'Accountable',
    'Specialized',
    'Publication'
];

export const divisions = [
    'Composing',
    'Photo-Litography',
    'Press',
    'Finishing',
    'Sales and Marketing',
]

export const divisions_label = {
    composing: 'Composing',
    photo_litography: 'Photo-litography',
    press: 'Press',
    finishing: 'Finishing',
}

export const worktype_options = {
    composing: [
        'Composition',
        'Text',
        'Cover',
        'Half-tones',
        'Inserts',
        'Job Work',
        'Chargeable Corrections, Hand',
        'Chargeable Corrections, Machine'
    ],
    press: [
        'Text',
        'Cover',
        'Colored Ink',
        'Half-tones',
        'Numbering',
        'Embossing and printing',
        'Changes',
        'Extra Charge',
    ],
    finishing: [
        'Folding',
        'Ruling',
        'Perforating',
        'Numbering',
        'Punching',
        'Tableting',
        'Inserting',
        'Binding',
        'Counting',
        'Cutting',
        'Verifying',
        'Boxing'
    ]
}