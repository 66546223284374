import React, { Component } from 'react';

import { HashRouter, Route, Switch } from 'react-router-dom';

import Login from './containers/login';
import Logout from './containers/logout';

class LandingContainer extends Component {
    render() {
        return (
            <div>
                
                <HashRouter>
                    <Switch>
                        <Route path="/logout" component={Logout} />
                        <Route path="/login" component={Login} />
                    </Switch>
                </HashRouter>
            </div>
        );
    }
}

export default LandingContainer;