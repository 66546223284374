import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { get, find, pick } from 'lodash';
import { 
	Drawer, 
	Spin, 
	Icon, 
	notification, 
	Divider, 
	Button,
	Dropdown,
	Menu
} from 'antd';
import Header from 'shared/components/Header';
import WorkOrderTimeline from '../components/WorkOrderTimeline';
import WorkOrderLastStep from '../components/WorkOrderLastStep';
import WorkOrderTabs from '../components/WorkOrderTabs';
import WOReleaseModal from '../components/WOReleaseModal';
import WOPartialReleaseModal from '../components/WOPartialReleaseModal';
import WOReturnModal from '../components/WOReturnModal';
import WOReleaseToOthersModal from '../components/WOReleaseToOthersModal';
import { canEdit, canDownload, canReceive, canRelease, canReturnToPPCD, canPartialRelease, canReleaseToOthers } from '../utils';
import { getWorkOrder, downloadWoPdf, receive, save, returnToPpcd, partialRelease, releaseToDivision } from '../actionCreators';
import { getWorkOrderIdFromUrl } from '../selectors';

const drawerStyle = css({
	'& .ant-drawer-wrapper-body': {
		height: '100%',
		width: '100%',
	},
	'& .ant-drawer-body': {
		height: '100%',
		width: '100%',
		background: '#F6FCFF',
		padding: 0
  },
  '& .drawer-container': {
		height: '100%',
		width: '100%',
		overflow: 'scroll',
    '& .content': {
      display: 'flex',
			flexDirection: 'row',
			padding: '35px 30px',
      '& .wo-timeline': {
				flex: '1',
			},
			'& .wo-details': {
				flex: '3',
				overflow: 'hidden'
			},
		},
		'& .loading': {
			// index: 9999, 
			display: 'flex', 
			justifyContent: 'center', 
			alignItems: 'center', 
			position: 'absolute', 
			top: '0', 
			bottom: '0', 
			left: '0', 
			right: '0', 
			backgroundColor: '#26272955'
		},
		'& header-button': {
			margin: '20px'
		}
	},
});

function disabledView (props) {
	const permissions = get(props, 'loggedInUser.user.permissions.ppcd');
	if (!Array.isArray(permissions)) {
			return true;
	}
	return !permissions.includes('ppcd_view_work_order');
}

class WorkOrderEnvelope extends Component {
	constructor() {
		super();
		this.state = {
			showReleaseModal: false,
			showPartialReleaseModal: false,
			showReturnModal: false, 
			releaseToOthersData: undefined,
		}
	}

  componentDidMount () {
		if (disabledView(this.props)) {
			notification.warning({
				message: 'Uh Oh!',
				description: 'You do not have permission to view this module.',
			});
			this.props.history.replace('/workorder');
			return;
		}
		this.loadData(true);
	} 

	componentDidUpdate () {
		this.loadData(false);
	}

	loadData = (forceReload) => {
		const currentDataId = get(this.props, 'work_order.id', -1);
		const work_orderID = get(this.props, 'work_orderID', -1);
    if (currentDataId !== work_orderID
        && (forceReload || !this.props.error)) {
			this.props.getWorkOrder(
				work_orderID, 
				undefined, 
				this.errorCallback
			);
			return true;
		}
		return false;
	}
	
	close = () => {
		this.props.history.replace('/workorder');
	}

	edit = (work_order) => {
		if (!work_order) {
			return;
		}
		this.props.history.replace(`/workorder/edit/${work_order.id}`);
	}

  receive = (work_order) => {
    this.props.receive(work_order, undefined, this.errorCallback)
	}

	triggerReleaseModal = () => {
		if (!this.componentRef || !this.componentRef.getValidatedData) {
			return;
		}
		const result = this.componentRef.getValidatedData();
		if (result.error) {
			return;
		}
		this.setState({ showReleaseModal: true });
	}
	
	save = (work_order, is_release, extra = {}) => {
		if (!this.componentRef || !this.componentRef.getValidatedData) {
			return;
		}
		const result = this.componentRef.getValidatedData();
		if (result.error) {
			return;
		}
		// console.log('result', { ...result.values, ...extra, is_release });
		this.props.save(get(work_order, 'id'), 
			{ ...result.values, ...extra, is_release }, 
			() => { this.setState({ showReleaseModal: false }) }, 
			this.errorCallback);
	}

	releasePartial = (work_order, extra) => {
		this.props.partialRelease(get(work_order, 'id'), { 
			...extra,
			source_division: get(work_order, 'woe_current_division'),
			source_section: get(work_order, 'woe_current_section'),
			source_step: get(work_order, 'woe_current_step'),
		}, 
		() => { this.setState({ showPartialReleaseModal: false, showReleaseModal: false }) },
		this.errorCallback);
	}

  download = (work_order) => {
    this.props.downloadWoPdf(work_order, this.errorCallback);
	}
	
	returnToPPCD = (work_order, extra = {}) => {
		const id = get(work_order, 'id');
		if (id) {
			this.props.returnToPpcd(
				id, 
				{ ...extra }, 
				() => { this.setState({ showReturnModal: false }) }, 
				this.errorCallback
			);
		}
	}

  errorCallback = (error) => {
		notification.error({ message: error.message });
	}
  
	partialReleaseClick = (event) => {
		if (event.key === 'release') {
			if (!this.componentRef || !this.componentRef.getValidatedData) {
				return;
			}
			const result = this.componentRef.getValidatedData();
			if (result.error) {
				return;
			}

			this.setState({ showReleaseModal: true })
		} else if (event.key === 'partial_release') {
			this.setState({ showPartialReleaseModal: true })
		}
	}

	triggerReleaseToOthersModal = (event, work_order) => {
		if (!this.componentRef || !this.componentRef.getValidatedData) {
			return;
		}
		const result = this.componentRef.getValidatedData();
		if (result.error) {
			return;
		}
		
		if (event.key === 'release') {
			this.setState({ showReleaseModal: true })
		} else {
			const steps = get(work_order, 'process', []);
			const step = find(steps, { step: event.key });
			this.setState({ releaseToOthersData: pick(step, ['division', 'step', 'section'])});
		}
	}

	releaseToOtherDivision = (work_order, extra = {}) => {
		if (!this.componentRef || !this.componentRef.getValidatedData) {
			return;
		}
		const result = this.componentRef.getValidatedData();
		if (result.error) {
			return;
		}
		this.props.releaseToDivision(get(work_order, 'id'), 
			{ ...result.values, ...extra, ...this.state.releaseToOthersData }, 
			() => { this.setState({ releaseToOthersData: undefined }) }, 
			this.errorCallback);
	}
	
	render() {
		const { loading, work_order, loggedInUser } = this.props;
		const releaseMenu = (
			<Menu onClick={e => this.partialReleaseClick(e, work_order)}>
				<Menu.Item key="release">
					Release
				</Menu.Item>
				<Menu.Item key="partial_release">
					Partial Release
				</Menu.Item>
			</Menu>
		);
		const releaseToOthersMenu = (
			<Menu onClick={e => this.triggerReleaseToOthersModal(e, work_order)}>
				<Menu.Item key="release">
					Release
				</Menu.Item>
				<Menu.SubMenu title='Release to other division'>
					<Menu.SubMenu title='Composing'>
						<Menu.Item key="Proof Read">
							Proof Read
						</Menu.Item>
						<Menu.Item key="Stamping">
							Stamping
						</Menu.Item>
						<Menu.Item key="Press Reviser Approval">
							Press Reviser Approval
						</Menu.Item>
					</Menu.SubMenu>
					<Menu.SubMenu title='Photolitography'>
						<Menu.Item key="CTP Proof">
							CTP Proof
						</Menu.Item>
						<Menu.Item key="Plates">
							Plates
						</Menu.Item>
					</Menu.SubMenu>
					<Menu.SubMenu title='Press'>
						<Menu.Item key="Sig and Dist">
							Sig and Dist
						</Menu.Item>
						<Menu.Item key="Printing">
							Printing
						</Menu.Item>
						<Menu.Item key="Final Printing">
							Final Printing
						</Menu.Item>
					</Menu.SubMenu>
					<Menu.SubMenu title='Finishing'>
						<Menu.Item key="Binding">
							Binding
						</Menu.Item>
					</Menu.SubMenu>
					<Menu.SubMenu title='Sales & Marketing'>
						<Menu.Item key="Releasing">
							Releasing
						</Menu.Item>
					</Menu.SubMenu>
				</Menu.SubMenu>
			</Menu>
		);
    return (
      <Drawer
				className={drawerStyle}
				placement="top"
				height={'100%'}
				closable={false}
				destroyOnClose={true}
				visible={true}
      >
        <div className='drawer-container'>
					<Header 
						loading={loading}
            title={`Work Order Envelope`} 
            containerStyle={{ background: '#FFFFFF', padding: '15px 20px', boxShadow: '10px 0px 5px grey' }} 
						titleStyle={{ color: '#2B3D44', fontWeight: '500' }}
					>
						{
							canEdit(loggedInUser, work_order) &&
							<Button className='header-button' onClick={() => this.edit(work_order)}>
								Edit
							</Button>
						}
						{
							canDownload(work_order) &&
							<Button className='header-button' onClick={() => this.download(work_order)}>
								Download
							</Button>
						}
						{
							canReceive(loggedInUser, work_order) &&
							<Button className='header-button' onClick={() => this.receive(work_order)}>
								Receive
							</Button>
						}
						{
							canRelease(loggedInUser, work_order) &&
							<Button className='header-button' onClick={() => this.save(work_order)}>
								Save
							</Button>
						}
						{
							canReturnToPPCD(loggedInUser, work_order) &&
							<Button className='header-button' onClick={() => this.setState({ showReturnModal: true })}>
								Return to PPCD
							</Button>
						}
						{
							canRelease(loggedInUser, work_order) ? 
								canPartialRelease(work_order) ?
									<Dropdown overlay={releaseMenu}>
										<Button>
											Release <Icon type="down" />
										</Button>
									</Dropdown>
								:
								canReleaseToOthers(loggedInUser, work_order) ?
									<Dropdown overlay={releaseToOthersMenu}>
										<Button>
											Release <Icon type="down" />
										</Button>
									</Dropdown>
								:
									<Button className='header-button' onClick={this.triggerReleaseModal}>
										Release
									</Button>
								: null
						}
            <Icon onClick={this.close} type='close' style={{ fontSize: '2em', color: '#797979' }} />
					</Header>
          <div className='content'>
            <div className='wo-timeline'>
              <WorkOrderTimeline 
                work_order={work_order}
							/>
						</div>
						<Divider type='vertical' style={{ background: 'transparent' }}/>
						<div className='wo-details'>
							<WorkOrderLastStep work_order={work_order} />
							<Divider style={{ background: 'transparent' }}/>
							<WorkOrderTabs 
								ref={(ref) => this.componentRef = ref}
								work_order={work_order}
								loggedInUser={loggedInUser}
							/>
						</div>
					</div>
					<WOReleaseModal
						visible={this.state.showReleaseModal}
						onCancel={() => this.setState({ showReleaseModal: false })}
						onRelease={value => { 
							this.save(work_order, true, value);
						}}
						loading={loading}
					/>
					<WOReturnModal
						visible={this.state.showReturnModal}
						onCancel={() => this.setState({ showReturnModal: false })}
						onReturn={value => { 
							this.returnToPPCD(work_order, value)
						}}
						loading={loading}
					/>
					<WOPartialReleaseModal
						visible={this.state.showPartialReleaseModal}
						onCancel={() => this.setState({ showPartialReleaseModal: false })}
						onRelease={value => {
							this.releasePartial(work_order, value)
						}}
						loading={loading}
					/>
					<WOReleaseToOthersModal
						visible={!!this.state.releaseToOthersData}
						onCancel={() => this.setState({ releaseToOthersData: undefined })}
						onRelease={value => {
							this.releaseToOtherDivision(work_order, value)
						}}
						loading={loading}
					/>
					{
						loading && <div className='loading'><Spin size='large' /></div>
					}
        </div>
			</Drawer>
    )
  }
}

const mapStateToProps = ({ loggedInUser, work_order }, ownProps) => {
	const work_orderID = getWorkOrderIdFromUrl(ownProps);
	return {
		loading: work_order.loading,
		work_order: work_order.work_order,
		loggedInUser,
		work_orderID,
	};
}

const mapDispatchToProps = {
  getWorkOrder, receive, save, downloadWoPdf, returnToPpcd, partialRelease, releaseToDivision
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderEnvelope);