import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { css } from 'emotion';
import { get } from 'lodash';
import EditableTable from 'shared/components/EditableTable';
import { divisions } from 'modules/work_order/data';

const FormItem = Form.Item;

const wrapper = css({
	margin: '25px',
	display: 'flex', 
	flexDirection: 'column',
  '& .form-group-title': {
    color: '#415A85', 
    fontSize: '1.5em',
    margin: '10px 15px' 
  },
	'& .top-form-container': {
		display: 'flex',
	}
})

class PublicationFormPage3 extends Component {
	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps) {
    if ((!prevProps.values && this.props.values) ||
      (prevProps.values && this.props.values && prevProps.values.id !== this.props.values.id)) {
      this.loadData();
    }
	}

	loadData = () => {
    const { values, form } = this.props;
    if (!values) {
      return;
    }
    const { setFieldsValue } = form;
    setFieldsValue({
			cut_stock_data: values.cut_stock_data
    });
	}

	columns = [
		{
			title: 'Rolls',
			dataIndex: 'rolls',
			inputType: 'number',
			width: '5%',
			editable: true,
			required: true,
			defaultValue: 0,
			disabled: form => {
				const reams = form.getFieldValue('reams');
				const sheets = form.getFieldValue('sheets');
        return !!reams || !!sheets;
      },
		}, {
			title: 'Reams',
			dataIndex: 'reams',
			inputType: 'number',
			width: '5%',
			editable: true,
			required: true,
			defaultValue: 0,
			disabled: form => {
        const rolls = form.getFieldValue('rolls') || 0;
        return !!rolls;
      },
		}, {
			title: 'Sheets',
			dataIndex: 'sheets',
			inputType: 'number',
			width: '5%',
			editable: true,
			required: true,
			defaultValue: 0,disabled: form => {
        const rolls = form.getFieldValue('rolls') || 0;
        return !!rolls;
      },
		}, {
			title: 'Kind',
			dataIndex: 'kind',
			width: '25%',
			editable: true,
			required: true,
		}, {
			title: 'Size',
			dataIndex: 'size',
			width: '15%',
			inputType: 'dimension',
			editable: true,
			required: true,
		}, {
			title: 'Weight',
			dataIndex: 'weight',
			width: '10%',
			editable: true,
			required: true,
		}, {
			title: 'Division',
			dataIndex: 'division',
			inputType: 'select',
			options: divisions,
			width: '15%',
			editable: true,
			required: true,
		}, {
			title: 'Over',
			dataIndex: 'over',
			width: '5%',
			valuePropName: 'checked',
			inputType: 'switch',
			editable: true,
			required: true,
			defaultValue: false,
			render: checked => !checked ? 'No' : 'Yes'
		},
	];

	onContinuePressed = (saveAsDraft) => {
    const { onFormSave, values, form } = this.props;
    form.validateFields((err, val) => {
      if (saveAsDraft) {
        onFormSave({
          ...values,
					...val,
					woe_data: {
						...get(values, 'woe_data', {}),
						...get(val, 'woe_data', {})
					},
          as_draft: true
        });
      } else if (!err) {
        onFormSave({
					...values,
					...val,
					woe_data: {
						...get(values, 'woe_data', {}),
						...get(val, 'woe_data', {})
					},
          as_draft: false
        });
			}
    });
	}

  render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;
    return (
      <div className={wrapper}>
				<div className='form-group-title'>
					Cut Stock
				</div>
				<div className='top-form-container'>
					<FormItem
						label='Pieces from sheet'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`cut_stock_data.pieces_from_sheet`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
					<FormItem
						label='Cut Sheet Works'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`cut_stock_data.cut_sheet_works`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
					<FormItem
						label='Size'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`cut_stock_data.size`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
					<FormItem
						label='Overs'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`cut_stock_data.overs`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
					<FormItem
						label='Total Pieces'
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						style={{ flex: '1', margin: '10px' }}
					>
						{
							getFieldDecorator(`cut_stock_data.total_pieces`, {
								initialValue: 0,
								required: true,
								pattern: new RegExp("^[a-zA-Z0-9,.-]+$"),
								message: 'Invalid format.',
							})(
								<Input onFocus={e => e.target.select()} />
							)
						}
					</FormItem>
				</div>
				<FormItem>
					{getFieldDecorator('cut_stock_data.data', {
						initialValue: []
					})(
						<EditableTable
							columns={this.columns}
							beforeRowSave={this.beforeRowSave}
							onChange={this.onChange}
						/>
					)}
				</FormItem>
      </div>
    );
  }
}

export default Form.create()(PublicationFormPage3);