import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './containers/List';
import Create from './containers/create';
import Envelope from './containers/WOEnvelope';

class Partners extends Component {
    render() {
		return (
			<React.Fragment>
				<Switch>
					<Route exact path="/workorder/new" component={Create} />
					<Route path="/workorder/new/:id" component={Create} />
					<Route path="/workorder/edit/:id" component={Create} />
					<Route path="/workorder/:id" component={Envelope} />
					<Route exact path="/workorder" component={List} />
				</Switch>
			</React.Fragment>
		);
    }
}

export default Partners;