import React, { Component } from 'react';
import WOSearch from '../components/WOSearch';
import { Modal } from 'antd';

export default class WOReferenceForm extends Component {
  constructor (props) {
    super (props);
    this.state = {
      wo: '',
    }
  }

  render() {
      const { visible, onCancel, onCreate } = this.props;
      return (
          <Modal
              visible={visible}
              title="Enter WO Number"
              okText="Create"
              onCancel={onCancel}
              onOk={() => onCreate(this.state.wo)}
              centered={true}
              closable={false}
          >
            <WOSearch onChange2={wo => this.setState({ wo: wo })} />
          </Modal>
      );
  }
}